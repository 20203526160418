
<!--
 * @Descripttion: 定时发布设置
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-24 15:31:58
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-28 15:09:55
-->
<template>
  <div class="time-set-wrapper" v-if=" timeVisible">
    <div>
      <span>发布时间</span>
      <el-date-picker
        v-model="fixedTime"
        format="YYYY-MM-DD HH:mm"
        value-format="YYYY-MM-DD HH:mm"
        time-format="HH:mm"
        type="datetime"
        :disabled-date="disabledDate"
        :disabled-hours="disabledHours"
        :disabled-minutes="disabledMinutes"
        :placeholder="placeholderTime"
      />
      <span @click="toUserCenter" class="cursor-span">管理待发布动态</span>
    </div>
    <svg class="cursor-pointer" @click="toggleTimeVisible" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="9.899" y="8.536" width="2" height="12" rx="1" transform="rotate(135 9.9 8.536)" fill="#333"/><rect x="1.414" y="9.95" width="2" height="12" rx="1" transform="rotate(-135 1.414 9.95)" fill="#333"/></svg>
  </div>
</template>

<script setup>
import { reactive, computed, toRefs, watch, defineExpose, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import eventBus from '@/utils/eventBus.js'
import { setCookies } from '@/utils/auth'

const store = useStore()
const router = useRouter()

// 获取默认显示时间
const getPlaceholderTime = () => {
  let currentTime = store.state.work.currentTime
  currentTime = currentTime.replaceAll(' : ', ':').slice(0, -3)
  return `${store.state.work.currentDay} ${currentTime}`
}
const state = reactive({
  timeVisible: false,
  fixedTime: '' // 定时发布时间
})
const placeholderTime = computed(() => getPlaceholderTime()) // 默认显示时间

const { timeVisible, fixedTime } = toRefs(state)

watch(() => state.timeVisible, (value) => {
  if (value) {
    // 获取当前时间，设置默认时间为当前时间
    store.dispatch('work/getServerClock')
  }
})

// 切换定时设置
const toggleTimeVisible = () => {
  state.timeVisible = !state.timeVisible
}

// 不可选日期
const disabledDate = (time) => {
  var today = new Date()
  var yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)
  return time.getTime() < new Date(yesterday).getTime()
}

const makeRange = (start, end) => {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}
// 不可选小时
const disabledHours = () => {
  // 获取当前选择的日期
  const selectDay = new Date(state.fixedTime).getDate()
  const currentDay = new Date().getDate()
  const currentHours = new Date().getHours()
  if (selectDay === currentDay) {
    return makeRange(0, currentHours - 1)
  } else {
    return []
  }
}
// 不可选分钟
const disabledMinutes = (hours) => {
  if (hours === new Date().getHours()) {
    const currentMinutes = new Date().getMinutes()
    return makeRange(0, currentMinutes)
  } else {
    return []
  }
}

// 管理定时动态
const toUserCenter = () => {
  setCookies('activeName', 'fixed_blog')
  router.push({
    name: 'personalCenter'
  })
}
onMounted(() => {
  eventBus.$on('toggleTimeVisible', () => {
    toggleTimeVisible()
  })
})

onBeforeUnmount(() => {
  eventBus.$off(toggleTimeVisible)
})

defineExpose({
  timeVisible,
  fixedTime
})
</script>

<style lang="less" scoped>
.time-set-wrapper{
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  margin-top: 16px;
  :deep(.el-date-editor){
    margin: 0 8px;
    width: 160px;
    height: 24px;
    line-height: 24px;
  }
  :deep(.el-input--prefix .el-input__inner){
    height: 24px;
    line-height: 24px;
    padding: 0 16px;
    border-radius: 19px;
    border: 1px solid #F1F1F1;
    color: #333;
    outline: none;
    background: #F6F9FA;
    vertical-align: middle;
  }
  :deep(.el-input__icon){
    display: none;
  }
  .cursor-span{
    color: @active-text-color;
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>
