import store from '@/store'
import { dialogMsg } from '@/utils/dialogMsg'
import { getCookies } from '@/utils/auth'
import SocketService from '@/utils/websocket.js'

const typeListOne = [
  { path: '/blogs/allBlogs' },
  { path: 'dynamics', match: 'end' },
  { path: '/personalCenter' },
  { path: '/layout/searchBlog' },
  { path: '/layout/notification' },
  { path: '/blogs/news' }
]
const typeListTwo = [
  { path: '/forum' },
  { path: 'articalDetail', match: 'end' }
]
const toDynamicsList = [
  { path: '/layout/work' },
  { path: '/layout/writeJournal' },
  { path: '/layout/updateJournal' }
]
const matchFn = (list, to) => {
  return list.map(item => Boolean(item.match === 'end' ? to.path.split('/')[2] === item.path : to.path === item.path))
}

// const whiteList = ['/login', '/face-dance']

export default function routerPermissions (router) {
  router.beforeEach(async (to, from, next) => {
    // 除了登录页面都需要token才能访问
    const token = getCookies('token')
    if (to.path === '/login') {
      // token存在且不过期
      if (token && !store.state.userInfo.isExpire) {
        return next({ path: store.state.userInfo.redirect.redirect || '/blogs/dynamics', query: store.state.userInfo.redirect })
      }
      next()
    } else {
      // 重置页脚状态
      store.commit('setLoadMore', false)
      store.commit('setNoMore', false)
      if (token) {
        // 进入公告相关 须先判断是否在公司内网
        if (to.meta.isAuthority && store.state.work.clockState?.in_company === 'Outside') {
          return dialogMsg('error', '不在公司网络环境，无法查看')
        }
        // 设置内容状态
        if (matchFn(typeListOne, to).includes(true)) {
          store.commit('editContentType', 1)
        } else if (matchFn(typeListTwo, to).includes(true)) {
          store.commit('editContentType', 2)
        }

        if (
          matchFn(toDynamicsList, to).includes(true) &&
          (store.getters['userInfo/isVirtualUser'] || store.state.userInfo.isWhiteList === -1)
        ) {
          return next({ path: '/blogs/dynamics' })
        }
        next()
      } else {
        if (to.meta.noLogin) {
          return next()
        }
        dialogMsg('error', '账号过期请重新登录')
        await store.dispatch('userInfo/clearLoginInfo')
        store.commit('userInfo/setRedirect', { ...to.query, redirect: to.fullPath })
        SocketService.Instance.close()
        next({ path: '/login' })
      }
    }
  })
}
