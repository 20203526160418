/*
 * @Description:
 * @Author: heqi
 * @Date: 2022-08-03 11:23:53
 * @LastEditTime: 2023-07-09 12:16:42
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\apis\okr.js
 */
import { get, post } from '@/apis/method'

const getOkrMenu = (data) => get('/api/okr/menu', data) // 获取左侧菜单

const getOkrCycle = (data) => get('/api/okr/cycle', data) // 获取okr周期列表

const getOkrCreate = (data) => post('/api/okr/create', data) // 创建okr

const getOkrDeptList = (data) => get('/api/okr/dept_list', data) // 搜索组织架构

const getOkrInfo = (data) => get('/api/okr/info', data) // 获取okr详情

const okrUpdate = (data) => post('/api/okr/update', data) // 更新okr

const getOkrListHistory = (data) => get('/api/okr/list', data) // 获取历史目标

const okrDelete = (data) => post('/api/okr/delete', data) // 删除目标

const setOkrSchedule = (data) => post('/api/okr/schedule', data) // 设置okr进度

const editOkrStatus = (data) => post('/api/okr/status', data) // 更新okr状态 撤销 驳回等

const selfEvaluate = (data) => post('/api/okr/self/evaluate', data) // OKR自评

const leadEvaluate = (data) => post('/api/okr/lead/evaluate', data) // OKR自评

const changeEvaluateStatus = (data) => post('/api/okr/evaluate/status', data) // 评价修改状态

const getOkrLeadAuditList = (data) => get('/api/okr/lead/audit_list', data) // 获取审核列表

const getOkrLeadEvaluateList = (data) => get('/api/okr/lead/evaluate_list', data) // 获取评价列表

const getOkrDeptAll = (data) => get('/api/okr/dept/all', data) // 获取部门全部目标

const getOkrUserAll = (data) => get('/api/okr/user/all', data) // 获取成员全部目标

const getAlignList = (data) => get('/api/okr/align_list', data) // 获取对齐视图

const getOkrSelect = (data) => get('/api/okr/select', data) // 获取对齐视图

const getOkrEvaluateCount = (data) => get('/api/okr/audit_evaluate', data) // 获取未评价审核数

const getOkrLeader = (data) => get('/api/okr/leader', data) // 获取部门对应领导

const getOkrExit = (data) => get('/api/okr/exist', data) // 检测是否创建okr

const saveOkrDraft = (data) => post('/api/okr/create_draft', data) // 保存草稿okr

const selfEvaluateDraft = (data) => post('/api/okr/self/evaluate_draft', data) // 自评草稿

const leadEvaluateDraft = (data) => post('/api/okr/lead/evaluate_draft', data) // 上级评价草稿

export {
  getOkrMenu,
  getOkrCycle,
  getOkrCreate,
  getOkrDeptList,
  getOkrInfo,
  okrUpdate,
  editOkrStatus,
  getOkrLeadAuditList,
  getOkrLeadEvaluateList,
  getOkrListHistory,
  getOkrDeptAll,
  getOkrUserAll,
  getAlignList,
  getOkrSelect,
  okrDelete,
  setOkrSchedule,
  selfEvaluate,
  changeEvaluateStatus,
  leadEvaluate,
  getOkrEvaluateCount,
  getOkrLeader,
  getOkrExit,
  saveOkrDraft,
  selfEvaluateDraft,
  leadEvaluateDraft
}
