<!--
 * @Descripttion: 空数据
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-16 20:35:17
 * @LastEditors: heqi
 * @LastEditTime: 2022-01-06 15:16:12
-->

<template>
  <el-empty
    :image-size="Number(size)"
    :description="description"
    :image="require(`../assets/img/empty/${image}.svg`)"
    ><div class="des-content">{{ descriptionTwo }}</div></el-empty
  >
</template>

<script>
export default {
  name: 'EmptyData',
  props: {
    image: {
      // 对应类型，图片名称
      type: String,
      default: 'no-content'
    },
    description: {
      // 文案
      type: String,
      default: '暂无数据'
    },
    size: {
      // 图片大小
      type: String,
      default: '200'
    },
    descriptionTwo: {
      // 第二行文字
      type: String
    }
  }
}
</script>
<style lang="less" scoped>
.el-empty {
  background: @colorWhite;
}
.el-empty__bottom {
  margin-top: 0;
}
.des-content {
  font-size: 14px;
  color: @second-text-color;
  margin-top: 5px;
}
:deep(.el-empty__description p) {
  color: @second-text-color;
}
</style>
