/*
 * @Description:静态资源路径管理
 * @Author: heqi
 * @Date: 2022-01-06 14:46:15
 * @LastEditTime: 2022-01-06 14:50:30
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\store\modules\assets.js
 */
const state = {
  huxiu: require('@/assets/img/huxiu.png'),
  kr: require('@/assets/img/36kr.png')
}
export default {
  namespaced: true,
  state
}
