<!--
 * @Descripttion: 文本溢出
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-07-07 13:49:15
 * @LastEditors: tangjz
 * @LastEditTime: 2023-07-20 13:34:45
-->

<template>
  <el-tooltip
    :disabled="isDisabled"
    :content="content"
    :placement="placement"
    :effect="effect"
  >
    <p :class="`ellipsis ${className}`" @mouseover="mouseOver">
      <span ref="contentRef">{{content}}</span>
    </p>
  </el-tooltip>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  name: 'TextExceedsTip',
  props: {
    content: { // 显示的文字内容
      type: String,
      default: ''
    },
    className: { // 设置父元素的样式
      type: String,
      default: ''
    },
    effect: { // 主题 dark/light
      type: String,
      default: 'light'
    },
    placement: { // Tooltip 的出现位置
      type: String,
      default: 'top'
    }
  },
  setup (props) {
    const state = reactive({
      isDisabled: true,
      contentRef: null
    })
    const mouseOver = () => {
      const parentWidth = state.contentRef.parentNode.offsetWidth
      const contentWidth = state.contentRef.offsetWidth
      // 判断是否禁用tooltip功能
      state.isDisabled = contentWidth <= parentWidth
    }
    return {
      ...toRefs(state),
      mouseOver
    }
  }
}
</script>

<style lang="less" scoped>
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }
</style>
