<!--
 * @Descripttion: 未写日志弹窗提示
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-03-04 09:49:19
 * @LastEditors: heqi
 * @LastEditTime: 2022-08-31 14:48:41
-->
<template>
  <div>
    <svg width="117" height="76" viewBox="0 0 117 76" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M89.812 27.2a1 1 0 0 1 .188.584V65a3 3 0 0 1-3 3h-8a3 3 0 0 1-3-3V27.784a1 1 0 0 1 .188-.583l6-8.355a1 1 0 0 1 1.624 0l6 8.355z" stroke="#E0E0E0" stroke-width="2"/><path d="M76.546 27.267L83 17.778l6.454 9.489a20.32 20.32 0 0 1-12.908 0z" fill="#fff" stroke="#E0E0E0" stroke-width="2"/><path fill="#E0E0E0" d="M75 59h16v2H75z"/><rect x="27" y="20" width="43" height="49" rx="5" stroke="#E0E0E0" stroke-width="2"/><rect x="36.5" y="35.5" width="24" height="1" rx=".5" stroke="#E0E0E0"/><rect x="36.5" y="42.5" width="24" height="1" rx=".5" stroke="#E0E0E0"/><rect x="36.5" y="49.5" width="24" height="1" rx=".5" stroke="#E0E0E0"/><rect x="36.5" y="56.5" width="24" height="1" rx=".5" stroke="#E0E0E0"/><rect x="36.1" y="12.488" width="9.635" height="17.894" rx="4.818" fill="#fff"/><rect x="52.618" y="12.488" width="9.635" height="17.894" rx="4.818" fill="#fff"/><rect x="38.477" y="14.865" width="4.882" height="13.141" rx="2.441" stroke="#E0E0E0" stroke-width="2"/><rect x="54.994" y="14.865" width="4.882" height="13.141" rx="2.441" stroke="#E0E0E0" stroke-width="2"/></svg>
    <p>您今日还未提交日志，请提交日志后再打卡下班</p>
    <el-button type="primary" @click="toWriteJournal">前往提交日志</el-button>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { setBuriedPoint } from '@/apis/journal.js'

export default {

  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const toWriteJournal = () => {
      emit('close')
      setBuriedPoint({ event_id: 10001, uc: 1 }).then(res => {
        // console.log(res)
      })
      // 保存自动弹窗标识字段
      store.commit('work/setAutoSignOut', 1)
      router.push({ name: 'writeJournal' })
    }
    return {
      toWriteJournal
    }
  }
}
</script>

<style lang="less">
.submit-log {
  width: 400px !important;
  height: 209px;
  border-radius: 6px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__footer {
    padding: 0;
  }
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #333;
    font-size: 16px;
    svg{
      margin-top: 15px;
    }
    p {
      margin: 12px 0 24px;
    }
  }

  .el-button {
    width: 157px;
    height: 32px;
    min-height: 32px;
    padding: 0;
    border-radius: 16px;
    color: #fff;
    font-size: 16px;
  }
}
</style>
