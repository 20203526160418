/*
 * @Description: 打卡相关接口
 * @Author: heqi
 * @Date: 2022-01-25 15:22:18
 * @LastEditTime: 2022-02-17 16:57:28
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\apis\attendance.js
 */
import { get, post } from '@/apis/method'

const attendanceClock = (data) => post('/api/attendance/clock', data) // 打卡
const getAttendanceClockResult = (data) => get('/api/attendance/get_clock_result', data) // 打卡详情
const attendanceList = (data) => get('/api/attendance/list', data) // 考勤列表
const exceptionList = (data) => get('/api/attendance/exception_list', data) // 异常考勤列表
const getExceptionCount = (data) => get('/api/attendance/exception_count', data) // 异常考勤统计
const getServerTime = (data) => get('/api/get_service_time', data)

export {
  attendanceClock,
  getAttendanceClockResult,
  attendanceList,
  exceptionList,
  getExceptionCount,
  getServerTime
}
