/*
 * @Descripttion: 投票模块vuex管理
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-21 16:52:19
 * @LastEditors: tangjz
 * @LastEditTime: 2023-08-11 11:06:08
 */
import { getProgressList } from '@/apis/vote'
const state = {
  voteInfos: {},
  progressVote: [],
  voteVisible: false,
  clearNum: 0 // 清理创建投票数据次数
}
const mutations = {
  // 获取投票传参数据
  setVoteInfo (state, value) {
    state.voteInfos = value
  },
  // 获取进行中的投票
  setProgressVote (state, value) {
    state.progressVote = value
  },
  // 获取投票弹窗是否显示
  setVoteVisible (state, value) {
    state.voteVisible = value
  },
  setClearNum (state, value) {
    state.voteInfos = {}
    state.clearNum += 1
  }
}

const actions = {
  getProgressVote (state) {
    const params = {
      limit: 7
    }
    getProgressList(params)
      .then((res) => {
        if (res.code === 0) {
          state.commit('setProgressVote', res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
