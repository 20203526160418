/*
 * @Description:
 * @Author: heqi
 * @Date: 2022-04-08 17:20:45
 * @LastEditTime: 2023-06-28 14:52:41
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\store\modules\forum.js
 */

import { getForumCate } from '@/apis/forum'
const state = {
  pubData: [],
  postInfo: {}, // 帖子内容
  previewSrc: '' // 帖子预览图片
}
const mutations = {
  setCateList (state, value) {
    state.pubData = value
  },

  setPostInfo (state, value) {
    state.postInfo = value
  },
  setPreviewSrc (state, payload) {
    state.previewSrc = payload
  }

}
const actions = {
  getCatalists (state) {
    getForumCate()
      .then((res) => {
        // debugger
        if (res.code === 0) {
          state.commit('setCateList', res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
