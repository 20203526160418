/*
 * @Descripttion: 兄弟组件通信
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-20 17:00:43
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2021-12-20 17:24:47
 */

import mitt from 'mitt'

const emitter = mitt()
const eventBus = {}

eventBus.$on = emitter.on
eventBus.$off = emitter.off
eventBus.$emit = emitter.emit

export default eventBus
