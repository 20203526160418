<template>
  <el-dialog
    v-model="userCareDialogVisible"
    v-if="userCareDialogVisible"
    custom-class="user-care-dialog"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="1110"
    append-to-body
  >
    <div class="dialog-content scroll-bar">
      <img :src="currCareDetail.care_info.banner" class="care-banner" />
      <p class="care-content">{{ currCareDetail.content }}</p>
    </div>
    <div class="dialog-footer">
      <el-button @click="handleCurrentCare(currCareDetail.care_id)" class="care-btn">收下祝福</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, toRefs } from 'vue'
import { acceptUserCareInfo } from '@/apis/userCare.js'

export default {
  setup () {
    const store = useStore()
    const state = reactive({
      userCareDialogVisible: computed(() => store.state.userCareDialog.isShow),
      careList: computed(() => store.state.userCareDialog.list),
      currIndex: 0,
      currCareDetail: computed(() => state.careList[state.currIndex] || []),
      hasNextCare: computed(() => state.careList.length - state.currIndex > 1),
      isAccept: computed(() => store.state.userCareDialog.isAccept),
      isNextOkr: computed(() => store.state.userCareDialog.isNextOkr)
    })

    const handleCurrentCare = (id) => {
      if (state.isAccept) {
        acceptUserCareInfo({ care_id: id }).then(() => {
          store.commit('reduceMessageCountToOne')
          handleNextCare()
        })
      } else {
        handleNextCare()
      }
    }

    const handleNextCare = () => {
      if (state.hasNextCare) {
        state.currIndex++
      } else {
        store.commit('setUserCareDialog', { isShow: false })
        state.isNextOkr && store.dispatch('okr/getOkrExitStatus')
      }
    }

    return {
      ...toRefs(state),
      handleCurrentCare
    }
  }
}
</script>

<style lang="less">

.user-care-dialog{
  display: flex;
  height: 732px;
  margin-top: calc((100vh - 732px) / 2) !important;
  padding: 23px 89px 29px;
  background: url(../assets/img/care/user-care-bg.svg) no-repeat center;
  box-shadow: none;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body{
    padding: 50px 0 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dialog-content{
    overflow-y: auto;
    padding: 0 68px;
    flex: 1;
  }
  .care-banner{
    height: 300px;
    flex-shrink: 0;
  }
  .care-content{
    color: #333;
    line-height: 30px;
    padding: 40px 0 0;
    flex: 1;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  .dialog-footer{
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    padding-top: 40px;
    .care-btn{
      width: 303px;
      height: 45px;
      border-radius: 30px;
      background: #FF8401;
      color: #fff;
      border: none;
    }
  }
}
</style>
