<!--
 * @Description: 滑动验证
 * @Author: heqi
 * @Date: 2022-01-25 09:44:07
 * @LastEditTime: 2023-07-10 11:03:50
 * @LastEditors: wangr
 * @FilePath: \mfzj\src\views\layout\work\component\SlideSign.vue
-->
<template>
  <div v-if="work.clockState && work.clockState.in_company === 'Normal'">
    <p class="attendance-date">
      <span>{{ attendanceText }}</span>
    </p>
    <div class="slider-check-box">
      <div class="slider-check" :class="rangeStatus ? 'success' : ''">
        <span ref="greenBac" class="green-bac">
          <span class="slide-text">{{ rangeStatus ? successText : work.currentTime }}</span>
        </span>
        <i @mousedown="rangeMove" :class="rangeStatus ? successIcon : startIcon"></i>
        <span class="range-status-text">{{ rangeStatus ? successText : work.currentTime }}</span>
      </div>
    </div>
  </div>
  <!-- <div class="no-company-range" v-else-if="work.clockState.in_company === 'Normal'"> -->
  <div class="no-company-range" v-else-if="work.clockState && work.clockState.in_company === 'Outside'">
    不在公司打卡范围内,无法打卡
    <div>{{ work.currentTime }}</div>
  </div>

  <el-dialog custom-class="submit-log" v-model="dialogVisible" center :show-close="false">
    <WriteJournalDialog @close="dialogVisible = false" />
  </el-dialog>
</template>

<script>
import { reactive, toRefs, computed, onMounted, watch } from 'vue'
import { attendanceClock } from '@/apis/attendance.js'
import { useStore } from 'vuex'
import { dialogMsg } from '@/utils/dialogMsg.js'
import eventBus from '@/utils/eventBus.js'
import WriteJournalDialog from './WriteJournalDialog.vue'
import { setBuriedPoint } from '@/apis/journal.js'
export default {
  name: 'SlideSign',
  emits: ['successSlide', 'justAttendanceStatus'],
  components: { WriteJournalDialog },
  props: {
    // 开始的图标
    startIcon: {
      type: String,
      default: 'el-icon-right'
    },
    // 成功图标
    successIcon: {
      type: String,
      default: 'el-icon-check'
    },
    // 成功文字
    successText: {
      type: String,
      default: '验证成功'
    },
    slideType: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      // now: new Date(),
      disX: 0,
      rangeStatus: false,
      greenBac: null,
      attendRecord: 0,
      work: computed(() => store.state.work),
      timeId: null,
      dialogVisible: false,
      clockState: 1,
      attendanceText: '',
      clockCount: 0
    })

    onMounted(() => {
      justAttendanceText(justClockState(state.work.clockState?.time_result, state.work.clockState?.check_type))
    })

    watch(store.state.work, (value) => {
      const hours = value.currentTime.split(':')[0]
      const justAttendanceStatus = justAttendanceText(
        justClockState(state.work.clockState?.time_result, state.work.clockState?.check_type),
        hours
      )
      // console.log(justAttendanceStatus)
      emit('justAttendanceStatus', justAttendanceStatus)
    })

    const justClockState = (timeResult, checkType) => {
      const status = {
        Normal: {
          OnDuty: {
            code: 2
          },
          OffDuty: {
            code: 3
          }
        },
        give_signed: {
          code: 2
        },
        Late: {
          code: 4
        },
        Early: {
          code: 5
        },
        Absenteeism: {
          OnDuty: {
            code: 6
          },
          OffDuty: {
            code: 7
          }
        }
      }

      if (status[timeResult]) {
        // console.log(status[timeResult])
        return status[timeResult][checkType] ? status[timeResult][checkType] : status[timeResult]
      }
    }

    const justAttendanceText = (data, hours, justAttendanceStatus = 1) => {
      if (data?.code) {
        switch (data.code) {
          case 2:
          case 4:
          case 6:
            if (hours >= 18) {
              state.attendanceText = '还没有打下班卡哦'
              justAttendanceStatus = 4
            } else {
              state.attendanceText = '已经打上班卡啦'
              justAttendanceStatus = 2
            }
            return justAttendanceStatus
          case 3:
          case 5:
          case 7:
            state.attendanceText = '已经打下班卡啦'
            justAttendanceStatus = 3
        }
      } else {
        if (state.work.clockState.sign_type === 1) {
          state.attendanceText = '还没有打上班卡哦'
        } else {
          state.attendanceText = '还没有打下班卡哦'
          justAttendanceStatus = 4
        }
      }
      return justAttendanceStatus
    }

    // 打卡成功之后的函数
    async function successFun (ele) {
      if (ele) {
        setTimeout(() => {
          ele.style.transform = 'translateX(0)'
          ele.style.transition = '1s all'
          state.greenBac && (state.greenBac.style.width = '0px')
          state.greenBac && (state.greenBac.style.transition = '1s all')
          state.disX = 0
          state.rangeStatus = false
        }, 1000)
      }

      localStorage.setItem('clockSlide', +new Date())
      //  滑动打卡滑块
      setBuriedPoint({ event_id: 10002, uc: 1 })
      clockFn()
    }

    const clockFn = async () => {
      try {
        const result = await attendanceClock()
        if (result.code === 0) {
          // 保存打卡时间
          store.commit('work/setSignTime', result.data.user_check_time)
          const res = result.data.time_result
          const justClockStateData = justClockState(res, result.data.check_type)
          state.clockState = justClockStateData.code
          justAttendanceText(justClockStateData)

          // 上班打卡手动调用方法更新显示状态
          const timeClockOnduty = [2, 4, 6]
          timeClockOnduty.includes(state.clockState) &&
            store.commit('work/setSlideSuccess', {
              sign_time: result.data.user_check_time,
              time_result: res
            })
          // 获取异常情况
          const timeRes = [4, 5, 6, 7]
          timeRes.includes(state.clockState) && store.dispatch('work/getClockAbnormal')

          state.work.signState = state.clockState

          // 打卡成功需要阅读公告 如果是dialog 需要先关闭 在弹窗 如果是工作台则直接弹窗
          emit('successSlide')
          // 更新个人状态
          setTimeout(() => {
            store.dispatch('work/getClockState')
            // 更新日历中的打卡状态
            eventBus.$emit('attendanceSuccess')
            // 重置状态
            store.commit('work/setAutoSignOut', 0)
            store.commit('work/setSubmitJournal', 0)
          }, 200)
        } else if (result.code === 70001) {
          state.dialogVisible = true
        } else {
          reClock()
        }
      } catch {
        reClock()
      }
    }

    const reClock = () => {
      dialogMsg('error', '打卡失败，正在自动尝试重新打卡')
      setTimeout(() => {
        state.clockCount += 1
        if (state.clockCount < 4) {
          clockFn()
        } else {
          return dialogMsg('error', '打卡失败，请手动尝试重新打卡')
        }
      }, 1000)
    }

    // 滑块移动
    function rangeMove (e) {
      const ele = e.target
      const startX = e.clientX
      const eleWidth = ele.offsetWidth
      const parentWidth = ele.parentElement.offsetWidth
      const MaxX = parentWidth - eleWidth
      if (state.rangeStatus) {
        // 不运行
        return false
      }
      document.onmousemove = (e) => {
        const endX = e.clientX
        state.disX = endX - startX
        if (state.disX <= 0) {
          state.disX = 0
        }
        if (state.disX >= MaxX - eleWidth) {
          // 减去滑块的宽度,体验效果更好
          state.disX = MaxX
        }
        ele.style.transition = '0s all'
        ele.style.transform = 'translateX(' + state.disX + 'px)'
        state.greenBac.style.width = state.disX + 32 + 'px'
        state.greenBac.style.transition = '0s all'
        e.preventDefault()
      }
      document.onmouseup = () => {
        if (state.disX !== MaxX) {
          ele.style.transition = '0s all'
          state.greenBac.style.transition = '0s all'
          ele.style.transform = 'translateX(0)'
          state.greenBac.style.width = 0
        } else {
          state.rangeStatus = true
          // 执行成功的函数
          successFun && successFun(ele)
        }
        document.onmousemove = null
        document.onmouseup = null
      }
    }

    const closeDialog = () => {
      state.dialogVisible = false
      eventBus.$emit('closeClockDialog')
    }
    return { rangeMove, ...toRefs(state), closeDialog, justClockState, justAttendanceText }
  }
}
</script>
<style lang="less">
.jc-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-check-box {
  padding-bottom: 26px;
  .slider-check {
    color: #7972f0;
    background-color: #f2f1fe;
    position: relative;
    transition: 1s all;
    user-select: none;
    height: 32px;
    width: 227px;
    margin: 0 auto;
    border-radius: 16px;
    .jc-flex();
    i {
      position: absolute;
      left: 0;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      height: 100%;
      color: #fff;
      background-color: #7972f0;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      .jc-flex();
    }
  }
}
.green-bac {
  position: absolute;
  left: 0;
  background: #86c600;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
}
.slide-text {
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 87px;
  text-align: center;
  justify-content: center;
  height: 32px;
  line-height: 32px;
}
.range-status-text {
  font-weight: bold;
  font-size: 16px;
}
.no-company-range {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ff8e96;
  div {
    padding: 7px;
    color: @second-text-color;
  }
}
</style>
