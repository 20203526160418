import { createStore } from 'vuex'
import PersistedState from 'vuex-persistedstate'
import userInfo from './modules/userInfo'
import hotTopic from './modules/hotTopic'
import forum from './modules/forum'
import searchRes from './modules/searchRes'
import work from './modules/work'
import announcement from './modules/announcement'
import okr from './modules/okr'
import vote from './modules/vote'
import prize from './modules/prize'
import tvwall from './modules/tvwall'
import assets from './modules/assets'
import { getNoteCount } from '@/apis/blogs.js'
import { getAllUserList } from '@/apis/search.js'
import { getEmojiList } from '@/apis/announcement.js'
import { getUserCareList } from '@/apis/userCare.js'
import { ElLoading } from 'element-plus'

export default createStore({
  state: {
    loading: false,
    contentType: 0, // 当前页面type类型
    WebSocket: {
      type: 0 // 新动态数量
    },
    messageCount: 0,
    loadMore: false, // 加载更多
    noMore: false, // 无数据
    isNetWorkErr: false, // 是否网络异常
    userList: [], // 用户列表
    messageDel: true, // 控制动态详情的显示和隐藏
    imagePreVisible: false,
    allEmojiList: [],
    loadingObj: null,
    feedContentDraft: {},
    userCareDialog: { // 员工关怀数据
      isShow: false,
      param: {},
      list: [],
      isAccept: true,
      isNextOkr: false
    }
  },
  mutations: {
    editContentType (state, onload) {
      state.contentType = onload
    },
    editwebSocketType (state, onload) {
      state.WebSocket.type = onload
    },
    editMessageCount (state, onload) {
      state.messageCount = onload
    },
    addMessageCountToOne (state) {
      state.messageCount += 1
    },
    reduceMessageCountToOne (state) {
      state.messageCount -= 1
    },
    setLoadMore (state, value) {
      state.loadMore = value
    },
    setNoMore (state, value) {
      state.noMore = value
    },
    setNetWorkErr (state, value) {
      state.isNetWorkErr = value
    },
    setUserList (state, value) {
      state.userList = value
    },
    setMessageDel (state, value) {
      state.messageDel = value
    },
    setImagePreVisible (state, payload) {
      state.imagePreVisible = payload
    },
    setAllEmojiList (state, list) {
      state.allEmojiList = list.map(item => {
        return {
          title: '[' + item.replace(/(.*\/)*([^.]+).*/ig, '$2') + ']',
          url: item
        }
      })
    },
    openLoading (state, payload) {
      state.loadingObj = ElLoading.service({ fullscreen: true, text: payload || 'loading...' })
    },
    closeLoading (state) {
      state.loadingObj && state.loadingObj.close()
    },
    saveFeedContentDraft (state, payload) {
      state.feedContentDraft = payload
    },
    setUserCareDialog (state, payload) {
      state.userCareDialog = Object.assign(state.userCareDialog, payload)
    }
  },
  actions: {
    getMessageCount (state) {
      getNoteCount()
        .then((res) => {
          if (res.code === 0) {
            state.commit('editMessageCount', res.data.count)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getUserList (state) {
      let userData = []
      const allUserList = []

      if (state.state.userInfo.userMedal.length) {
        userData = state.state.userInfo.userMedal
      } else {
        const res = await getAllUserList()
        if (res?.code === 0) {
          userData = res.data
        }
      }
      if (userData.length) {
        userData.forEach((item) => {
          allUserList.push({
            key: item.name,
            value: item.name,
            id: item.id,
            ding_user_id: item.ding_user_id
          })
        })
        state.commit('setUserList', allUserList)
      }
    },
    async getAllEmojiList ({ commit, state }, reload = false) {
      if (state.allEmojiList.length && !reload) {
        return state.allEmojiList
      } else {
        const res = await getEmojiList()
        if (res?.code === 0) {
          commit('setAllEmojiList', res.data)
        }
        return res.data
      }
    },
    getUserCare ({ commit, dispatch }, { param, isAccept, isNextOkr }) {
      getUserCareList(param).then(res => {
        if (res.code === 0) {
          if (res.data.data.length) {
            commit('setUserCareDialog', {
              isShow: true,
              param,
              list: res.data.data,
              isAccept,
              isNextOkr
            })
          } else {
            isNextOkr && dispatch('okr/getOkrExitStatus', '', { root: true })
          }
        }
      })
    }
  },
  modules: {
    userInfo,
    hotTopic,
    forum,
    searchRes,
    assets,
    work,
    announcement,
    okr,
    vote,
    prize,
    tvwall
  },
  plugins: [
    PersistedState({
      key: 'states',
      storage: window.sessionStorage,
      paths: ['feedContentDraft', 'userInfo', 'forum', 'searchRes', 'assets', 'work', 'announcement', 'okr', 'prize']
    })
  ]
})
