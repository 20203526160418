/*
 * @Descripttion: 投票api
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-05-24 09:50:59
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-04 18:20:19
 */

import { get, post } from '@/apis/method'

const addVote = (data) => post('/api/vote/create', data) // 新增投票
const addVoteOption = (data) => post('/api/vote/add_option', data) // 新增投票选项
const delVoteOption = (data) => post('/api/vote/delete_option', data) // 删除投票选项
const selectVote = (data) => post('/api/vote/selection', data) // 进行投票/取消投票
const updateOptionList = (data) => get('/api/vote/option_list', data) // 获取投票选项列表
const getOptionUsers = (data) => get('/api/vote/voted_users', data) // 获取选中该投票选项的用户
const getSelectNum = (data) => get('/api/vote/voted_nums', data) // 获取选中该投票选项的数量
const getProgressList = (data) => get('/api/vote/progress_list', data) // 获取进行中的投票

export {
  addVote,
  addVoteOption,
  delVoteOption,
  selectVote,
  updateOptionList,
  getOptionUsers,
  getSelectNum,
  getProgressList
}
