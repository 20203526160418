import { getUploadConfig } from '@/apis/blogs'
var OSS = require('ali-oss')

export const getToken = async () => {
  const res = await getUploadConfig({ type: 3 })

  const { accessKeyId, accessKeySecret, bucket, region, path, securityToken } = res.data

  var client = new OSS({
    region: region, // 填写Bucket所在地域
    accessKeyId: accessKeyId, // 这自己keyId
    accessKeySecret: accessKeySecret, // 这里写自己的key密码
    bucket: bucket, // 填写Bucket名称。
    stsToken: securityToken,
    secure: true,
    refreshSTSToken: async () => {
      const refreshToken = await getToken()
      return {
        accessKeyId: refreshToken.accessKeyId,
        accessKeySecret: refreshToken.accessKeySecret,
        stsToken: refreshToken.securityToken
      }
    }
  })

  return { client, path }
}

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}
