export const RESPOND = {
  SUCCESS: 0
}
// 账号类型
export const ACCOUNT_TYPE = {
  DINGDING: 1, // 钉钉账号
  BACKGROUNDB: 2, // 后台账号
  RECEPTION: 3, // 前台账号
  UNIVERSAL: 4 // 通用账号（后台+前台）
}

// 来源类型
export const FEEDFROM_TYPE = {
  PC: 1 // PC端
}
