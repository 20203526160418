/*
 * @Description:搜索相关接口
 * @Author: heqi
 * @Date: 2021-12-17 11:24:54
 * @LastEditTime: 2022-01-06 14:37:55
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\apis\search.js
 */
import { get } from '@/apis/method'

/**
 * @description: 搜索数据
 */
const searchData = (data) => get('/api/search/data', data)

/**
 * @description: 获取用户列表
 */
const getAllUserList = (data = { token: '' }) => get('/api/user/user_all', data)

export { searchData, getAllUserList }
