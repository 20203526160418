<!--
 * @Descripttion: 用户头像
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-10 11:17:47
 * @LastEditors: tangjz
 * @LastEditTime: 2023-09-14 18:02:48
-->
<template>
  <div :class="['user-avatar', 'user-medal', needBorder && 'select-avatar']" :style="medalStyle">
    <el-image v-if="user.avatar" @click="toUserCenter(user.id || user.user_id)"
      :src="`${user.avatar}${animation ? '?x-oss-process=image/quality,Q_5/resize,m_fill,h_150,w_150' : ''}`"
      :preview-src-list="(isPreview && NopreImage && previewList ) || []"
      :hide-on-click-modal="true"
      :style="style"
    >
    </el-image>
    <p v-else-if="user.name" :style="style" @click="toUserCenter(user.id)" class="default-avatar">
      <span :class="{'small-size': Number(size) <= 28 && Number(fontSize) <= 12}">{{userName}}</span>
    </p>
    <slot name="slot"></slot>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch, onMounted } from 'vue'
import myRouter from '@/router'
import { useStore } from 'vuex'
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      default: () => ({
        id: '',
        avatar: '',
        name: ''
      })
    },
    size: {
      type: String,
      default: '50'
    },
    isPreview: { // 是否仅查看，不跳转用户中心
      type: Boolean,
      default: false
    },
    NopreImage: { // 不可查看图片
      type: Boolean,
      default: true
    },
    fontSize: {
      type: String,
      default: '18'
    },
    noPreview: {
      type: Boolean,
      default: true
    },
    medalSize: {
      type: String,
      default: '45'
    },
    medalHref: {
      type: String,
      default: ''
    },
    isWear: {
      type: Boolean,
      default: true
    },
    needBorder: { // 抽奖头像选中border
      type: Boolean,
      default: false
    },
    animation: { // 抽奖动画时的状态
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const state = reactive({
      id: computed(() => props.user.id),
      medalStyle: {},
      style: { width: `${props.size}px`, height: `${props.size}px`, 'line-height': `${props.size}px`, 'font-size': `${props.fontSize}px`, margin: '0 auto' },
      previewList: computed(() => [props.user.avatar]),
      userName: computed(() => {
        const user = props.user
        if (user && user.name) {
          const pattern = /^[\u4E00-\u9FA5]{1,5}$/
          if (pattern.test(user.name)) { // 中文
            return user.name.length === 2 ? user.name : user.name.substr(user.name.length - 2, 2)
          } else { // 英文
            return user.name.substring(0, 2)
          }
        } else {
          return ''
        }
      }),
      noPreview: computed(() => props.noPreview),
      medalHref: computed(() => props.medalHref),
      isWear: computed(() => props.isWear)
    })
    watch(() => [props.user, props.isWear, props.medalHref, store.state.userInfo.userMedal], () => {
      props.isWear && store.state.userInfo.userMedal.filter(item => {
        if (item.id === state.id) {
          const imageUrl = state.medalHref ? state.medalHref : item.medal_img
          state.medalStyle = { '--width': `${props.medalSize}px`, '--height': `${props.medalSize}px`, '--background': `url(${imageUrl}) 100%/100% no-repeat` }
        }
      })
    })

    const toUserCenter = (id) => {
      if (!state.noPreview) return
      !props.isPreview && myRouter.push({ name: 'personalCenter', query: { id } })
    }
    onMounted(() => {
      props.isWear && store.state.userInfo.userMedal.filter(item => {
        if (item.id === state.id) {
          const imageUrl = state.medalHref ? state.medalHref : item.medal_img
          state.medalStyle = { '--width': `${props.medalSize}px`, '--height': `${props.medalSize}px`, '--background': `url(${imageUrl}) 100%/100% no-repeat` }
        }
      })
    })
    return {
      ...toRefs(state),
      toUserCenter
    }
  }
}
</script>

<style lang="less" scoped>
.user-avatar{
  position: relative;
    margin-right: 13px;
    display: flex;
    justify-content: center;
    // align-items: center;
  .el-image{
    cursor: pointer;
  }
  p{
    display: inline-block;
    color: #fff;
    background: #3f87f5;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  .default-avatar{
    overflow: unset !important;
  }
  .small-size{
    display: inline-block;
    transform: scale(.86);
  }
}

.user-medal .el-image{
  overflow: unset;
  border-radius: 50%;
  :deep(.el-image__inner){
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
  }
  :deep(.el-image__error){
    border-radius: 50%;
    font-size: 12px;
    line-height: 22px;
  }
  :deep(.el-image__placeholder){
    border-radius: 50%;
  }
}
.user-medal .el-image::before,
.user-medal .default-avatar::before {
  content: '';
  position: absolute;
  width: var(--width);
  height: var(--height);
  background: var(--background);
  background-size: 100%;
  pointer-events: none;
  z-index: 2;
  top: calc((100% - var(--width)) / 2);
  left: calc((100% - var(--height)) / 2);
}
</style>
