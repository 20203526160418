import { createRouter, createWebHistory } from 'vue-router'

import layoutModule from './layoutModule'
import prizeModule from './prizeModule'
import otherModule from './otherModule'

import routerPermissions from './routerPermissions'

const routes = [
  ...prizeModule,
  ...layoutModule,
  ...otherModule
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  }
})

routerPermissions(router)

export default router
