import elementResizeDetectorMaker from 'element-resize-detector'
const imgSrc = require('@/assets/img/headerImg.png')
const HANDLER = '_vue_resize_handler'
const errorimg = {
  // 执行时机：绑定了当前指令的元素的所有属性和事件
  inserted (el) {
    // 获取的是真是的dom元素，可以使用dom的所有事件
    el.onerror = function () {
      // 将默认图片赋值给el.src属性
      el.src = imgSrc
    }
  },
  componentUpdated: function (el) {
    el.src = imgSrc
  }
}

const inputMax = {
  mounted (el, { value }) {
    el.querySelector('input').setAttribute('maxlength', value)
  }
}

const chartRsize = {
  bind (el, binding) {
    el[HANDLER] = binding.value ? binding.value : () => {
      if (!el) return
      el.resize()
    }
    // 监听绑定的div大小变化，更新 echarts 大小
    elementResizeDetectorMaker().listenTo(el, el[HANDLER])
  },
  unbind (el) {
    elementResizeDetectorMaker().removeListener(el, el[HANDLER])
    delete el[HANDLER]
  }
}

const showTip = {
  created (el, binding, vnode) {
    const tooltipNode = vnode.children.find((childCmpt) => childCmpt.component?.type.name === 'ElTooltip')
    const getStyle = (obj, name) => window.getComputedStyle ? getComputedStyle(obj, null)[name] : obj.currentStyle[name]
    if (tooltipNode) {
      el.addEventListener('mouseenter', () => {
        tooltipNode.component.props.disabled = true
        const range = document.createRange()
        range.setStart(el, 0)
        range.setEnd(el, el.childNodes.length)
        const rangeWidth = Math.round(range.getBoundingClientRect().width)
        const padding = (parseInt(getStyle(el, 'paddingLeft'), 10) || 0) + (parseInt(getStyle(el, 'paddingRight'), 10) || 0)
        if (rangeWidth + padding > el.offsetWidth || tooltipNode.el.nextElementSibling.scrollWidth > el.offsetWidth) {
          tooltipNode.component.props.disabled = false
        }
      })
    }
  }
}

export default {
  errorimg,
  inputMax,
  chartRsize,
  showTip
}
