<!--
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-10 22:43:46
 * @LastEditors: 何琦 heqi@imyfone.cn
 * @LastEditTime: 2023-04-10 22:44:10
 * @FilePath: \mfadmind:\hq\mfzj\src\components\Lottie.vue
 * @Description:
    src json路径
    jsonData json文件 优先级大于src
    autoplay 自动播放
    loop 循环
-->
<template>
  <div ref="animation" :style="{ width, height }" class="lottie"></div>
</template>

<script>
import { ref, onMounted } from 'vue'
import lottie from 'lottie-web'

export default {
  name: 'Lottie',
  props: {
    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: '100px'
    },
    src: {
      type: String
    },
    jsonData: {
      type: Object,
      require: true,
      default: () => null
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const animation = ref(null)

    onMounted(() => {
      if (animation.value) {
        lottie.loadAnimation({
          container: animation.value,
          renderer: 'svg',
          loop: props.loop,
          autoplay: props.autoplay,
          path: props.src,
          // animationData只能加载本地json，优先级高于path
          animationData: props.jsonData
        })
      }
    })

    return {
      animation
    }
  }
}
</script>

<style scoped>

</style>
