/*
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-12 09:43:57
 * @LastEditTime: 2023-07-10 10:55:53
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\store\modules\okr.js
 */

import { editOkrStatus, getOkrExit } from '@/apis/okr'
const state = {
  menu: [], // 全部菜单信息
  allTargetId: '', // 全部okr最高级部门id 全部目标跳转使用
  localEvaluteLists: {}, // 上级评价草稿
  departmentList: [], // 部门列表
  cycleList: [], // 周期列表
  pickCycleId: '', // 选中的周期id
  highEvaluteData: {}, // 上级评价跳转页面的数据
  evaluateCount: { // 审核与评价数
    audit: 0,
    evaluate: 0
  },
  targetType: '', // 全部目标类型
  allTargetSel: '', // 选择的部门
  toOkrAddId: '',
  highComenting: false,
  evaluateStatusAgo: 0, // 上级去评价前的状态
  createOkrVisible: false // 提示去创建okr的弹窗显隐状态
}
const mutations = {
  setMenu (state, payload) {
    state.menu = payload
  },
  // 保存id
  setAllTargetId (state, payload) {
    state.allTargetId = payload
  },
  // 保存评价
  saveLocalEvaluteList (state, payload) {
    const { id } = payload
    state.localEvaluteLists[id] = payload
  },
  // 删除评价
  deleteLocalEvaluteList (sate, payload) {
    console.log(payload)
    state.localEvaluteLists[payload] = undefined
  },
  setDepartment (state, payload) {
    state.departmentList = payload
  },
  setCycleList (state, payload) {
    state.cycleList = payload
  },
  async setstatus (state, payload) {
    const result = await editOkrStatus({ id: payload.id, status: payload.status })
    console.log(result)
  },
  setHighEvaluteData (state, payload) {
    state.highEvaluteData = payload
  },
  setEvaluateCount (state, payload) {
    const { audit, evaluate } = payload
    state.evaluateCount.audit = audit
    state.evaluateCount.evaluate = evaluate
  },
  setTargetType (state, payload) {
    state.targetType = payload
  },
  setAllTargetSel (state, payload) {
    state.allTargetSel = payload
  },
  setToOkrAddId (state, payload) {
    state.toOkrAddId = payload
  },
  setHighComenting (state, payload) {
    state.highComenting = payload
  },
  setEvaluateStatusAgo (state, payload) {
    state.evaluateStatusAgo = payload
  },
  setCreateOkrVisible (state, payload) {
    state.createOkrVisible = payload
  }
}

const actions = {
  getOkrExitStatus (state) { // 弹出okr提醒弹窗
    getOkrExit().then(({ code, data }) => {
      if (code === 0) {
        if (data.filter((dep) => !dep.is_exist).length) {
          state.commit('setCreateOkrVisible', true)
          const { name } = data.filter((dep) => !dep.is_exist)[0]
          state.commit('setToOkrAddId', { depId: data.filter((dep) => !dep.is_exist)[0].dept_id, name })
        }
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
