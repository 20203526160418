/*
 * @Description:帖子相关接口
 * @Author: heqi
 * @Date: 2021-12-17 11:24:54
 * @LastEditTime: 2024-06-17 10:26:25
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \mfzj\src\apis\forum.js
 */
import { get, post } from '@/apis/method'
/**
 * @description: 获得分区
 */
const getForumCate = (data = { token: '' }) => get('/api/forum/cate', data)

/**
 * @description: 获得分区论坛数据
 */
const getForumPostList = (data) => get('/api/forum/post_list', data)

/**
 * @description: 发布帖子
 */
const forumPublish = (data) => post('/api/forum/publish_post', data)

/**
 * @description: 帖子详情
 */
const forumPostInfo = (id) => get('/api/forum/post_info', { id })

/**
 * @description: 获取评论列表
 */
const feedsCommentlist = (data) => get('/api/feeds/comment_list', data)

/**
 * @description: 删除帖子
 */
const forumDelpost = (data) => post('/api/forum/del_post', data)

// 点赞 收藏 使用动态点赞接口 传参type 2
/**
 * @description: 分享动态
 */
const forumShare = (data) => post('/api/forum/share', data)

/**
 * @修改帖子
 */
const editForumApi = (data) => post('/api/forum/update_post', data)
export {
  getForumCate,
  getForumPostList,
  forumPostInfo,
  feedsCommentlist,
  forumDelpost,
  forumShare,
  forumPublish,
  editForumApi
}
