<template>
  <div class="wrapper">
    <button class="emoji-button"
      type='button'
      ref="emojiWrap"
      @keyup.esc="hidePicker"
      @click.stop="togglePickerVisibility"
      >
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="14" cy="14" r="9" stroke="#999" stroke-width="2"/><path d="M18 16c0 1.657-1.5 3-4 3s-4-1.343-4-3" stroke="#999" stroke-width="2"/><circle cx="11" cy="12" r="2" fill="#999"/><circle cx="17" cy="12" r="2" fill="#999"/></svg>
      <ul :class="['emoji-picker', 'scroll-bar', pickerPosition]" v-show="pickerVisible">
        <li v-for="url in files" :key="url.title" class="emoji-picker-item" @click="handlerSelect(url.url)">
          <button class="img-btn" type='button'>
            <img
              class="emoji-icon"
              width="20"
              height="20"
              v-lazy="url.url"
              alt
            />
          </button>
        </li>
      </ul>
      <ul :class="['emoji-picker', 'scroll-bar', pickerPosition]" v-show="pickerVisibleClone">
         <li v-for="url in files" :key="url.title" class="emoji-picker-item" @click="handlerSelect(url.url)">
          <button class="img-btn" type='button'>
             <img
              class="emoji-icon"
              width="20"
              height="20"
              v-lazy="url.url"
              alt
            />
          </button>
        </li>
      </ul>
    </button>

    <template v-if="isComment || isCommentDialog">
      <p :class="['button-picture', dataurlImage.length > 0 ? 'disabled' : '']" >
      <UploadFile
        @uploadFileInf="uploadCommentFileInf"
        :disabled="dataurlImage.length > 0"
        :commentType="isComment?'comment':'' || isCommentDialog?'commentDialog':''"
        >
        <template #mySlot>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6" width="16" height="16" rx="3" stroke="#999" stroke-width="2"/><circle cx="17" cy="11" r="2" fill="#999"/><path d="M14.5 17.5c.98 0 1.865-.46 2.517-.798l.121-.063C17.893 16.249 18.434 16 19 16c1.386 0 2.068.582 2.457 1.226.432.714.543 1.593.543 2.107A2.667 2.667 0 0 1 19.333 22H9a3 3 0 0 1-3-3v-5.386c1.22-.578 2.02-.655 2.56-.56.594.107 1.06.457 1.556 1.034a15.34 15.34 0 0 1 .748.973c.253.345.535.728.85 1.073.64.705 1.513 1.366 2.786 1.366z" stroke="#999" stroke-width="2"/></svg>
        </template>
      </UploadFile>
      </p>
    </template>
    <template v-if="isBlog || isForum">
      <p :class="['button-picture', (dataurlVideo.length > 0 || percent > 0 || dataurlImage.split('^').length === 9 || !!Object.keys(dataVote).length)? 'disabled' : '']" >
        <UploadFile
          @uploadFileInf="uploadFileInf"
          :disabled="dataurlVideo.length > 0 || percent > 0 || dataurlImage.split('^').length === 9 || !!Object.keys(dataVote).length"
          :limit="dataurlImage.split('^')[0] ? 9 - dataurlImage.split('^').length : 9"
          >
          <template #mySlot>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6" width="16" height="16" rx="3" stroke="#999" stroke-width="2"/><circle cx="17" cy="11" r="2" fill="#999"/><path d="M14.5 17.5c.98 0 1.865-.46 2.517-.798l.121-.063C17.893 16.249 18.434 16 19 16c1.386 0 2.068.582 2.457 1.226.432.714.543 1.593.543 2.107A2.667 2.667 0 0 1 19.333 22H9a3 3 0 0 1-3-3v-5.386c1.22-.578 2.02-.655 2.56-.56.594.107 1.06.457 1.556 1.034a15.34 15.34 0 0 1 .748.973c.253.345.535.728.85 1.073.64.705 1.513 1.366 2.786 1.366z" stroke="#999" stroke-width="2"/></svg>
          </template>
        </UploadFile>
      </p>
      <p v-if="isBlog" :class="['button-video', (dataurlImage.length > 0 || !!Object.keys(dataVote).length) ? 'disabled' : '']">
        <UploadFile
          :disabled="dataurlImage.length > 0 || percent > 0 || !!Object.keys(dataVote).length"
          @uploadFileInf="uploadFileInf"
          type="video"
          @uploadPercent="uploadPercent">
          <template #mySlot>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="5" y="7" width="13" height="14" rx="3" stroke="#999" stroke-width="2"/><path d="M18 11.25a1 1 0 0 1 .4-.8l3-2.25A1 1 0 0 1 23 9v10a1 1 0 0 1-1.6.8l-3-2.25-.6.8.6-.8a1 1 0 0 1-.4-.8v-5.5z" stroke="#999" stroke-width="2"/></svg>
          </template>
        </UploadFile>
      </p
      >
      <p v-if="isBlog" :class="['button-vote', (dataurlImage.length > 0 || dataurlVideo.length > 0 || percent > 0)? 'disabled' : '']">
        <!-- 投票弹窗 -->
        <CreateVote>
          <template #clickButton>
            <svg @click="createVoteDialog" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.332 19.483c-.58 0-1.05-.47-1.05-1.05V7.892a1.05 1.05 0 1 1 2.1 0v10.541c0 .58-.47 1.05-1.05 1.05zm-4.889 0c-.58 0-1.05-.47-1.05-1.05V9.898a1.05 1.05 0 0 1 2.1 0v8.535c0 .58-.47 1.05-1.05 1.05zm-9.777 0c-.58 0-1.05-.47-1.05-1.05v-6.53a1.05 1.05 0 1 1 2.1 0v6.53c0 .58-.47 1.05-1.05 1.05zm4.89 0c-.58 0-1.05-.47-1.05-1.05V6.05a1.05 1.05 0 1 1 2.1 0v12.383c0 .58-.47 1.05-1.05 1.05zM22.955 20.91H5.045a1.045 1.045 0 0 0 0 2.09h17.91a1.045 1.045 0 1 0 0-2.09z" fill="#999"/></svg>
          </template>
        </CreateVote>
      </p>
      <p v-if="isBlog">#话题#：带上双#加入话题哦～</p>
    </template>
  </div>
</template>
<script>
import eventBus from '@/utils/eventBus.js'
import { reactive, toRefs, onMounted, ref, computed, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
// import { fileUrl as files } from '@/utils/filterHtml'
import CreateVote from '@/views/layout/vote/index'
// const requireEmoji = require.context('@/assets/emoji')
// const files = requireEmoji.keys()
export default {
  name: 'EmjoyComment',
  props: {
    triggerPick: {
      tyep: String,
      default: 'click',
      validator (value) {
        return ['hover', 'click'].includes(value)
      }
    },
    pickerPosition: {
      type: String,
      default: 'right',
      validator (value) {
        return ['left', 'middle', 'right'].includes(value)
      }
    },
    isComment: {
      type: Boolean,
      default: false
    },
    isCommentDialog: {
      type: Boolean,
      default: false
    },
    isBlog: {
      type: Boolean,
      default: false
    },
    isForum: {
      type: Boolean,
      default: false
    },
    dataurlVideo: {
      type: String,
      default: ''
    },
    dataurlImage: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 0
    },
    dataVote: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CreateVote
  },
  setup (props, { emit }) {
    const store = useStore()
    store.dispatch('getAllEmojiList')
    const state = reactive({
      pickerVisible: false,
      pickerVisibleClone: false,
      // files: files.map((url) => require(`@/assets/emoji/${url.slice(2)}`)),
      // fileNames: [...require.context('@/assets/emoji', false, /.(png|gif|svg)$/).keys()],
      percent: 0,
      files: computed(() => store.state.allEmojiList),
      scrollTop: 0,
      isDialogs: false,
      isFlagNum: 0
    })
    const emojiWrap = ref(null)

    const hidePicker = () => {
      state.pickerVisible = false
      state.pickerVisibleClone = false
      document.querySelectorAll('.emoji-picker').forEach(i => {
        i.scrollTop = 0
      })
    }

    function insertHtmlAtCaret (html) {
      var sel, range
      if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection() // 获取选中的文本范围或者光标位置
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          var el = document.createElement('div')
          el.innerHTML = html
          var frag = document.createDocumentFragment()
          var node
          var lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          range.insertNode(frag)
          // Preserve the selection
          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if (document.selection && document.selection.type !== 'Control') {
        // IE < 9
        document.selection.createRange().pasteHTML(html)
      }
      return html
    }

    function togglePickerVisibility () {
      if (props.triggerPick === 'click') {
        document.querySelectorAll('.emoji-picker').forEach(i => {
          i.scrollTop = 0
        })
        state.pickerVisible = !state.pickerVisible
        state.pickerVisibleClone = !state.pickerVisibleClone
        eventBus.$emit('focusInput', props.id)
        insertHtmlAtCaret('')
      }
    }

    const docHandleClick = (e) => {
      // console.log(e)
      const thisClassName = e.target.className
      if (emojiWrap.value && emojiWrap.value.contains(e.target)) {
        hidePicker()
      }
      if (thisClassName !== 'emoji-button' && thisClassName !== 'emoji-picker-item') {
        hidePicker()
      }
    }

    const handleKeyup = (e) => {
      if (e.key === 'Escape') {
        hidePicker()
      }
    }

    // 选择表情填入输入框
    function handlerSelect (value) {
      eventBus.$emit('focusInput', props.id)
      const html = insertHtmlAtCaret(`<img data-img="emoji" class='emoji-img' src=${value}>`)
      // const html = insertHtmlAtCaret('[' + value.replace(/(.*\/)*([^.]+).*/ig, '$2') + ']')
      emit('updateContent', html, props.id)
      state.pickerVisible = !state.pickerVisible
      state.pickerVisibleClone = !state.pickerVisibleClone

      setTimeout(() => {
        document.querySelectorAll('.emoji-picker').forEach(i => {
          i.scrollTop = state.scrollTop
        })
      })
    }

    // 子组件上传文件or清空文件
    const uploadFileInf = (type, file) => {
      if (type === 'image') {
        eventBus.$emit('uploadImage', file)
      } else {
        emit('uploadVideoUrl', file && file.url)
      }
    }

    const uploadCommentFileInf = (type, file, commentType) => {
      if (type === 'image') {
        eventBus.$emit('uploadCommentImage', { file, commentType })
      }
    }

    const uploadPercent = (value, file) => {
      state.percent = value
      emit('uploadPercentage', value, file)
    }
    // 初始化表情滚动为0
    const initEmjoyScroll = () => {
      document.querySelectorAll('.emoji-picker').forEach(i => {
        if (i.style.display) {
          i.addEventListener('scroll', (e) => {
            state.scrollTop = e.target.scrollTop
          })
        }
      })
    }

    eventBus.$on('isDialogs', (value) => {
      state.isDialogs = value
    })
    watch(() => state.isDialogs, (newValue) => {
      const dialogEl = document.querySelectorAll('.el-overlay, .el-dialog')
      if (state.isFlagNum !== 2) {
        state.isFlagNum += 1
        document.removeEventListener('click', docHandleClick)
        document.removeEventListener('keyup', handleKeyup)
      }

      if (newValue) {
        dialogEl && initEmjoyScroll(dialogEl)
      } else {
        if (dialogEl.length) {
          for (let i = 0; i < dialogEl.length; i++) {
            dialogEl[i].addEventListener('click', docHandleClick)
          }
        }
      }
    }, { immediate: true })

    onMounted(() => {
      document.addEventListener('click', docHandleClick)
      document.addEventListener('keyup', handleKeyup)

      const dialogList = document.getElementsByClassName('my-dialog')
      if (dialogList.length > 0) {
        for (let i = 0; i < dialogList.length; i++) {
          dialogList[i].addEventListener('click', docHandleClick)
        }
      }
      initEmjoyScroll()
    })
    const createVoteDialog = () => {
      if (!(props.dataurlImage.length > 0 || props.dataurlVideo.length > 0 || state.percent > 0)) {
        store.commit('vote/setVoteVisible', true)
      }
    }

    onUnmounted(() => {
      document.removeEventListener('click', docHandleClick)
      document.removeEventListener('keyup', handleKeyup)
    })

    return {
      ...toRefs(state),
      emojiWrap,
      handlerSelect,
      uploadCommentFileInf,
      hidePicker,
      togglePickerVisibility,
      uploadFileInf,
      uploadPercent,
      createVoteDialog
    }
  }
}
</script>
<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
}
</style>

<style scoped lang="less">
.emoji-picker {
  display: flex;
  flex-wrap: wrap;
  width: 285px;
}
.emoji-picker-item {
  cursor: pointer;
  list-style: none !important;
  img {
    padding: 2px;
    user-select: none;
    &:hover {
      background: #f1f1f2;
      border-radius: 2px;
    }
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  p{
    margin-right: 20px;
    color: @second-text-color;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
  }
  svg {
    border-radius: 50%;
  }
  svg:hover{
    background: #d9d9e580;
    circle {
      fill: @active-text-color;
    }
    path, rect{
      stroke: @active-text-color;
    }
  }
  .emoji-button {
    z-index: 5;
    outline: none;
    border: none;
    background: #fff;
    // margin-bottom: 3px;
    margin-right: 18px;
    // transform: translateY(-2px);
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg:hover{
      background: #d9d9e580;
      border-radius: 50%;
      circle:first-child{
        fill: none;
        stroke: @active-text-color;
      }
    }
  }
  .emoji-picker {
    z-index: 5;
    background: #fff;
    box-shadow: #ccc 1px 1px 7px;
    border-radius: 5px;
    display: flex;
    position: absolute;
    transform: scale(1.5);
    left: 72px;
    top: 77px;
    height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    &.left {
      right: 0;
    }
    &.middle {
      left: 50%;
      transform: translateX(-50%);
    }
    .img-btn {
      outline: none;
      border: none;
      background: #fff;
      cursor: pointer;
    }
  }
  .button-picture{
    :deep(.el-upload-list){
      display: none;
    }
  }
  .button-video, .button-picture, .button-vote{
    cursor: pointer;
    &.disabled{
      svg {
        cursor:not-allowed;
        rect, path {
          stroke: #ccc;
        }
        circle{
          fill: #ccc;
        }
      }
    }
    >div,
    :deep(.el-upload){
      height: 100%;
    }
  }
  .button-vote{
    svg:hover {
      path {
          fill: @active-text-color;
          stroke: none;
        }
      }
  }

}
</style>
