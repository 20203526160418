<!--
 * @Descripttion: 上传文件组件
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-21 11:41:07
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-06 17:55:26
-->
<template>
    <el-upload
      ref="uploadRef"
      action=""
      :before-upload="beforeUpload"
      :http-request="uploadHttpRequest"
      :multiple="type !== 'video'"
      :drag="drag"
      :limit="limit"
      :on-exceed="onExceed"
      :accept="accept"
      :show-file-list="false"
      :disabled="disabled">
      <slot name="mySlot"></slot>
      </el-upload>
</template>

<script>
import { dialogMsg } from '@/utils/dialogMsg.js'
import { uploadFile, uploadVideoFile } from '@/apis/userCenter.js'
import { computed } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { ref, reactive, toRefs } from 'vue'
export default {
  name: 'UploadFile',
  props: {
    limit: { // 限制上传数量
      type: [Number, String],
      default: 9
    },
    drag: { // 是否拖拽
      type: Boolean,
      default: false
    },
    type: { // 文件类型
      type: String,
      default: 'image'
    },
    disabled: { // 不可操作
      type: Boolean,
      default: false
    }
  },
  emits: ['uploadFileInf', 'uploadPercent'],
  setup (props, { emit }) {
    const state = reactive({
      uploadRef: null
    })
    // const accept = computed(() => props.type === 'image' ? '.JPG,.PNG,.GIF,.BMP,.SVG,.heic,.jpeg' : '.mp4,.flv,.wmw,.avi,.3GP')
    const accept = computed(() =>
      props.type === 'image' ? '.JPG,.PNG,.GIF,.BMP,.SVG,.heic,.jpeg'
        : props.type === 'file' ? '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip'
          : '.mp4'
    )
    const imageWhiteList = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg+xml', 'image/jpeg']
    // const videoWhiteList = [
    //   'video/mp4', // mp4
    //   'video/avi', // avi
    //   'video/x-ms-wmv', // wmv
    //   'video/3gpp' // 3GP
    // ]
    const videoWhiteList = [
      'video/mp4' // mp4
    ]
    const route = useRoute()
    const uploadModule = ref(1)
    if (route.name === 'writeJournal') {
      uploadModule.value = 4
    }
    const beforeUpload = (file) => {
      if (props.type === 'image') {
        beforeImageUpload(file)
      } else if (props.type === 'file') {
        beforeFileUpload(file)
      } else {
        beforeVideoUpload(file)
      }
    }
    const beforeImageUpload = (file) => {
      const fileType = file.type
      if (
        (fileType && !imageWhiteList.includes(fileType)) ||
        (!file.name.substring(file.name.lastIndexOf('.') + 1) === 'heic')
      ) {
        dialogMsg(
          'warning',
          '上传文件只能是 jpg、png、gif、bmp、svg、heic、jpeg格式'
        )
        return false
      }
      // 大小要求 10M
      const isLt1M = file.size / 1024 / 1024 < 10
      if (!isLt1M) {
        dialogMsg('warning', '允许上传图片的大小限制为10M')
        return false
      }
      file.timestamp = new Date().getTime()
      emit('uploadFileInf', props.type, file)
      uploadFile(file, 1, () => {}, uploadModule.value).then((res) => {
        if (res.code === 0) {
          file.url = res.data.url
          emit('uploadFileInf', props.type, file)
        } else {
          res.code === 17 ? dialogMsg('error', '文件名长度不能超过191个字符（不分数字、字母和汉字）') : dialogMsg('error', '上传图片失败')
        }
      })
    }

    // 视频校验
    function beforeVideoUpload (file) {
      const size = 500
      const isLtSizeM = file.size / 1024 / 1024 < size
      if (
        videoWhiteList.indexOf(file.type) === -1 &&
        file.name.substr(-3) !== 'flv'
      ) {
        dialogMsg('error', '请上传正确的视频格式')
        return false
      }
      if (!isLtSizeM) {
        dialogMsg('error', `上传视频大小\n不能超过${size}MB哦!`)
        return false
      }

      uploadVideoFile(file, (progress) => {
        const uploadPercent = Math.floor(progress * 100)
        // 反馈上传进度
        emit('uploadPercent', uploadPercent)
      }, (url) => {
        file.url = url
        emit('uploadFileInf', props.type, file)
      }, () => {
        dialogMsg('error', '上传视频失败')
        emit('uploadPercent', -1, file) // 上传失败
      })
    }

    // 文件校验
    function beforeFileUpload (file) {
      // 格式要求 pdf，doc，docx，xls，xlsx，ppt，pptx，zip
      const whiteList = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/x-zip-compressed'
      ]
      const isType = whiteList.includes(file.type)
      if (!isType) {
        dialogMsg('error', '仅允许上传pdf，doc，docx，xls，xlsx，ppt，pptx，zip格式的文件')
      }
      // 大小要求 30M
      const isLimit = file.size / 1024 / 1024 < 30
      if (!isLimit) {
        dialogMsg('error', '附件限制大小为30M')
      }
      if (isType && isLimit) {
        uploadFile(file, 2).then((res) => {
          if (res.code === 0) {
            file.url = res.data.url
            emit('uploadFileInf', props.type, file)
          }
        }).catch((err) => {
          console.log(err)
          // dialogMsg('error', err)
        })
      }
    }

    const onExceed = () => {
      dialogMsg('warning', `文件超过上限，最多还可上传${props.limit}份文件`)
    }
    // 自定义上传方法
    const uploadHttpRequest = () => {
      // 修复上传后报404
      state.uploadRef.clearFiles()
      return false
    }

    return {
      ...toRefs(state),
      beforeUpload,
      onExceed,
      accept,
      uploadHttpRequest
    }
  }
}
</script>
