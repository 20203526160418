/*
 * @Description:axios请求方式
 * @Author: heqi
 * @Date: 2022-01-06 09:52:17
 * @LastEditTime: 2023-02-13 19:20:59
 * @LastEditors: 何琦 heqi@imyfone.cn
 * @FilePath: \mfzj\src\apis\method.js
 */
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
import request from '@/utils/request'
export function get (url, params = { token: '' }, type) {
  return request({
    url,
    method: 'get',
    params,
    type
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, data = { token: '' }, type) {
  return request({
    url,
    method: 'post',
    data,
    type
  })
}
