<!--
 * @Descripttion: 输入框附加内容
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-22 14:46:00
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-09-12 10:49:46
-->

<template>
  <div class="wrapper">
    <template v-if="props.isEmoBtnVisible">
      <button class="emoji-button"
        type='button'
        ref="emojiWrap"
        @keyup.esc="hidePicker"
        @click.stop="togglePickerVisibility"
        >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="14" cy="14" r="9" stroke="#999" stroke-width="2"/><path d="M18 16c0 1.657-1.5 3-4 3s-4-1.343-4-3" stroke="#999" stroke-width="2"/><circle cx="11" cy="12" r="2" fill="#999"/><circle cx="17" cy="12" r="2" fill="#999"/></svg>
        <ul :class="['emoji-picker', 'scroll-bar', pickerPosition]" v-show="pickerVisible">
          <li v-for="url in files" :key="url.title" class="emoji-picker-item" @click="handlerSelect(url.url)">
            <button class="img-btn" type='button'>
              <img
                class="emoji-icon"
                width="20"
                height="20"
                v-lazy="url.url"
                alt
              />
            </button>
          </li>
        </ul>
        <ul :class="['emoji-picker', 'scroll-bar', pickerPosition]" v-show="pickerVisibleClone">
          <li v-for="url in files" :key="url.title" class="emoji-picker-item" @click="handlerSelect(url.url)">
            <button class="img-btn" type='button'>
              <img
                class="emoji-icon"
                width="20"
                height="20"
                v-lazy="url.url"
                alt
              />
            </button>
          </li>
        </ul>
      </button>
    </template>

    <template v-if="props.isImgBtnVisible">
      <p :class="['button-picture', props.isImgBtnDisabled ? 'disabled' : '']" >
        <UploadFile
          @uploadFileInf="uploadFileInf"
          :disabled="props.isImgBtnDisabled"
          :limit="limit"
          >
          <template #mySlot>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6" width="16" height="16" rx="3" stroke="#999" stroke-width="2"/><circle cx="17" cy="11" r="2" fill="#999"/><path d="M14.5 17.5c.98 0 1.865-.46 2.517-.798l.121-.063C17.893 16.249 18.434 16 19 16c1.386 0 2.068.582 2.457 1.226.432.714.543 1.593.543 2.107A2.667 2.667 0 0 1 19.333 22H9a3 3 0 0 1-3-3v-5.386c1.22-.578 2.02-.655 2.56-.56.594.107 1.06.457 1.556 1.034a15.34 15.34 0 0 1 .748.973c.253.345.535.728.85 1.073.64.705 1.513 1.366 2.786 1.366z" stroke="#999" stroke-width="2"/></svg>
          </template>
        </UploadFile>
      </p>
    </template>
    <template v-if="props.isVideoBtnVisible">
      <p :class="['button-video', props.isVideoBtnDisabled  ? 'disabled' : '']">
        <UploadFile
          :disabled="props.isVideoBtnDisabled"
          @uploadFileInf="uploadFileInf"
          type="video"
          @uploadPercent="uploadPercent">
          <template #mySlot>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="5" y="7" width="13" height="14" rx="3" stroke="#999" stroke-width="2"/><path d="M18 11.25a1 1 0 0 1 .4-.8l3-2.25A1 1 0 0 1 23 9v10a1 1 0 0 1-1.6.8l-3-2.25-.6.8.6-.8a1 1 0 0 1-.4-.8v-5.5z" stroke="#999" stroke-width="2"/></svg>
          </template>
        </UploadFile>
      </p>
    </template>
    <template v-if="props.isVoteBtnVisible">
        <p :class="['button-vote', props.isVoteBtnDisabled ? 'disabled' : '']">
          <!-- 投票弹窗 -->
          <CreateVote>
            <template #clickButton>
              <svg @click="createVoteDialog" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.332 19.483c-.58 0-1.05-.47-1.05-1.05V7.892a1.05 1.05 0 1 1 2.1 0v10.541c0 .58-.47 1.05-1.05 1.05zm-4.889 0c-.58 0-1.05-.47-1.05-1.05V9.898a1.05 1.05 0 0 1 2.1 0v8.535c0 .58-.47 1.05-1.05 1.05zm-9.777 0c-.58 0-1.05-.47-1.05-1.05v-6.53a1.05 1.05 0 1 1 2.1 0v6.53c0 .58-.47 1.05-1.05 1.05zm4.89 0c-.58 0-1.05-.47-1.05-1.05V6.05a1.05 1.05 0 1 1 2.1 0v12.383c0 .58-.47 1.05-1.05 1.05zM22.955 20.91H5.045a1.045 1.045 0 0 0 0 2.09h17.91a1.045 1.045 0 1 0 0-2.09z" fill="#999"/></svg>
            </template>
          </CreateVote>
        </p>
    </template>
    <template v-if="props.isTimeBtnVisible ">
      <p :class="['button-time', props.isTimeBtnDisabled ? 'disabled' : '']" >
        <svg @click="toggleTimeVisible"  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="7" y="9" width="14" height="14" rx="7" stroke="#999" stroke-width="2"/><rect x="10" y="4" width="8" height="2" rx="1" fill="#999"/><rect x="15" y="12" width="5" height="2" rx="1" transform="rotate(90 15 12)" fill="#999"/><rect x="18" y="17" width="5" height="2" rx="1" transform="rotate(-180 18 17)" fill="#999"/><rect x="15" y="4" width="6" height="2" rx="1" transform="rotate(90 15 4)" fill="#999"/></svg>
      </p>
    </template>
    <template v-if="props.isTextVisible">
      <p >#话题#：带上双#加入话题哦～ </p>
    </template>
  </div>
</template>
<script setup>
import eventBus from '@/utils/eventBus.js'
import { reactive, toRefs, onMounted, computed, onUnmounted, watch, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import CreateVote from '@/views/layout/vote/index'

const props = defineProps({
  triggerPick: {
    type: String,
    default: 'click',
    validator (value) {
      return ['hover', 'click'].includes(value)
    }
  },
  pickerPosition: {
    type: String,
    default: 'right',
    validator (value) {
      return ['left', 'middle', 'right'].includes(value)
    }
  },
  isEmoBtnVisible: { // 添加表情按钮是否可见
    type: Boolean,
    default: true
  },
  isImgBtnVisible: { // 上传图片按钮是否可见
    type: Boolean,
    default: false
  },
  imgLimit: { // 上传图片最大数量
    type: Number,
    default: 1
  },
  isVideoBtnVisible: { // 上传视频按钮是否可见
    type: Boolean,
    default: false
  },
  isVoteBtnVisible: { // 发起投票按钮是否可见
    type: Boolean,
    default: false
  },
  isTimeBtnVisible: { // 定时发布按钮是否可见
    type: Boolean,
    default: false
  },
  isTextVisible: { // 文案是否可见
    type: Boolean,
    default: false
  },
  isImgBtnDisabled: { // 上传图片按钮是否不可点击
    type: Boolean,
    default: false
  },
  isVideoBtnDisabled: { // 上传视频按钮是否不可点击
    type: Boolean,
    default: false
  },
  isVoteBtnDisabled: { // 发起投票按钮是否不可点击
    type: Boolean,
    default: false
  },
  isTimeBtnDisabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['updateContent', 'focusInput'])
const store = useStore()
store.dispatch('getAllEmojiList')

const state = reactive({
  emojiWrap: null,
  pickerVisible: false,
  pickerVisibleClone: false,
  percent: 0,
  files: computed(() => store.state.allEmojiList),
  scrollTop: 0,
  isDialogs: false,
  isFlagNum: 0,
  limit: computed(() => props.imgLimit)
})

const { emojiWrap, files, pickerVisible, pickerVisibleClone, limit } = toRefs(state)

const hidePicker = () => {
  state.pickerVisible = false
  state.pickerVisibleClone = false
  document.querySelectorAll('.emoji-picker').forEach(i => {
    i.scrollTop = 0
  })
}

function insertHtmlAtCaret (html) {
  var sel, range
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection() // 获取选中的文本范围或者光标位置
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()
      var el = document.createElement('div')
      el.innerHTML = html
      var frag = document.createDocumentFragment()
      var node
      var lastNode
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      range.insertNode(frag)
      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    } else if (document.selection && document.selection.type !== 'Control') {
    // IE < 9
      document.selection.createRange().pasteHTML(html)
    }
    return html
  }
}

function togglePickerVisibility () {
  if (props.triggerPick === 'click') {
    document.querySelectorAll('.emoji-picker').forEach(i => {
      i.scrollTop = 0
    })
    state.pickerVisible = !state.pickerVisible
    state.pickerVisibleClone = !state.pickerVisibleClone
    insertHtmlAtCaret('')
  }
}

const docHandleClick = (e) => {
  const thisClassName = e.target.className
  if (state.emojiWrap && state.emojiWrap.contains(e.target)) {
    hidePicker()
  }
  if (thisClassName !== 'emoji-button' && thisClassName !== 'emoji-picker-item') {
    hidePicker()
  }
  // 解决点击表情没有光标不能编辑的问题
  if (thisClassName === 'emoji-img') { // 点击了表情
    e.preventDefault()
    const img = e.target
    // 创建并插入一个空白的 span 元素
    const blankSpan = document.createElement('span')
    blankSpan.innerHTML = '&#8203;' // 零宽度空白字符
    img.parentNode.insertBefore(blankSpan, img.nextSibling)

    // 将光标移动到新插入的 span 内
    const range = document.createRange()
    const selection = window.getSelection()
    range.setStartAfter(img)
    range.collapse(true)
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

const handleKeyup = (e) => {
  if (e.key === 'Escape') {
    hidePicker()
  }
}

// 选择表情填入输入框
function handlerSelect (value) {
  emit('focusInput')
  setTimeout(() => {
    const html = insertHtmlAtCaret(`<img data-img="emoji" class='emoji-img' src=${value}>`)
    emit('updateContent', html, props.id)
    state.pickerVisible = !state.pickerVisible
    state.pickerVisibleClone = !state.pickerVisibleClone
    document.querySelectorAll('.emoji-picker').forEach(i => {
      i.scrollTop = state.scrollTop
    })
  })
}

// 子组件上传文件or清空文件
const uploadFileInf = (type, file) => {
  if (type === 'image') {
    eventBus.$emit('uploadImage', { file, id: props.id })
  } else {
    eventBus.$emit('uploadVideo', file && file.url)
  }
}

const uploadPercent = (value, file) => {
  state.percent = value
  eventBus.$emit('uploadPercentage', value, file)
}

// 初始化表情滚动为0
const initEmjoyScroll = () => {
  document.querySelectorAll('.emoji-picker').forEach(i => {
    if (i.style.display) {
      i.addEventListener('scroll', (e) => {
        state.scrollTop = e.target.scrollTop
      })
    }
  })
}

eventBus.$on('isDialogs', (value) => {
  state.isDialogs = value
})

watch(() => state.isDialogs, (newValue) => {
  const dialogEl = document.querySelectorAll('.el-overlay, .el-dialog')
  if (state.isFlagNum !== 2) {
    state.isFlagNum += 1
    document.removeEventListener('click', docHandleClick)
    document.removeEventListener('keyup', handleKeyup)
  }

  if (newValue) {
    dialogEl && initEmjoyScroll(dialogEl)
  } else {
    if (dialogEl.length) {
      for (let i = 0; i < dialogEl.length; i++) {
        dialogEl[i].addEventListener('click', docHandleClick)
      }
    }
  }
}, { immediate: true })

onMounted(() => {
  document.addEventListener('click', docHandleClick)
  document.addEventListener('keyup', handleKeyup)

  const dialogList = document.getElementsByClassName('my-dialog')
  if (dialogList.length > 0) {
    for (let i = 0; i < dialogList.length; i++) {
      dialogList[i].addEventListener('click', docHandleClick)
    }
  }
  initEmjoyScroll()
})

const createVoteDialog = () => {
  if (!props.isVoteBtnDisabled) {
    store.commit('vote/setVoteVisible', true)
  }
}

const toggleTimeVisible = () => {
  if (!props.isTimeBtnDisabled) {
    eventBus.$emit('toggleTimeVisible')
  }
}

onUnmounted(() => {
  document.removeEventListener('click', docHandleClick)
  document.removeEventListener('keyup', handleKeyup)
})

</script>
<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
}
</style>

<style scoped lang="less">
.emoji-picker {
  display: flex;
  flex-wrap: wrap;
  width: 285px;
}
.emoji-picker-item {
  cursor: pointer;
  list-style: none !important;
  img {
    padding: 2px;
    user-select: none;
    &:hover {
      background: #f1f1f2;
      border-radius: 2px;
    }
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  p{
    margin-right: 20px;
    color: @second-text-color;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
  }
  svg {
    border-radius: 50%;
  }
  svg:hover{
    background: #d9d9e580;
    circle {
      fill: @active-text-color;
    }
    path, rect{
      stroke: @active-text-color;
    }
  }
  .emoji-button {
    z-index: 5;
    outline: none;
    border: none;
    background: #fff;
    // margin-bottom: 3px;
    margin-right: 18px;
    // transform: translateY(-2px);
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg:hover{
      background: #d9d9e580;
      border-radius: 50%;
      circle:first-child{
        fill: none;
        stroke: @active-text-color;
      }
    }
  }
  .emoji-picker {
    z-index: 5;
    background: #fff;
    box-shadow: #ccc 1px 1px 7px;
    border-radius: 5px;
    display: flex;
    position: absolute;
    transform: scale(1.5);
    left: 72px;
    top: 77px;
    height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    &.left {
      right: 0;
    }
    &.middle {
      left: 50%;
      transform: translateX(-50%);
    }
    .img-btn {
      outline: none;
      border: none;
      background: #fff;
      cursor: pointer;
    }
  }
  .button-picture{
    :deep(.el-upload-list){
      display: none;
    }
  }
  .button-video, .button-picture, .button-vote, .button-time{
    cursor: pointer;
    &.disabled{
      svg {
        cursor:not-allowed;
        rect, path {
          stroke: #ccc;
        }
        circle{
          fill: #ccc;
        }
      }
    }
    >div,
    :deep(.el-upload){
      height: 100%;
    }
  }
  .button-vote{
    svg:hover {
      path {
          fill: @active-text-color;
          stroke: none;
        }
      }
    &.disabled svg path{
      fill: #ccc;
    }
  }
  .button-time{
    svg:hover rect:nth-child(1){
      stroke: @active-text-color;
    }
    svg:hover rect:not(:nth-child(1)){
      fill: @active-text-color;
    }
  }

}
</style>
