/*
 * @Description: 打卡文案
 * @Author: heqi
 * @Date: 2022-01-27 10:04:40
 * @LastEditTime: 2022-04-26 10:28:23
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \mfzj\src\utils\attendceText.js
 */

export const textObj = {
  2: [
    '加油保持良好的记录。',
    '早呀～!麦风人!',
    '早，今天也要努力鸭',
    '麦风踩点王非你莫属!',
    '今天~也是元气满满的一天!',
    '早呀！今天心情怎么样？发个动态说说吧！'
  ],
  3: [
    '上了一天班辛苦了，回去好好放松一下吧!',
    '终于撑到点了，我要不行了。',
    '忙忙碌碌一天又结束了，好充实。',
    '今天的工作有成就感吗？',
    '今晚吃啥？红烧茄子小炒肉!',
    '看看有没有东西漏了拿。',
    '运动减肥不能落下，出发麦风健身房!',
    '工作完成，可以撤退～!',
    '开心嘅时候过得特别快，又系时候讲Bye Bye!',
    '今天的工作一定很多吧',
    '真好呀，睡一觉又能见到你了',
    '啊！又是被自己感动的一天！'
  ],
  4: [
    '迟到5分钟加班35分钟，还不如早起5分钟。',
    '今天地铁又塞车了吗？',
    '脚步放轻小心别被发现。',
    '再迟到可是要扣钱了!',
    '迟到啦，下次早点出门哦!'
  ],
  5: [
    '哎，干哈呢？还没到下班时间就走了。',
    '哎咱们也不差那几分钟半小时的是吧。',
    '是身体有什么不舒服吗？'
  ],
  6: [
    '上哪溜达去啦，班都忘记上了哈!',
    '小心下次回来工位都没了 :(',
    '终于等到你，还好我没放弃～',
    '这么晚!下次不许了哦!'
  ],
  7: [
    '才几点呐0.0，是不是签错退了？',
    '是身体有什么不舒服吗？'
  ]
}

// 特殊提示的文案，优先级高于其他的提示文案
export const specialTextList = [{
  // 20:30至次日06:00，下班打卡提示
  min: '20 : 30 : 00',
  max: '06 : 00 : 00',
  signState: 3,
  text: '下班后各部门最后走的小伙伴不要忘记关灯和关空调哦~'
}]
