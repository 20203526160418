<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-13 13:54:07
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-05-13 13:54:08
-->

<template>
   <el-form-item required class="form-title">
      <el-input
        v-model="formInline.title"
        placeholder="请输入标题"
        maxlength="40"
        show-word-limit
        id="title"
        @input="showDialog"
        @keydown.enter="contentFocus"
        @focus="optDisabled"
        @blur="showOptions"
      ></el-input>
    <vue-ueditor-wrap
      v-model="formInline.content"
      :config="editorConfig"
      editor-id="editorUe"
      @ready="readyEditor"
      ref="editorUe"
    ></vue-ueditor-wrap>
    </el-form-item>
</template>

<script setup>
import { reactive, toRefs, computed, defineProps } from 'vue'
import { myToast } from '@/utils/dialogMsg.js'
import { getCookies } from '@/utils/auth'

const props = defineProps({
  form: {
    type: Object,
    default: () => {}
  }
})

const state = reactive({
  ue: null,
  formInline: computed(() => props.form),
  editorConfig: {
    UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径，可参考常见问题1
    serverUrl:
        process.env.VUE_APP_URL + 'ueditor/index' + '?token=' + getCookies('token'), // 上传文件url
    initialFrameHeight: '730',
    initialFrameWidth: 'auto',
    focus: false,
    autoFloatEnabled: true,
    autoHeightEnabled: false,
    insertorderedlist: {
      decimal: '',
      'lower-alpha': '', // 'a,b,c...'
      'lower-roman': '', // 'i,ii,iii...'
      'upper-alpha': '', // 'A,B,C'
      'upper-roman': '' // 'I,II,III...'
    },
    insertunorderedlist: {
      circle: '', // '○ 小圆圈'
      disc: '', // '● 小圆点'
      square: '' // '■ 小方块'
    },
    toolbars: [
      [
        'undo', // 撤销
        'redo', // 重做
        'bold', // 加粗
        'italic', // 斜体
        'forecolor', // 字体颜色
        'backcolor', //  背景颜色
        'link', // 超链接
        'underline', // 下划线
        'strikethrough', // 删除线
        'horizontal', // 分隔线
        'insertimage', // 多图上传
        'emotion', // 表情
        'insertvideo', // 视频
        'justifyleft', // 居左对齐
        'justifyright', // 居右对齐
        'justifycenter', // 居中对齐
        'justifyjustify', // 两端对齐
        'insertorderedlist', // 有序列表
        'insertunorderedlist', // 无序列表
        'lineheight', // 行间距
        'fontfamily', // 字体sss
        'fontsize', // 字号
        'paragraph', // 段落格式
        'inserttable',
        'deletetable',
        'insertparagraphbeforetable',
        'attachment'
      ]
    ]
  }

})

const { formInline, editorConfig } = toRefs(state)

const readyEditor = (editorInstance) => {
  state.ue = editorInstance
}

const contentFocus = () => {
  state.ue.focus()
}

const optDisabled = () => {
  document.querySelectorAll('.edui-box').forEach(i => i.classList.add('edui-state-disabled'))
}

// 显示按钮
const showOptions = () => {
  document.querySelectorAll('.edui-box').forEach(i => i.classList.remove('edui-state-disabled'))
}

// 字数限制显示
const showDialog = () => {
  if (state.formInline.title.length >= 40) {
    myToast({ type: 'warning', message: '标题最多输入40个字符' })
  }
}
</script>
