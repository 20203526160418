/*
 * @Description:微博与消息通知相关接口
 * @Author: heqi
 * @Date: 2022-01-06 12:25:13
 * @LastEditTime: 2024-08-05 10:16:34
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \cbsd:\hq\mfzj\src\apis\blogs.js
 */
import { get, post } from '@/apis/method'

/**
 * @description:获取banner
 */
const getBanner = (data) => get('/api/advertising/list', data)

/**
 * @description: 获得动态列表
 */
const getFeedsList = (data) => get('/api/feeds/list', data)

/**
 * @description: 删除动态
 */
const removeBlog = (data) => post('/api/feeds/feed_delete', data)

/**
 * @description: 点击收藏
 */
const feedsCollected = (data) => post('/api/feeds/collected', data)

/**
 * @description: 点赞
 */
const feedsFeedlike = (data) => post('/api/feeds/feed_like', data)

/**
 * @description: 发布动态
 */
const feedsPublishfeed = (data) => post('/api/feeds/publish_feed', data)

/**
 * @description: 获取评论列表
 */
const feedsCommentList = (data) => get('/api/feeds/comment_list', data)

/**
 * @description: 获取带层级的评论列表
 */
const feedsCommentLists = (data) => get('/api/feeds/comment_list_pc', data)

/**
 * @description: 发布评论及回复
 */
const feedsFeedComment = (data) => post('/api/feeds/feed_comment', data)

/**
 * @description: 删除评论 或 回复
 */
const feedsDeleteComment = (data) => post('/api/feeds/delete_comment', data)

/**
 * @description: 上传
 */
const uploadUploadFile = (data) =>
  post('/api/upload/upload_file', {
    file: data,
    type: 1
  })

/**
 * @description: @人列表
 */
const getUserAll = (data) => get('/api/user/user_all', data)

/**
 * @description: 获取未读消息总数
 */
const getNoteCount = (data = { token: '' }) => get('/api/note/count', data)

/**
 * @description: 消息通知 回复物我的
 */
const getMessageReplyList = (data) => get('/api/message/reply_list', data)

/**
 * @description: 被收藏的
 */
const getMessageCollectlist = (data) => get('/api/message/collect_list', data)

/**
 * @description: 收到的赞
 */
const getMessageLiketlist = (data) => get('/api/message/like_list', data)

/**
 * @description: @我的
 */
const getMessageAtList = (data) => get('/api/message/at_list', data)

/**
 * @description: 获取未读数
 */
const getNoReadNum = (data = { token: '' }) =>
  get('/api/note/no_read_num', data)

/**
 * @description: 清除消息通知数量
 */
const getClearNoReadNum = (data = { token: '' }) =>
  get('/api/note/clear', data)

/**
 * @description: 消息通知中动态详情
 */
const getFeedsFeedInfo = (data) => get('/api/feeds/feed_info', data)

/**
 * @description: 系统通知
 */
const getMessageList = (data) => get('/api/message/system_list', data)

/**
 * @description: 点赞详情列表
 */
const getFeedsLikeList = (data) => get('/api/feeds/like_list', data)

/**
 * @description: 评论置顶
 */
const postCommentTop = (data) => post('/api/feeds/comment_top', data)

/**
 * @param {*} data
 * @returns 获取oss配置
 */
const getUploadConfig = (data) => post('/api/upload/upload_config', data)

/**
 * @param {*} data
 * @returns 阅读微博
 */
const setBlogRead = (data) => get('/api/feeds/add_view', data)

/**
 * @description: 清除单条消息通知数量
 */
const messageClearOne = (data) => get('/api/note/clearone', data)

/**
 * @param {*} data
 * @returns 获取阅读详情
 */
const getBlogsReadList = (data) => get('/api/feeds/read_list', data)

/**
 * @param {*} data
 * @returns 获取话题搜索数据
 */
const getTopicList = (data) => get('/api/feeds/search_topic', data)

/**
 *
 * @param {*} data
 * @returns 更新动态
 */
const updateFeed = (data) => post('/api/feeds/update_feed', data)

export {
  getBanner,
  getFeedsList,
  removeBlog,
  feedsCollected,
  feedsFeedlike,
  feedsPublishfeed,
  feedsCommentList,
  feedsFeedComment,
  feedsDeleteComment,
  uploadUploadFile,
  getUserAll,
  getNoteCount,
  getMessageReplyList,
  getMessageCollectlist,
  getMessageLiketlist,
  getMessageAtList,
  getNoReadNum,
  getClearNoReadNum,
  getFeedsFeedInfo,
  getMessageList,
  getFeedsLikeList,
  postCommentTop,
  feedsCommentLists,
  getUploadConfig,
  setBlogRead,
  messageClearOne,
  getBlogsReadList,
  getTopicList,
  updateFeed
}
