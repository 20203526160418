<template>
  <div class="header-right">
    <router-link :to="{ name: 'prizeSetting' }" target="_blank">
      <img class="prize-icon" src="@/assets/img/common/prize-icon.png" alt="prize-icon" >
    </router-link>

    <!-- 无需考勤人员不显示 -->
    <template v-if="work.clockState && work.clockState.group_id !== -1">
      <el-icon class="abnorma-icon" @click="showClockAbnorma" v-if="work.clockAbnorma.length && !isVirtualUser && store.state.userInfo.isWhiteList !== -1" color="#ffa800" :size="19"><warning-filled /></el-icon>
      <div class="state-sign-wrapper" v-if="(!isVirtualUser && store.state.userInfo.isWhiteList !== -1)">
        <span class="state-sign" @click="showAccordingSign">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="3" width="18" height="15" rx="3" fill="#7972F0"/><rect x="13" y="1" width="2" height="4" rx="1" stroke="#F2F1FE" stroke-width="2"/><rect x="5" y="1" width="2" height="4" rx="1" stroke="#F2F1FE" stroke-width="2"/><rect x="13.5" y=".5" width="1" height="4" rx=".5" stroke="#7972F0"/><rect x="5.5" y=".5" width="1" height="4" rx=".5" stroke="#7972F0"/><path d="M6.5 10.5L8.91 13 13 8.91" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <span v-if="work.clockState">{{ work.clockState.sign_type === 1 ? '签到' :  work.clockState.sign_type === 2 ? '签退' : '已签退' }}</span>
        </span>
      </div>
    </template>
    <span
      class="message-icon user-msg"
      @click="router.push({ name: 'notification' })"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="15" r="3" stroke="#333" stroke-width="2" />
        <path
          d="M11.995 3.865l.036.533.463.267A4.998 4.998 0 0 1 15 9v2.987l.26.286.959 1.054c.515.567.22 1.46-.471 1.64L15.62 15H4.38l-.128-.033c-.692-.18-.986-1.073-.47-1.64l.958-1.054.26-.286V9a4.998 4.998 0 0 1 2.506-4.335l.463-.267.035-.533a2 2 0 0 1 3.992 0z"
          fill="#fff"
          stroke="#333"
          stroke-width="2"
        />
      </svg>
      <span class="msg-count" v-if="store.state.messageCount > 0">{{
        store.state.messageCount > 99 ? '99+' : store.state.messageCount
      }}</span>
    </span>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link" @mouseover="showPersonal">
        <div class="user-info">
          <UserAvatar
            :user="store.state.userInfo.userInfos"
            :size="'34'"
            :fontSize="'13'"
            medalSize="50"
          ></UserAvatar>
        </div>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="toCenter">个人中心</el-dropdown-item>
          <el-dropdown-item command="exit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  <!-- 打卡弹窗 -->
  <el-dialog append-to-body v-model="dialogVisible" custom-class="my-attendance-dialog" :show-close="false" :width="350" :before-close="outerClose">
    <!-- 签到 签退 更新签退 通过获取打卡详情中的状态来判断 -->
    <!-- 打卡结果通过打卡之后的时间计算-->
    <img :src="require(`@/assets/img/common/sign${isSubmitingWorkStatue ? '6' : '5'}.png`)" >

    <div class="dialog-content">
      <div class="dialog-sign-text" :style="{'color': signTextColor}">
        <template v-if="work.clockState">
          <template v-if="work.clockState.sign_type === 1">上班签到</template>
          <template v-else-if="[2,3].includes(work.clockState.sign_type )">下班签退</template>
        </template>
      </div>
      <SlideSign @successSlide='successSlide' slideType="dialog" ref="slideSignRef"></SlideSign>
    </div>
    <el-dialog v-model="innerVisible" custom-class="my-attendance-dialog" :show-close="false" :width="350" append-to-body :before-close="beforeInnerClose">
      <template #default>
        <img v-if="[2,4,6,7].includes(work.signState)" src="@/assets/img/common/sign2.png">
        <img v-else-if="work.signState === 3" src="@/assets/img/common/sign4.png">
        <img v-else-if="work.signState === 5" src="@/assets/img/common/sign3.png">
        <div class="dialog-content">
          <div class="dialog-sign-text dialog-sign-text-inner" :style="{'color': signTextColor}">
            {{signTextList[work.signState]}}
          </div>
          <div class="signState-clock" :style="{'color': signTextColor}">{{ time }}</div>
          <div class="dialog-successText">{{ successText }}</div>
        </div>
      </template>
    </el-dialog>
  </el-dialog>
  <!-- 异常提醒弹窗 -->
  <el-dialog append-to-body v-model="dialogAbnormaVisible" :show-close="false" center custom-class="abnorma-dialog">
    <template #title>
      <el-icon class="abnorma-icon" color="#ffa800" :size="19"><warning-filled /></el-icon><span class="remind-title">考勤异常提醒</span>
    </template>
    <template #default>
    <p>您近三天有异常的打卡记录，为避免影响您的考勤统计结果，请前往钉钉提交异常考勤日的审批申请。异常情况如下:</p>
    <table cellSpacing="0">
      <tr v-for="item in work.clockAbnorma" :key="item">
        <td>
          {{(new Date(item.work_date).getMonth() + 1) + '月' + (new Date(item.work_date).getDate()) + '日'}}
        </td>
        <td>
          <span>{{getClockAbnorma(item).onduty}} {{getClockAbnorma(item).offduty}}</span>
        </td>
      </tr>
    </table>
    <div class="remind-tail">提示：钉钉补卡或请假审批通过后，此处状态同步改变需要一定时间</div>
    </template>
    <template #footer>
      <el-button @click="toAttendanceStatic">查看考勤统计</el-button>
    </template>
  </el-dialog>

  <!-- 未写日志提示 -->
    <el-dialog custom-class="submit-log" v-model="writeJournalVisible" center :show-close="false">
      <WriteJournalDialog @close="writeJournalVisible = false"/>
    </el-dialog>

  </div>
</template>

<script>
import { watch, ref, reactive, toRefs, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getCookies } from '@/utils/auth.js'
import { logout } from '@/apis/login.js'
import SlideSign from '@/views/layout/work/component/SlideSign'
import WriteJournalDialog from '@/views/layout/work/component/WriteJournalDialog'
import { textObj, specialTextList } from '@/utils/attendceText.js'
import eventBus from '@/utils/eventBus.js'
import { getJournalLogStatu, setBuriedPoint } from '@/apis/journal.js'
import SocketService from '@/utils/websocket.js'

export default {
  name: 'HeaderRight',
  components: {
    SlideSign,
    WriteJournalDialog
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const msgCount = ref(0)
    const isVirtualUser = computed(() => store.getters['userInfo/isVirtualUser'])
    watch(() => route.path, (newval) => {
      newval !== '/login' && store.dispatch('getMessageCount')
    }, { immediate: true })

    const outToLogin = async () => {
      sessionStorage.removeItem('timeId')
      await store.dispatch('userInfo/clearLoginInfo')
      SocketService.Instance.close()
      router.push('/login')
    }

    const handleCommand = (command) => {
      if (command === 'toCenter') {
        router.push({
          name: 'personalCenter',
          query: { id: store.state.userInfo.userInfos.id }
        })
      } else {
        const token = getCookies('token')
        logout({ token: token, login_from: 'pc' })
          .then((res) => {
            outToLogin()
          })
          .catch(() => {
            outToLogin()
          })
      }
    }

    // console.log(userInfo)
    const state = reactive({
      dialogVisible: false,
      innerVisible: false,
      work: computed(() => store.state.work),
      clockTime: computed(() => store.state.work.signTime && store.state.work.signTime.slice(-8)),
      timeId: null,
      signTextList: ['上班签到', '下班签退', '上班签到成功', '下班签退成功', '迟到签到成功', '早退签退成功', '旷工签到成功', '旷工签退成功'],
      signTextColor: '',
      successText: null,
      time: computed(() => state.work.signTime?.split(' ')[1]?.replaceAll(':', ' : ') || ''), // 打卡后暂停时间;
      dialogAbnormaVisible: false,
      autoSignOut: 0 || computed(() => { return (store.state.work.autoSignOut && store.state.work.hasSubmitJournal) }),
      writeJournalVisible: false, // 写日志弹窗提示
      isSubmitingWorkStatue: computed(() => store.state.work.currentTime.split(':')[0] >= 18),
      noCloseTimeIdRouteName: ['work', 'writeJournal'] // 对应路由不关闭定时器
    })

    watch(() => state.dialogVisible, (newVal) => {
      if (newVal) {
        store.dispatch('work/getServerClock')
      }
      if (!newVal && !state.noCloseTimeIdRouteName.includes(route.name)) {
        clearInterval(sessionStorage.getItem('timeId'))
        sessionStorage.removeItem('timeId')
      }
    }, { immediate: true })

    watch(() => route.name, (newVal) => {
      if (state.noCloseTimeIdRouteName.includes(newVal)) {
        store.dispatch('work/getServerClock')
      } else {
        clearInterval(sessionStorage.getItem('timeId'))
        sessionStorage.removeItem('timeId')
      }
    }, { immediate: true })

    // 监听状态 修改样式
    watch(() => state.work.signState, () => {
      // console.log(state.work.signState)

      if (state.work.signState === 1) state.signTextColor = '#86C600'
      if (state.work.signState === 2 || state.work.signState === 3) state.signTextColor = '#95C531'
      if (state.work.signState === 4 || state.work.signState === 5 || state.work.signState === 6 || state.work.signState === 7) state.signTextColor = '#FFA800'
    }, { immediate: true })

    watch(() => state.autoSignOut, () => {
      if (state.autoSignOut === 1) {
        state.dialogVisible = true
      }
    }, { immediate: true })

    // 调用
    const showAccordingSign = () => {
      //  点击导航位置打卡按钮
      setBuriedPoint({ event_id: 10000, uc: 1 }).then(res => {
        // console.log(res)
      })
      if (state.work.clockState.sign_type === 1) { // 上班打卡
        state.dialogVisible = true
      } else { // 下班打卡
        // 判断今天是否已写日志
        getJournalLogStatu().then(res => {
          if (res.code === 0) {
            if (res.data.today_write_log_status === 1) { // 已写日志
              state.dialogVisible = true
            } else {
              state.writeJournalVisible = true
            }
          }
        })
      }
    }

    // 时间转纯数字
    const timeToNumber = (time) => { return Number(time.replaceAll(' : ', '')) }

    // 打卡成功显示文案随机
    const successSlide = () => {
      const signState = state.work.signState
      const matchBysignState = specialTextList.filter(item => item.signState === signState)

      if (signState === -1) {
        state.successText = ''
      } else if (matchBysignState.length && ((timeToNumber(state.time) >= timeToNumber(matchBysignState[0].min)) || (timeToNumber(state.time) < timeToNumber(matchBysignState[0].max)))) {
        // 根据signState，超过某个时间，特殊文案提示
        state.successText = specialTextList[0].text
      } else {
        state.successText = textObj[state.work.signState][Math.floor(Math.random() * textObj[state.work.signState].length)]
      }
      state.innerVisible = true
    }

    const beforeInnerClose = () => {
      state.innerVisible = false
      outerClose()
      // 打卡 弹出公告
      // 打卡成功需要阅读公告
      if (state.work.signState === 2 || state.work.signState === 4 || state.work.signState === 6) {
        store.commit('work/setNoticeFlag', true)
      }
      store.commit('work/setSignState', 1)
    }

    const outerClose = () => {
      state.dialogVisible = false
      // location.hostname !== 'sns.imyfone.cn' && store.commit('work/setNoticeFlag', true) // 非正式环境无法打卡成功，增加调试
      if (state.autoSignOut === 1) {
        store.commit('work/setAutoSignOut', 0)
        store.commit('work/setSubmitJournal', 0)
      }
    }

    // 考勤异常弹窗
    const showClockAbnorma = () => {
      state.dialogAbnormaVisible = true
    }
    const toAttendanceStatic = () => {
      state.dialogAbnormaVisible = false
      router.push({ name: 'work' })
    }

    eventBus.$on('closeClockDialog', () => {
      state.dialogVisible = false
    })

    eventBus.$on('openClockDialog', () => {
      state.dialogVisible = true
      successSlide()
    })

    eventBus.$on('openCheckClock', () => {
      state.dialogVisible = true
    })

    /**
     * @异常考勤对应文案
     */
    const getClockAbnorma = (val) => {
      const myOnduty = val.on_dutytime
      const myOffduty = val.off_dutytime
      const resObj = {
        onduty: '',
        offduty: ''
      }
      const clockResult = val.time_result.split('|')
      if ((val.time_result === 'NotSigned|NotSigned' || val.time_result === 'Absenteeism|Absenteeism') && myOnduty === '0' && myOffduty === '0') {
        resObj.onduty = '旷工'
        return resObj
      }
      // 上班
      clockResult[0] === 'Late' && (resObj.onduty = '上班迟到')
      if (clockResult[0] === 'Absenteeism' && myOnduty === '0') resObj.onduty = '上班旷工'
      if (clockResult[0] === 'Absenteeism' && myOnduty !== '0') resObj.onduty = '旷工打卡'
      if (clockResult[0] === 'OndutyNotsigned' || clockResult[0] === 'onduty_notsigned' || clockResult[0] === 'NotSigned') resObj.onduty = '上班缺卡'
      // 下班
      if (clockResult[1] === 'Early') resObj.offduty = '下班早退'
      if (clockResult[1] === 'Absenteeism' && myOffduty === '0') resObj.offduty = '下班旷工'
      if (clockResult[1] === 'Absenteeism' && myOffduty !== '0') resObj.offduty = '旷工打卡'
      if (clockResult[1] === 'OffdutyNotsigned' || clockResult[1] === 'offduty_notsigned' || clockResult[1] === 'NotSigned') resObj.offduty = '下班缺卡'
      return resObj
    }

    return {
      handleCommand,
      router,
      store,
      msgCount,
      showAccordingSign,
      ...toRefs(state),
      textObj,
      successSlide,
      beforeInnerClose,
      showClockAbnorma,
      toAttendanceStatic,
      isVirtualUser,
      outerClose,
      getClockAbnorma
    }
  }
}
</script>

<style lang="less">
.header-right {
  display: flex;
  position: relative;
  align-items: center;
  left: 0;
  .el-dropdown,
  .el-dropdown-link {
    height: 55px !important;
    line-height: 55px !important;
  }
  .el-dropdown{
    margin-left: 20px;
  }
  span {
    display: inline-block;
  }
  .message-icon {
    position: relative;
    cursor: pointer;
    margin-top: 4.5px;
    .msg-count {
      position: absolute;
      min-width: 24px;
      width: auto;
      height: 18px;
      background: red;
      border-radius: 9px;
      left: 7px;
      top: -8px;
      color: #fff;
      font-size: 14px;
      transform: scale(0.7);
      text-align: center;
      font-weight: bold;
      padding: 0 2px;
    }
    svg:hover {
      circle {
        stroke: @active-text-color;
      }
      path {
        fill: #fff;
        stroke: @active-text-color;
      }
    }
  }
  .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      width: auto;
    }
    .user-avatar {
        margin-right: 0;
    }
  }
  .prize-icon{
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
}
.state-sign-wrapper {
  display: inline-block;
  margin-right: 20px;
  border-right: 1px solid #e0e0e0;
  padding: 0 20px 0 8px;
  height: 26px;
}
.state-sign {
  display: inline-block;
  padding: 0 12px 0 14px;
  height: 28px;
  line-height: 28px;
  min-width: max-content;
  font-size: 14px;
  border-radius: 14px;
  border: 1px solid #7972F0;
  color: #7972F0;
  font-weight: bold;
  transform: translateY(-1px);
  background: #f2f1fe;
  svg {
    vertical-align: text-top;
    margin-right: 7px;
  }
  &:hover {
    cursor: pointer;
  }
}
.signState-clock {
  font-size: 18px;
  text-align: center;
  margin-bottom: 8px;
  font-weight: bold;
}
  // 打卡弹窗
.my-attendance-dialog{
  background: #302e52;
  border-radius: 6px;
  overflow: hidden;
  width: 350px !important;
  height: 312px;
  margin-top: calc((100vh - 312px) / 2) !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    position: relative;
    padding: 0;
    img {
      object-fit: contain;
      width: 350px;
    }
    .dialog-content {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .dialog-content .slider-check-box .slider-check {
      width: 260px;
    }
    .now-date {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin: 12px 0;
    }
    .dialog-sign-text {
      font-weight: bold;
      text-align: center;
      margin-bottom: 12px;
      color: #86C600;
      font-size: 16px;
    }
    .dialog-sign-text-inner {
      font-size: 20px;
    }
    .dialog-successText {
      font-size: 16px;
      color: #999;
      text-align: center;
      margin-bottom: 26px;
      padding: 0 12px;
    }
    .attendance-date {
      text-align: center;
      font-size: 14px;
      color: @second-text-color;
      padding-bottom: 9px;
    }
  }
}
.abnorma-icon:hover {
  cursor: pointer;
}
.abnorma-dialog {
  width: 596px !important;
  height: auto;
  border-radius: 6px;
  padding: 24px 44px;
  .remind-title{
    margin-left: 6px;
  }
  .remind-tail{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    color: @second-text-color;
  }
  .el-dialog__header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: @default-text-color;
    padding: 0;
    margin-bottom: 15px;
  }
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    height: auto;
  }
  p {
    color: @default-text-color;
    margin-bottom: 16px;
  }
  table {
    border-collapse: collapse;
    background: #f6f9fa;
    margin-bottom: 8px;
  }
  table tr td {
    width: 256px;
    height: 48px;
    text-align: center;
    border: 1px solid #F1F1F1;
    &:first-child {
      color: @default-text-color;
    }
    &:last-child {
      color: #FFA800;
    }
  }
  div {
    height: 25px;
    line-height: 25px;
    margin-bottom: 16px;
  }
  .el-button {
    width: 306px;
    height: 44px;
    min-height: 44px;
    padding: 0;
    border-radius: 22px;
    background: #7972f0;
    color: #fff;
    font-size: 14px;
    border: none;
  }
}
.create-okr {
  width: 350px !important;
  height: 312px;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  overflow: hidden;
  margin-top: calc((100vh - 312px) / 2) !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    width: auto;
  }
}
</style>
