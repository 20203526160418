/*
 * @Description:热门相关接口
 * @Author: heqi
 * @Date: 2021-12-17 11:24:54
 * @LastEditTime: 2022-01-06 14:31:44
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\apis\hotTopic.js
 */
import { get } from '@/apis/method'
/**
 * @description: 获取热门话题和帖子
 */
const feedsHotlist = (data = { token: '' }) => get('/api/feeds/hot_all', data)

/**
 * @description: 话题相关
 */
const feedsTopicFeed = (data) => get('/api/feeds/topic_feed', data)

export { feedsHotlist, feedsTopicFeed }
