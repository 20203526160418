<!--
 * @Description: copyright
 * @Author: heqi
 * @Date: 2022-03-22 09:26:53
 * @LastEditTime: 2024-09-13 16:00:02
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \mfzj\src\components\Copyright.vue
-->
<template>
  <div :class="copyrightClass">
    <a target="blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010168">粤公网安备 44030502010168号</a>
    <a target="blank" href="http://beian.miit.gov.cn/publish/query/indexFirst.action">粤ICP备18096032号</a>
    <p>Copyright © {{+new Date().getFullYear()}} iMyFone.</p>
    <p>深圳麦风科技有限公司 版权所有</p>
  </div>
</template>

<script>
import { ref, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
export default {
  name: 'Copyright',
  setup () {
    const route = useRoute()
    const copyrightClass = ref('')
    watch(() => route.name, (newRouteName) => {
      newRouteName === 'accountLogin' ? (copyrightClass.value = 'copyright-login') : (copyrightClass.value = 'copyright-home')
    }, { immediate: true })
    return { copyrightClass }
  }
}
</script>

<style lang="less" scoped>
.copyright-login,
.copyright-home {
  font-size: 12px;
  line-height: 22px;
  padding-left: 8px;
}
.copyright-login {
  position: absolute;
  bottom: 20px;
  text-align: center;
  color: #ccc;
  opacity: .6;
  a {
    display: block;
    color: #ccc;
  }
}
.copyright-home {
  color: @second-text-color;
  margin-top: 16px;
  a {
    display: block;
    color: @second-text-color;
  }
}
</style>
