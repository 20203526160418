<!--
 * @Descripttion: 视频信息
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-24 15:31:58
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-12 16:26:36
-->

<template>
  <template v-if="videoUrl || percentage > 0" >
    <div :class="['video-wrapper', percentage === -1 ? 'video-wrapper-mask': '']">
      <div class="video-progress">
        <el-progress :percentage="percentage" :status="percentage === 100 ? 'success' : ''" />
      </div>
      <div class="mask-wrapper" @click="uploadVideoAuto">
        <template v-if="percentage === -1">
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity=".3" width="44" height="44" rx="22" fill="#000"/><path d="M22 33c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11" stroke="#fff" stroke-width="3" stroke-linecap="round"/><path d="M12.324 25.801a1 1 0 0 1-1.648 0l-3.599-5.234A1 1 0 0 1 7.901 19h7.198a1 1 0 0 1 .824 1.567L12.324 25.8z" fill="#fff"/></svg>
          <p>上传失败，请重试</p>
        </template>
      </div>
      <template v-if="videoUrl">
        <video :src="videoUrl" class="avatar" controls="controls">
          您的浏览器不支持视频播放
        </video>
        <svg @click="deleteVideo" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 7h12v12a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7z" stroke="#999" stroke-width="2"/><rect x="3.5" y="6.5" width="17" height="1" rx=".5" stroke="#999"/><rect x="14.5" y="10.5" width="6" height="1" rx=".5" transform="rotate(90 14.5 10.5)" stroke="#999"/><rect x="10.5" y="10.5" width="6" height="1" rx=".5" transform="rotate(90 10.5 10.5)" stroke="#999"/><rect x="9.5" y="3.5" width="5" height="1" rx=".5" stroke="#999"/></svg>
      </template>
      <template v-else-if="percentage > 0">
        <div class="loading-box">
          <el-image :src="require('@/assets/img/common/uploading.gif')"></el-image>
        </div>
      </template>
    </div>
  </template>
</template>
<script setup>
import { reactive, toRefs, watch, defineExpose, onBeforeUnmount } from 'vue'
import { uploadVideoFile } from '@/apis/userCenter.js'
import { DeletePending } from '@/utils/cancelRequest'
import eventBus from '@/utils/eventBus.js'

const state = reactive({
  percentage: 0, // 上传百分比
  videoUrl: ''
})

const { percentage, videoUrl } = toRefs(state)

eventBus.$on('uploadVideo', (url) => {
  state.videoUrl = url
})

eventBus.$on('uploadPercentage', (val) => {
  state.percentage = val
})

// 删除视频
const deleteVideo = () => {
  // 文件上传过程中，取消上传
  if (state.percentage > 0 && state.percentage < 100) {
    DeletePending()
  }
  state.videoUrl = ''
  state.percentage = 0
}

// 重新上传
const uploadVideoAuto = () => {
  uploadVideoFile(state.failedFile, (progress) => {
    state.percentage = Math.floor(progress * 100)
  }, (url) => {
    state.videoUrl = url
  }, () => {
    state.percentage = -1
  })
}

watch(() => state.videoUrl, (value) => {
  state.percentage = value ? 100 : 0
})

onBeforeUnmount(() => {
  eventBus.$off('uploadVideo')
  eventBus.$off('uploadPercentage')
})

defineExpose({
  videoUrl,
  percentage
})
</script>

<style lang="less" scoped>

.video-wrapper{
  display: flex;
  align-items: center;
  position: relative;
  .mask-wrapper{
    display: none;
    position: absolute;
    width: 297px;
    height: 167px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
    background: #1A1A1A;
    position: absolute;
    z-index: 1;
    p{
      color: #fff;
    }
    svg {
      cursor: pointer;
      &:hover{
        rect{
          fill: @active-text-color;
        }
      }
    }
    image{
      width: 100%;
    }
  }
  .video-progress {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 300px;
    text-align: center;
    padding-left: 20px;
  }
  &.video-wrapper-mask .mask-wrapper{
    display: flex;
  }
  video,.loading-box{
    width: 297px;
    height: 167px;
    margin-top: 16px;
    border-radius: 6px;
    margin-bottom: 6px;
    text-align: center;
    background: #f6f9fa;
    .el-image{
      margin-top: 40px;
    }
  }
  .loading-box .el-image{
    transform: scale(0.5);
  }
  >svg{
    cursor: pointer;
    margin-left: 10px;
    &:hover{
      background: #d9d9e580;
      border-radius: 50%;
      rect, path{
        stroke: @active-text-color;
      }
    }
  }
}
</style>
