/*
 * @Descripttion: 公告列表数据接口
 * @Date: 2022-01-19 13:34:03
 * @Author: lingjunbin@imyfone.cn
 * @LastEditTime: 2022-03-03 10:50:36
 * @LastEditors: heqi
 */
import { get } from '@/apis/method'

const getCateList = (data) => get('/api/announcement/cate_list') // 获取公告分类列表
const getAnnouncementList = (data) => get('/api/announcement/list', data) // 获取公告列表
const getAnnouncementInfo = (data) => get('/api/announcement/info', data) // 获取公告详情
const getIsCompany = (data) => get('/api/announcement/check_network') // 获取是否在公司内网 访问公告
const getEmojiList = () => get('/api/emoji/list') // 获取表情
export {
  getCateList,
  getAnnouncementList,
  getAnnouncementInfo,
  getIsCompany,
  getEmojiList
}
