import { searchData } from '@/apis/search.js'
const state = {
  searchResList: [], // 搜索数据存储
  page: 1,
  userList: [],
  blogsCounts: [0, 0, 0],
  forumCounts: [],
  userCounts: 0,
  refreshValue: true, // loading
  noticeCounts: 0,
  searchAtMe: {}
}
const mutations = {
  // 加载
  setSearchResList (state, payload) {
    payload.length === 0
      ? (state.searchResList = payload) : (state.searchResList.push(...payload))
  },

  // 设置页码
  setPage (state, payload) {
    state.page = payload
  },
  // 设置userList
  setUserList (state, payload) {
    state.userList = []
    state.userList = payload
  },
  // 设置count
  setBlogCounts (state, payload) {
    state.blogsCounts = []
    state.blogsCounts = payload
  },
  setForumCounts (state, payload) {
    state.forumCounts = []
    state.forumCounts = payload
  },
  setUserCounts (state, payload) {
    state.userCounts = payload
  },
  setRefreshValue (state, payload) {
    state.refreshValue = payload
  },
  setNoticeCounts (state, payload) {
    state.noticeCounts = []
    state.noticeCounts = payload
  },
  setSearchAtMe (state, payload) {
    state.searchAtMe = payload
  }
}

const actions = {
  getSearchRes (state, { type, page, keyword, blogType = 0, cateId = 0, limit }) {
    if (page === 1) {
      state.commit('setRefreshValue', true)
      state.commit('setSearchResList', [])
    }
    searchData({
      type,
      limit: limit || 10,
      page: page || 1,
      keyword,
      blog_type: blogType,
      cate_id: cateId
    })
      .then((res) => {
        state.commit('setRefreshValue', false)
        res.data.data.forEach((item) => {
          if (!item.user) {
            item.user = {
              name: '',
              avater: ''
            }
          }
        })
        state.commit('setLoadMore', false, { root: true })
        // 保存分区总数
        if (type === 1) { // 微博
          const conutObj = [res.data.total, res.data.feed, res.data.new]
          state.commit('setBlogCounts', conutObj)
        } else if (type === 2) { // 论坛
          res.data.cate.unshift({
            id: 0,
            name: '所有分区',
            count: res.data.total
          })
          state.commit('setForumCounts', res.data.cate)
        } else if (type === 5) { // 公告
          state.commit('setNoticeCounts', res.data.total)
        }
        if (res.data.data.length !== 0) {
          state.commit('setSearchResList', res.data.data)
        }

        if (res.data.data.length < 10 && !(page === 1 && res.data.data.length === 0)) {
          res.data.data.forEach((item) => {
            !item.user && (item.user = {})
          })
          state.commit('setNoMore', true, { root: true })
        }
      })
      .catch((err) => {
        console.log(err)
        state.commit('setLoadMore', false, { root: true })
        state.commit('setRefreshValue', false)
      })
  },
  getUserList (state, { page, keyword }) {
    if (page === 1) {
      state.commit('setRefreshValue', true)
      state.commit('setUserList', [])
    }
    searchData({
      type: 6,
      limit: 28,
      page: page,
      keyword
    })
      .then((res) => {
        if (res.code === 0) {
          state.commit('setUserCounts', res.data.count)
          state.commit('setUserList', res.data.data)
          state.commit('setRefreshValue', false)
        }
      })
      .catch((err) => {
        console.log(err)
        state.commit('setRefreshValue', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
