/*
 * @Description: 登录相关接口
 * @Author: heqi
 * @Date: 2021-12-17 11:24:54
 * @LastEditTime: 2022-02-22 16:06:57
 * @LastEditors: lingjunbin@imyfone.cn
 * @FilePath: \mfzj\src\apis\login.js
 */
import { get, post } from '@/apis/method'

/**
 * @description: 登录
 */
const loginUser = (data) => get('/api/auth/ding_login', data)

/**
 * @description: 其他账号登录
 */
const loginOther = (data) => post('/api/auth/other_login', data)

/**
 * @description: 获取用户信息
 */
const getUserInfo = (data) => get('/api/get_user_info', data)

/**
 * @description: 退出登录
 */
const logout = (data) => get('/api/auth/logout', data)

/**
 * @description: 获取白名单
 */
const getWhiteList = (data) => get('/api/attendance/get_white_list', data)

/**
 * 获取dingID
 * @param {*} data
 * @returns
 */
const getDingID = (data) => get('/api/auth/app_key', data)

export { loginUser, loginOther, getUserInfo, logout, getWhiteList, getDingID }
