/*
 * @Descripttion: 用户信息
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-07-31 11:20:24
 * @LastEditors: tangjz
 * @LastEditTime: 2023-12-28 09:42:25
 */

import { removeCookies, getCookies } from '@/utils/auth'
import { ACCOUNT_TYPE } from '@/utils/const.js'

const state = {
  userInfos: '',
  userFeedCount: 0, // 个人中心面板展示的动态数量
  isWhiteList: 1, // 是否白名单，默认为1
  userMedal: [],
  redirect: '', // 回跳地址
  isExpire: false // token是否过期
}
const mutations = {
  setUserInfo (state, value) {
    state.userInfos = value
  },
  setUserFeedCount (state, value) {
    state.userFeedCount = value
  },
  deleteFeed (state) {
    state.userFeedCount -= 1
  },
  setIsWhiteList (state, value) {
    state.isWhiteList = value
  },
  setUserMedal (state, value) {
    state.userMedal = value
  },
  setRedirect (state, payload) {
    state.redirect = payload
  },
  setIsExpire (state, payload) {
    state.isExpire = payload
  }
}
const getters = {
  isVirtualUser (state) {
    const receptionList = [ACCOUNT_TYPE.RECEPTION, ACCOUNT_TYPE.UNIVERSAL]
    return receptionList.includes(state.userInfos.user_type)
  }
}

const actions = {
  clearLoginInfo ({ commit }) {
    clearInterval(sessionStorage.getItem('timeId'))
    removeCookies('token')
    if (getCookies('token')) {
      // 删除token失败即删除含有.domain
      removeCookies('token', `${process.env.VUE_APP_LOGIN_URL.split('/')[2].split('.').slice(-2).join('.')}`) // 删掉顶级域名
      removeCookies('token', `.${process.env.VUE_APP_LOGIN_URL.split('/')[2]}`) // 删掉.sns.imyfone.cn
    }
    commit('setUserInfo', '')
    commit('saveFeedContentDraft', {}, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
