<template>
  <div class="arena-container">
    <p
      ref="inputPRef"
      class="arena"
      id="arena"
      :data-id="id"
      contentEditable="true"
      :placeholder="placeholder"
      @click.self="getVal($event, id)"
      @keyup="getVal($event, id)"
      @keydown="getVal($event, id)"
      @compositionstart="isEnd = false"
      @compositionend="isEnd = true"
      v-html="arenaValue"
      :style="{
        'min-height': isBlog ? '99px' : '',
        'max-height': isBlog ? '186px' : ''
      }"
    ></p>
  </div>
</template>

<script setup>
import Tribute from 'tributejs'
import { toRefs, onMounted, computed, reactive, onBeforeMount, defineProps, defineEmits, defineExpose, nextTick } from 'vue'
import { useStore } from 'vuex'
import { getTopicList } from '@/apis/blogs.js'

const props = defineProps({
  placeholder: { // 输入框默认提示文案
    type: String,
    default: ''
  },
  inputContent: { // 父子组件通用变量
    type: Object,
    default: () => ({
      html: '',
      text: ''
    })
  },
  initInputContent: { // 输入框填充内容
    type: String,
    default: ''
  },

  isBlog: { // 是否发微博
    type: Boolean,
    default: false
  },
  id: { // 组件id
    type: Number,
    default: NaN
  }
})

const emit = defineEmits(['update:inputContent', 'focus'])

const store = useStore()

const state = reactive({
  inputPRef: null,
  isEnd: true,
  allUserList: computed(() => store.state.userList),
  isTopic: true,
  arenaValue: computed({
    set: (value) => {
      return value
    },
    get: () => {
      return props.initInputContent
    }
  }),
  id: computed(() => props.id)
})
const { inputPRef, isEnd, arenaValue, id } = toRefs(state)

// 监听鼠标点击事件
document.addEventListener('click', (e) => {
  if (state.inputPRef && !state.inputPRef.contains(e.target)) {
    state.inputPRef.dispatchEvent(new Event('click'))
    emit('focus', false)
  }
})

// 清空内容
const clearContent = () => {
  state.inputPRef.innerHTML = ''
  state.inputPRef.innerText = ''
  emit('update:inputContent', { text: '', html: '' })
}

if (store.getters['userInfo/isVirtualUser'] && state.allUserList[0].id !== -1) {
  const allUser = [{ key: '所有人', value: '所有人', id: -1 }, ...state.allUserList]
  store.commit('setUserList', allUser)
}

// 初始化实例
onMounted(() => {
  // 输入@触发下拉所有人
  const setParams = {
    menuContainer: document.querySelector('.arena-wrapper'),
    noMatchTemplate: function () {
      return '<span style:"visibility: hidden;"></span>'
    },
    requireLeadingSpace: false,
    autocompleteMode: false,
    collection: [
      {
        selectClass: 'highlight',
        // The function that gets call on select that retuns the content to insert
        selectTemplate: function (item) {
          if (this.range.isContentEditable(this.current.element)) {
            return (
                  `<a
                    contenteditable="false"
                    href="/personalCenter?id=${item.original.id}"
                    title="${item.original.value}"
                    data-isAtCompanyUserid="${item.original.id}"
                  >
                    @${item.original.value}
                  </a>`
            )
          }
        },
        // the array of objects
        values: state.allUserList
      }
    ]
  }
  // 输入#触发话题弹窗
  if (props.isBlog) {
    setParams.collection.push({
      trigger: '#',
      itemClass: 'topics-content',
      selectTemplate: function (item) {
        if (this.range.isContentEditable(this.current.element)) {
          return `#${item.original.name}#`
        }
      },
      menuItemTemplate: function (item) {
        return (`
                  <span class="topics-desc">#${item.original.name}#</span>
                  <span class="feeds-count">${item.original.feeds_count} 动态</span>
              `)
      },
      values: (query, cb) => {
        if (state.isTopic) {
          return searchTopics(query, cb)
        }
      },
      lookup: 'name',
      fillAttr: 'name'
    })
  }
  var tribute = new Tribute(setParams)
  nextTick(() => {
    tribute.attach(state.inputPRef)
    // 增加粘贴的监听
    addPasteLister()
    // 增加鼠标左键点击事件
    addMouseClick()
    // 增加drag拖拽事件
    addDragListener()
  })
})

function addDragListener () {
  state.inputPRef.addEventListener('dragstart', (e) => {
    e.preventDefault()
    return false
  })
}

function addPasteLister () {
  state.inputPRef.addEventListener('paste', (event) => {
    event.preventDefault()
    var text = ''
    var clp = (event.originalEvent || event).clipboardData
    if (clp === undefined || clp === null) {
      text = window.clipboardData.getData('text') || ''
      // text = text.replace(/<[^<>]*>/g, '') // 过滤所有标签
      if (text !== '') {
        if (window.getSelection) {
          var newNode = document.createElement('span')
          newNode.innerHTML = text
          window.getSelection().getRangeAt(0).insertNode(newNode)
        } else {
          document.selection.createRange().pasteHTML(text)
        }
      }
    } else {
      text = clp.getData('text/plain') || ''
      // text = text.replace(/<[^<>]*>/g, '') // 过滤所有标签
      if (text !== '') {
        document.execCommand('insertText', false, text)
      }
    }

    // 鼠标右键复制额外触发兼容
    if (event.target.id === 'arena') {
      setInputContent(event.target)
    }
  })
}

function addMouseClick () {
  const list = [...document.querySelectorAll('.arena')]
  list.forEach((item) => {
    item.addEventListener('tribute-active-false', (event) => {
      state.inputPRef && state.inputPRef.dispatchEvent(new Event('click'))
    })
  })
}

const searchTopics = async (query, cb) => {
  const { code, data } = await getTopicList({ keyword: query })
  code === 0 && cb(data.list)
}

function getVal (e, id) {
  const filterKeys = [17, 18, 20]
  if (~filterKeys.indexOf(e?.keyCode)) {
    // 解决按alt ctrl shift 存在调用话题接口
    state.isTopic = false
  } else {
    state.isTopic = true
  }
  const inputList = document.querySelectorAll('.arena')
  inputList.forEach(i => {
    if (i.getAttribute('data-id') === String(id)) {
      setInputContent(i)
    }
  })
}

// 获取内容
function setInputContent (dom) {
  const text = dom.innerText
  // text = text.trim().substr(0, limit.value)
  // 去掉首尾空行
  const html = dom.innerHTML
    .replace(/(^(<div><br><\/div>)+)/g, '')
    .replace(/((<div><br><\/div>)+$)/g, '')
  emit('focus', true)
  emit('update:inputContent', { text, html })
}
// 输入框focus
const focus = () => {
  state.inputPRef.focus()
}

// 是否选中元素及其子元素
function isSelectionWithinElement () {
  const element = state.inputPRef
  const selection = window.getSelection()

  if (selection.rangeCount > 0) {
    let selectedNode = selection.anchorNode

    // Traverse up the DOM tree
    while (selectedNode) {
      if (selectedNode === element) {
        return true
      }
      selectedNode = selectedNode.parentNode
    }
  }

  return false
}

// 输入框focus
const focusInputEnd = () => {
  const flag = isSelectionWithinElement()
  if (!flag) {
    const element = state.inputPRef
    // 获取选区对象
    const range = document.createRange()
    const selection = window.getSelection()

    // 设置选区的起始点和终止点为元素的最后一个子节点
    range.selectNodeContents(element)
    range.collapse(false) // collapse to end

    // 清除所有选区，并设置新选区
    selection.removeAllRanges()
    selection.addRange(range)

    // 让元素获得焦点
    element.focus()
  }
}

onBeforeMount(() => {
  document.removeEventListener('click', (e) => {
    console.log(e)
  })
})

defineExpose({
  getVal,
  focus,
  focusInputEnd,
  clearContent,
  id
})

</script>

<style lang="less">
@import "../../assets/less/common.less";
.arena-container {
  position: relative;
}
.arena {
  overflow: auto;
  padding: 8px 10px;
  background: #f6f9fa;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 14px;
  &:empty::before {
    content: attr(placeholder);
    color: #a9a9a9;
    font-size: 14px;
  }
  &:focus {
    outline: none;
  }
  a {
    color: @active-text-color;
    display: inline-block;
  }
}

.tribute-container {
  z-index: 9999999;
  max-height: 300px;
  overflow: auto;
  background: #feffff;
  box-shadow: 4px 4px 8px #cccccc;
  border-radius: 4px;
  ul {
    li {
      padding: 0 24px 0 24px;
      margin: 0 8px;
      text-align: center;
      height: 26px;
      line-height: 26px;
      border-radius: 13px;
      color: @default-text-color;
      font-size: 12px;
      display: block;
      min-width: 100px;
      &.topics-content{
        min-width: 200px;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0 15px;
        .topics-desc{
          max-width: 500px;
          margin-right: 40px;
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow:ellipsis;
        }
        .feeds-count{
          color: @second-text-color;
        }
        &.highlight{
          background: none;
        }
      }
      &:first-child {
        margin-top: 12px;
      }
      &:last-child {
        margin-bottom: 12px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .highlight {
    color: #7972f0;
    background: rgba(121, 114, 240, 0.2);
  }
  .scroll-bar()
}
</style>
