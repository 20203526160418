/*
 * @Descripttion: 工作台相关
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-24 19:20:12
 * @LastEditors: tangjz
 * @LastEditTime: 2023-10-09 17:22:09
 */
import { getAttendanceClockResult, exceptionList, getServerTime } from '@/apis/attendance'
import { reBackTime } from '@/utils/tools'

const state = {
  templateList: {
    use_template: [],
    no_use_template: []
  },
  journalId: '',
  journalList: [],
  clockState: null, // 个人打卡相关状态
  signState: null, // 打卡类型 迟到 早退等
  signTime: '', // 打卡时间
  currentTime: '', // 显示时间
  currentDay: '', // 显示日期
  clockAbnorma: [], // 打卡异常状态
  noticeFlag: false, // 是否强制阅读公告
  receiveNewJournal: 0, // 收到新日志数量
  timeCount: 0,
  autoSignOut: 0, // 是否需要自动签退
  hasSubmitJournal: 0, // 是否已提交日志
  random: 0,
  random2: 0
}
const mutations = {
  setTemplateList (state, value) {
    state.templateList = value
    localStorage.setItem('日志模板', JSON.stringify(value))
  },
  setJournalId (state, value) {
    state.journalId = value
  },
  setJournalList (state, value) {
    // state.journalList = []
    const index = state.journalList.findIndex(item => item.template_id === value.template_id)
    if (index > -1) {
      state.journalList[index] = value
    } else {
      state.journalList.push(value)
    }
  },
  changeReciverList (state, payload) {
    const index = state.journalList.findIndex(item => item.template_id === payload.template_id)
    if (index > -1) {
      state.journalList[index].receiveList = payload.list
    }
  },
  removeJournalList (state, value) {
    const index = state.journalList.findIndex(item => item.template_id === value.template_id)
    index > -1 && state.journalList.splice(index, 1)
  },
  setClockState (state, payload) {
    state.clockState = payload
  },
  setSignState (state, payload) {
    state.signState = payload
  },
  setSignTime (state, payload) {
    state.signTime = payload
  },
  setTime (state, payload) {
    state.currentTime = ''
    const timeDate = new Date(payload)
    const str = `${timeDate.getHours() < 10 ? ('0' + timeDate.getHours()) : timeDate.getHours()} : ${timeDate.getMinutes() < 10 ? ('0' + timeDate.getMinutes()) : timeDate.getMinutes()} : ${timeDate.getSeconds() < 10 ? ('0' + timeDate.getSeconds()) : timeDate.getSeconds()}`
    state.currentTime = str
    const month = timeDate.getMonth() + 1 < 10 ? `0${timeDate.getMonth() + 1}` : `${timeDate.getMonth() + 1}`
    const day = timeDate.getDate() < 10 ? `0${timeDate.getDate()}` : `${timeDate.getDate()}`
    state.currentDay = `${timeDate.getFullYear()}-${month}-${day}`
    // 早上6点更新上班状态变为签到 签到之后状态变为1 打卡之后状态为2 或 3  如果是1将其变为签退 晚上18点签退 状态为1 变为2 如果为2 和 3则忽略
    // 打卡后状态checktype 变为onduty  下班打卡之后状态改变为2 或3
    // if (new Date().getHours() === 18 && state.clockState.sign_type === 1) {
    //   state.clockState.sign_type = 2
    //   state.signState = 2
    //   state.signTime = null
    //   state.clockState.check_type = 'OnDuty'
    // }
  },
  setClockAbnorma (state, payload) {
    state.clockAbnorma = payload
  },
  setNoticeFlag (state, payload) {
    state.noticeFlag = payload
  },
  setReceiveNewJournal (state, num) {
    if (num === 0) {
      state.receiveNewJournal = 0
    } else {
      state.receiveNewJournal += num
    }
  },
  setAllReceiveNewJournal (state, num) {
    state.receiveNewJournal = num
  },
  setAutoSignOut (state, val) {
    state.autoSignOut = val
  },
  setSubmitJournal (state, val) {
    state.hasSubmitJournal = val
  },
  setSlideSuccess (state, payload) {
    // 打卡成功 将状态改为下班打卡状态 并设置打卡时间 以及打卡结果
    state.clockState.sign_type = 2
    state.clockState.check_type = 'OnDuty'
    state.signTime = payload.sign_time
    state.clockState.time_result = payload.time_result
  },
  setRandom (state, payload) {
    payload.type === 'first' ? state.random = payload.random : state.random2 = payload.random
  }
}
const actions = {
  // 设置时间
  setActionCurrentTime (state, payload) {
    // eslint-disable-next-line no-unused-vars
    var timeId
    sessionStorage.getItem('timeId') && clearTimeout(sessionStorage.getItem('timeId'))
    timeId = setTimeout(() => {
      const random = state.state.random
      payload += 1000
      // const time = payload
      state.commit('setTime', payload)
      const { hours, minutes, seconds } = reBackTime(payload)

      if (hours === 6 && minutes === 0 && seconds < 10) { // 早上6点随机
        const random = Math.ceil(Math.random() * 59)
        seconds < 5 ? state.commit('setRandom', { type: 'first', random }) : state.commit('setRandom', { random })
      }
      if (random !== 0 && hours === 6 && minutes === random && seconds === state.state.random2) {
        clearTimeout(sessionStorage.getItem('timeId'))
        state.dispatch('getClockState')
        state.random = 0
      }

      state.dispatch('setActionCurrentTime', payload)
    }, 1000)
    sessionStorage.setItem('timeId', timeId)
  },

  // 获取打卡详情
  async getClockState (state, payload) {
    clearTimeout(sessionStorage.getItem('timeId'))
    sessionStorage.removeItem('timeId')

    const result = await getAttendanceClockResult()
    if (result.code === 0) {
      state.commit('setClockState', result.data)
      var times = +new Date(result.data.current_time)
      state.commit('setTime', times)

      // 处理时间
      state.dispatch('setActionCurrentTime', times)
      // state.commit('setTime', times)
      return new Promise((resolve, reject) => {
        resolve(result.data)
      })
    }
  },
  // 获取打卡异常
  async getClockAbnormal (state, payload) {
    const result = await exceptionList()
    if (result.code === 0) state.commit('setClockAbnorma', result.data)
  },
  async getServerClock (state) {
    const { data: { datetime } } = await getServerTime()
    const time = +new Date(datetime)
    state.commit('setTime', time)

    state.dispatch('setActionCurrentTime', time)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
