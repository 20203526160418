/*
 * @Descripttion: 公告相关
 * @Date: 2022-02-15 15:56:16
 * @Author: lingjunbin@imyfone.cn
 * @LastEditTime: 2023-03-05 19:02:02
 * @LastEditors: 何琦 heqi@imyfone.cn
 */
const state = {
  clickItem: 0, // 公告列表左边按下选项
  unReadAnt: 0, // 未读公告个数
  announcementData: { // 用于公告请求的数据
    limit: 10,
    page: 1,
    cate_id: 0, // 公告分类 全部+未读|0 通知|1 规章制度|2
    type: 1 // 类型 1-全部|2-未读|3-自定义
  },
  announceData: { // 存储当前公告列表 详情回跳页面更新内容 以及滚动距离
    list: '',
    top: 0,
    page: 1
  }
}
const mutations = {
  setClickItem (state, val) {
    state.clickItem = val
  },
  setunReadAnt (state, val) {
    state.unReadAnt = val
  },
  setAnnouncementData (state, val) {
    state.announcementData.limit = val.limit ? val.limit : state.announcementData.limit
    state.announcementData.page = val.page ? val.page : state.announcementData.page
    state.announcementData.cate_id = val.cate_id ? val.cate_id : state.announcementData.cate_id
    state.announcementData.type = val.type ? val.type : state.announcementData.type
  },
  setAnnounceData (state, payload = { list: '', top: 0 }) {
    const { list, top } = payload
    state.announceData.list = list
    state.announceData.top = top
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
