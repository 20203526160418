<template>
  <div class="nav-search">
    <div :class="['input-search', !seardKeyWords.trim() ? 'disabled' : '']">
      <input
        autocomplete="off"
        class="nav-input"
        type="text"
        placeholder="请输入关键字"
        id="head_search"
        v-model="seardKeyWords"
        @keyup.enter="toRes"
      />
      <div class="div-input">
        <div class="input" @click="openValue">
          <span class="click-Dom">{{ selectData.name }}</span>
          <img class="click-Dom" src="../assets/img/arrow-down.png"  />
        </div>
        <div class="list" v-show="show">
          <ul>
            <li
              @click="selectItem(item)"
              @mouseover="hoverItem(item)"
              :class="{'active': item.id === selectData.id}"
              v-for="(item) in selectOptions"
              :key="item.id"
            >
              {{ item.name }}
            </li>
          </ul>
          <el-scrollbar class="account-list" ref="accountListRef"
            v-show="accountListVisible"
            @scroll="scrollAccountList">

            <div :class="['account-item', id === selectedAccount.id ? 'active': '']"
              v-for="{id,name,disabled = false,visible = false} in accountList" :key="id"
              @click="selectAccount(id,name)"
              >
              <el-tooltip placement="right" effect="light"
                :disabled="disabled"
                :visible="visible"
                :show-after="300"
                :offset="30">
                <template #content> {{ name }}</template>
                <p @mouseenter="mouseEnter($event,id)">
                  <span class="account-name"> {{ name }}</span>
                </p>
              </el-tooltip>
            </div>
          </el-scrollbar>
        </div>

      </div>
      <svg
        height="18"
        fill="#333333"
        viewBox="0 0 18 18"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
        @click="toRes()"
      >
        <path
          d="m13.3240132 15.0076008c-1.3911673 1.0419188-3.1188524 1.6590659-4.99067987 1.6590659-4.60237291 0-8.33333333-3.7309605-8.33333333-8.33333337 0-4.60237291 3.73096042-8.33333333 8.33333333-8.33333333 4.60237287 0 8.33333337 3.73096042 8.33333337 8.33333333 0 1.87182747-.6171471 3.59951257-1.6590659 4.99067987l2.5893385 2.5893385c.4627701.4627702.4642768 1.2115621-.0038743 1.6797133-.4649099.4649099-1.2253693.4582183-1.6797133.0038743zm-4.99067987-.7218865c3.28740927 0 5.95238097-2.6649717 5.95238097-5.95238097 0-3.28740922-2.6649717-5.95238095-5.95238097-5.95238095-3.28740922 0-5.95238095 2.66497173-5.95238095 5.95238095 0 3.28740927 2.66497173 5.95238097 5.95238095 5.95238097z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { watch, reactive, toRefs, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getUserAll } from '@/apis/blogs.js'
export default {
  name: 'SearchInput',
  setup () {
    const state = reactive({
      show: false,
      selectOptions: [
        {
          id: 1,
          name: '微博'
        },
        {
          id: 2,
          name: '论坛'
        },
        {
          id: 3,
          name: '用户'
        },
        {
          id: 5,
          name: '公告'
        },
        {
          id: 0,
          name: '@我'
        }
      ],
      defaultSelect: {
        id: 1,
        name: '微博'
      },
      selectData: {},
      seardKeyWords: '',
      accountList: [],
      accountListVisible: false,
      selectedAccount: {
        id: '',
        name: ''
      }
    })
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    /**
     * type 1 微博 2 论坛 3 用户 5公告 0 @我
     * limit 一页的数量
     * page 页码
     * keyword 关键字
     * blog_type 0 全面 1 动态 2 咨询  博客必填  二级筛选
     * cate_id 分区论坛必填  二级筛选
     */
    function toRes () {
      store.commit('setLoadMore', false)
      store.commit('setNoMore', false)
      if (!state.seardKeyWords.trim()) {
        return
      }
      const id = state.selectData.id
      if (id === 1) {
        store.commit('editContentType', 1)
      } else if (id === 2) {
        store.commit('editContentType', 2)
      } else if (id === 3) {
        store.dispatch('searchRes/getUserList', {
          page: 1,
          keyword: state.seardKeyWords
        })
      } else if (id === 5) {
        // store.dispatch('searchRes/getSearchRes', {
        //   type: 5,
        //   page: 1,
        //   keyword: state.seardKeyWords
        // })
      } else if (id === 0) {
        // 缓存起来id和名称,关键字
        const obj = Object.assign({}, state.selectedAccount, { keyword: state.seardKeyWords.trim() })
        store.commit('searchRes/setSearchAtMe', obj)
        store.commit('searchRes/setRefreshValue', true)
      }
      router.push({
        name: 'searchBlog',
        query: { type: id, keyword: state.seardKeyWords.trim() }
      })
    }

    watch(
      () => route.fullPath,
      (n, o) => {
        const data = state.selectOptions.filter(item => item.id === Number(route.query.type))
        state.selectData = data.length > 0 ? data[0] : state.defaultSelect
        state.seardKeyWords = route.query.keyword || ''
      }, { immediate: true }
    )

    function openValue () {
      state.show = !state.show
    }
    function selectItem (item) {
      state.selectData = item
      state.show = false
      state.selectedAccount = { id: '', name: '', keyword: '' }
      store.commit('searchRes/setSearchAtMe', {})
    }

    function hoverItem (item) {
      state.accountListVisible = item.id === 0
    }

    function updateSelectOptions () {
      if (store.getters['userInfo/isVirtualUser'] || store.state.userInfo.isWhiteList === -1) {
        // 前台用户不展示公告
        const index = state.selectOptions.findIndex(v => v.id === 5)
        state.selectOptions.splice(index, 1)
      }
    }

    function initAccountList () {
      getUserAll({ type: 3 }).then(({ code, data }) => {
        if (code === 0) {
          state.accountList = data
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    function selectAccount (id, name) {
      state.selectData = { id: 0, name: name.substring(0, 2) }
      state.selectedAccount = { id, name }
    }

    function mouseEnter ($el, id) {
      const textContent = $el.target.lastChild
      const width = textContent.offsetWidth
      if (width < 71) {
        const item = state.accountList.filter(item => item.id === id)[0]
        item.disabled = true
        item.visible = true
      }
    }

    function scrollAccountList () {
      state.accountList.forEach(item => {
        item.visible = false
      })
    }

    onMounted(() => {
      updateSelectOptions()
      initAccountList()
      document.addEventListener('click', (e) => {
        const thisClassName = e.target.className
        if (
          thisClassName !== 'input' &&
          thisClassName !== 'list' &&
          thisClassName !== 'click-Dom'
        ) {
          state.show = false
        }
      })
    })
    return {
      openValue,
      toRes,
      selectItem,
      hoverItem,
      selectAccount,
      mouseEnter,
      scrollAccountList,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less">
.nav-search {
  position: relative;
  width: 225px;
  height: 36px;
  border-radius: 50px;
  float: right;
  margin-left: 22px;
  margin-right: 55px;
  .head_search {
    z-index: 9;
    position: absolute;
    background: #fff;
    width: 235px;
    font-size: 14px;
    box-shadow: 0 1px 22px #e2e0e0;
    margin-top: 5px;
    .history {
      p {
        font-size: 14px;
        color: #ccc;
        padding: 20px 15px;
        margin: 0;
      }
      ul {
        display: block;
        margin: 0;
        padding: 0px 15px;
        overflow: hidden;
      }
      .clear {
        font-size: 12px;
        line-height: 40px;
        display: block;
        height: 40px;
        cursor: pointer;
        text-align: center;
        color: #999;
        background: #f8fcff;
        a {
          font-size: 100%;
          vertical-align: baseline;
          background: transparent;
          text-decoration: none;
          color: inherit;
        }
      }
    }
    .search_types ul {
      display: block;
      margin: 0;
      padding: 20px 20px 0px 20px;
      overflow: hidden;
    }
    .search_types li {
      color: #666;
      width: 100%;
      cursor: pointer;
      float: left;
      margin-bottom: 20px;
      overflow: hidden;
      line-height: 18px;
    }
  }
  .input-search {
    position: relative;
  }
  .input-search > svg {
    position: absolute;
    top: 28px;
    right: 20px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .input-search.disabled > svg {
    cursor: not-allowed;
  }
  .nav-input {
    width: 100%;
    height: 36px;
    font-size: 14px;
    border: 1px solid #F1F1F1;
    color: @default-text-color;
    outline: none;
    background: #f6f9fa;
    padding-left: 70px;
    padding-right: 50px;
    vertical-align: middle;
    margin-top: 10px;
    border-radius: 18px;
  }
  input::input-placeholder {
    color: #797979;
  }
  input::-webkit-input-placeholder {
    //兼容WebKit browsers（Chrome的内核）
    color: #797979;
  }
  input::-moz-placeholder {
    //Mozilla Firefox 4 to 18
    color: #797979;
  }
  input::-moz-placeholder {
    //Mozilla Firefox 19+
    color: #797979;
  }
  input::-ms-input-placeholder {
    //Internet Explorer 10+
    color: #797979;
  }
}
.div-input {
  font-size: 14px;
  cursor: pointer;
  ul li {
    list-style: none;
  }
  .input {
    width: 42px;
    position: absolute;
    top: 19px;
    left: 16px;
  }
  .input img {
    position: absolute;
    right: 0;
    top: 7px;
  }
  .list {
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    margin-top: 2px;
    margin-left: -5px;
    position: absolute;

    ul {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      padding: 8px 17px 8px 8px;
        li {
          position: relative;
          width: 100%;
          cursor: pointer;
          padding: 0 14px 8px 14px;
          position: relative;
        &:last-child{
          padding-bottom: 0;
          background: url('../assets/img/common/arrow-right-icon.svg') no-repeat;
          background-position: right;
        }
        &:hover{
          color: @active-text-color;
        }
        &.active:before{
          content: '';
          position: absolute;
          left: 0;
          top: 7px;
          width: 10px;
          height: 8px;
          background: url('../assets/img/common/selected.svg') no-repeat;
        }
      }
    }

  }

  .account-list{
    position: absolute;
    top: 110px;
    left: 83px;
    // width: 120px;
    height: 140px;
    z-index: 1;
    background: #fff;
    padding: 8px 16px 8px 8px;
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    .account-item{
      color: #333;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
      cursor: pointer;
      position: relative;
      // padding-left: 16px;
      // width: 86px;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // word-break: break-all;
      // white-space: nowrap;
      &:last-child{
        margin-bottom: 0;
      }
      &:hover{
        color: @active-text-color;
      }
      &.active::before{
        content: '';
        position: absolute;
        width: 10px;
        height: 8px;
        left: 0px;
        top: 7px;
        background: url('../assets/img/common/selected.svg') no-repeat;
      }
      p{
        padding-left: 16px;
        width: 86px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
