const layout = () => import('@/views/layout')
const blogs = () => import('@/views/layout/blogs')
const allBlogs = () => import('@/views/layout/blogs/allBlogs/index.vue')
const forum = () => import('@/views/layout/forum')
const personalCenter = () => import('@/views/layout/personalCenter')
const edit = () => import('@/views/layout/personalCenter/edit/index.vue')
const employees = () => import('@/views/layout/personalCenter/employees/index.vue')
const notification = () => import('@/views/layout/notification/index.vue')
const PublishForum = () => import('@/components/PublishForum.vue')
const articalDetail = () => import('@/views/layout/forum/articalDetail/index.vue')
const searchBlog = () => import('@/views/layout/searchRes/searchBlog/index.vue')
const work = () => import('@/views/layout/work/index.vue')
const writeJournal = () => import('@/views/layout/work/writeJournal/index.vue')
const announcement = () => import('@/views/layout/announcement/index.vue')
const announcementDel = () => import('@/views/layout/announcement/announcementDel.vue')
const okr = () => import('@/views/layout/work/okr')
const myokr = () => import('@/views/layout/work/okr/myokr')
const evaluation = () => import('@/views/layout/work/okr/evaluation')
const allTarget = () => import('@/views/layout/work/okr/AllTarget')

const alignTheView = () => import('@/views/layout/work/okr/alignTheView/index.vue')
const targetAligned = () => import('@/views/layout/work/okr/alignTheView/TargetAligned.vue')
const targetNotAligned = () => import('@/views/layout/work/okr/alignTheView/TargetNotAligned.vue')

const OkrNav = () => import('@/views/layout/work/okr/component/nav/OkrNav')
const TeamOkrNav = () => import('@/views/layout/work/okr/component/nav/TeamOkrNav')

const okrAdd = () => import('@/views/layout/work/okr/myokr/component/OkrAdd.vue') // 新增okr
const okrHistory = () => import('@/views/layout/work/okr/myokr/OkrHistory.vue')
const okrHistoryNav = () => import('@/views/layout/work/okr/myokr/OkrHistoryNav.vue')
const evaluatePage = () => import('@/views/layout/work/okr/evaluation/components/EvaluatePage.vue')

export default [
  {
    path: '/',
    redirect: '/blogs/dynamics'
  },
  {
    path: '/layout',
    component: layout,
    name: 'layout',
    children: [
      {
        path: '/blogs',
        redirect: '/blogs/dynamics',
        component: blogs,
        name: 'blogs',
        meta: { activeRoute: 'blogs' },
        children: [
          {
            path: '/blogs/allBlogs',
            component: allBlogs,
            name: 'allBlogs',
            meta: { blogsActive: 'allBlogs' }
          },
          {
            path: '/blogs/dynamics',
            component: allBlogs,
            name: 'dynamics',
            meta: { blogsActive: 'dynamics' }
          },
          {
            path: '/blogs/news',
            component: allBlogs,
            name: 'news',
            meta: { blogsActive: 'news' }
          }
        ]
      },
      {
        path: '/forum',
        component: forum,
        name: 'forum',
        meta: { activeRoute: 'forum' }
      },
      {
        path: '/personalCenter',
        component: personalCenter,
        name: 'personalCenter',
        meta: { activeRoute: 'personalCenter' }
      },
      {
        name: 'editPersonal',
        path: 'edit',
        component: edit,
        meta: { activeRoute: 'personalCenter' }
      },
      {
        path: '/employees',
        component: employees,
        name: 'employees',
        meta: { activeRoute: 'personalCenter' }
      },
      {
        name: 'notification',
        path: 'notification',
        component: notification,
        meta: { requireLogin: true }
      },
      {
        name: 'PublishForum',
        path: 'PublishForum',
        component: PublishForum,
        meta: { activeRoute: 'forum' }
      },
      {
        name: 'articalDetail',
        path: 'articalDetail/:id',
        component: articalDetail,
        meta: { activeRoute: 'forum' }
      },
      {
        name: 'announcementDel',
        path: 'announcementDel/:id',
        component: announcementDel,
        meta: { activeRoute: 'announcement', isAuthority: true }
      },
      {
        name: 'searchBlog',
        path: 'searchBlog',
        component: searchBlog,
        meta: { activeRoute: 'blogs' }
      },
      {
        name: 'work',
        path: 'work',
        component: work,
        meta: { activeRoute: 'work', selected: 'work-log' }
      },
      {
        name: 'writeJournal',
        path: 'writeJournal',
        component: writeJournal,
        meta: { activeRoute: 'work' }
      },
      {
        name: 'updateJournal',
        path: 'updateJournal',
        component: writeJournal,
        meta: { activeRoute: 'work' }
      }, {
        name: 'okr',
        path: 'okr',
        component: okr,
        meta: { activeRoute: 'work', selected: 'work-okr' },
        children: [
          {
            name: 'myokr',
            path: 'myokr/:id/:depName',
            components: {
              okrNav: OkrNav,
              okrContent: myokr
            },
            meta: { activeRoute: 'work', activeMenu: 'myokr' },
            children: [
              {
                name: 'okr-add',
                path: 'okr-add',
                components: {
                  okrNav: OkrNav,
                  okrContent: okrAdd
                },
                meta: { activeMenu: 'myokr' }
              }
            ]
          },
          {
            name: 'okrHistory',
            path: 'okrHistory',
            components: {
              okrNav: okrHistoryNav,
              okrContent: okrHistory
            },
            meta: { activeRoute: 'work' }
          },
          {
            name: 'evaluation',
            path: 'evaluation',
            components: {
              okrNav: OkrNav,
              okrContent: evaluation
            },
            meta: { activeRoute: 'work', activeMenu: 'evaluation' }
          },
          {
            name: 'evaluatePage',
            path: 'evaluatePage',
            components: {
              okrNav: OkrNav,
              okrContent: evaluatePage
            },
            meta: { activeRoute: 'work', activeMenu: 'evaluation' }
          },
          {
            name: 'allTarget',
            path: 'allTarget/:allTargetID',
            components: {
              okrNav: TeamOkrNav,
              okrContent: allTarget
            },
            meta: { activeRoute: 'work' }
          },
          {
            name: 'alignTheView',
            path: 'alignTheView',
            components: {
              okrContent: alignTheView
            },
            children: [
              {
                name: 'targetAligned',
                path: 'targetAligned',
                component: targetAligned
              },
              {
                name: 'targetNotAligned',
                path: 'targetNotAligned',
                component: targetNotAligned
              }
            ],
            meta: { activeRoute: 'work' }
          }
        ]
      }, {
        name: 'all-target',
        path: 'all-target',
        component: allTarget,
        meta: { activeRoute: 'forum' }
      },
      {
        name: 'announcement',
        path: 'announcement',
        component: announcement,
        meta: { activeRoute: 'announcement', isAuthority: true }
      }
    ]
  }
]
