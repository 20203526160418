<!--
 * @Descripttion: 空数据/下拉laoding
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-15 09:56:33
 * @LastEditors: heqi
 * @LastEditTime: 2022-08-15 14:14:25
-->

<template>
  <div :class="className">
    <img v-if="loadMore" class="load" src="../assets/img/common/loading.gif"/>
    <template v-else-if="noMore">
      <el-divider>
        已经到底啦~
      </el-divider>
    </template>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'Footer',
  setup () {
    const store = useStore()
    const state = reactive({
      loadMore: computed(() => store.state.loadMore),
      noMore: computed(() => store.state.noMore),
      className: 'footer allBlogs'
    })
    const route = useRoute()
    state.className = `footer ${route.name}`
    watch(() => route.name, (val) => {
      if (val) {
        state.className = `footer ${val}`
      }
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
  .footer{
    width: 957px;
    height: 34px;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    :deep(.el-divider__text){
      padding: 0 30px;
      color: @second-text-color;
      background-color: #f6f9fa;
      font-size: 12px;
    }
    &.forum{
      width: 623px;
      margin-left: calc(50% - 390px);
    }
    &.allTarget,
    &.okrHistory,
    &.evaluation {
      margin-left: calc(50% - 398px);
    }
    &.artical-detail{
      width: 930px;
      margin-left: calc(50% - 460px);
    }
    &.news,&.allBlogs,&.dynamics{
      width: 790px;
      margin-left: calc(50% - 560px);
    }
    &.searchBlog{
      width: 789px;
      margin-left: calc(50% - 395px);
    }
    &.notification {
      width: 717px;
      margin-left: calc(50% - 395px);
    }
    .load{
      width: 60px;
      height: 60px;
    }
    &.writeJournal,&.work{
      display: none;
    }
    &.PublishForum{
      padding: 30px 0;
    }
  }
  @media (max-height: 700px) {
    .footer{
      padding: 30px 0;
    }
  }
</style>
