/*
 * @Description: 图标
 * @Author: heqi
 * @Date: 2022-01-21 18:29:48
 * @LastEditTime: 2023-07-25 17:02:54
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\plugins\element-icon.js
 */
import {
  Fold,
  Share,
  Star,
  Edit,
  PictureFilled,
  VideoCamera,
  Loading,
  Plus,
  ArrowRight,
  Delete,
  View,
  StarFilled,
  Download,
  Back,
  ArrowDownBold,
  ArrowUpBold,
  SuccessFilled,
  WarningFilled,
  ArrowLeftBold,
  ArrowRightBold,
  CloseBold,
  Document,
  // IconMenu,
  Location,
  Setting,
  Promotion,
  Mute,
  Microphone
} from '@element-plus/icons'

export default (app) => {
  app.component('Fold', Fold)
  app.component('Share', Share)
  app.component('Star', Star)
  app.component('Edit', Edit)
  app.component('PictureFilled', PictureFilled)
  app.component('VideoCamera', VideoCamera)
  app.component('Loading', Loading)
  app.component('Plus', Plus)
  app.component('ArrowRight', ArrowRight)
  app.component('Delete', Delete)
  app.component('View', View)
  app.component('StarFilled', StarFilled)
  app.component('Download', Download)
  app.component('Back', Back)
  app.component('ArrowDownBold', ArrowDownBold)
  app.component('ArrowUpBold', ArrowUpBold)
  app.component('SuccessFilled', SuccessFilled)
  app.component('WarningFilled', WarningFilled)
  app.component('ArrowLeftBold', ArrowLeftBold)
  app.component('ArrowRightBold', ArrowRightBold)
  app.component('CloseBold', CloseBold)
  app.component('Promotion', Promotion)
  app.component('Document', Document)
  // app.component('IconMenu', IconMenu)
  app.component('Location', Location)
  app.component('Setting', Setting)
  app.component('Mute', Mute)
  app.component('Microphone', Microphone)
}
