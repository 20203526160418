import { getCookies } from '@/utils/auth.js'
import { getOkrEvaluateCount } from '@/apis/okr'
import store from '@/store/index'
import { useRoute } from 'vue-router'
import eventBus from '@/utils/eventBus.js'
export default class SocketService {
  static instance = null
  static get Instance () {
    if (!this.instance) {
      this.instance = new SocketService()
    }
    return this.instance
  }

  route = useRoute()

  // 和服务端连接的socket对象
  ws = null
  // 存储回调函数
  callBackMapping = {}
  // 标识是否连接成功
  connected = false
  // 记录重试的次数
  sendRetryCount = 0
  // 重新连接尝试的次数
  connectRetryCount = 0
  // 手动关闭连接，不调用自动连接机制
  handClosed = false
  connect () {
    // 连接服务器
    if (!window.WebSocket) {
      return console.log('您的浏览器不支持WebSocket')
    }
    // let token = $.cookie('123');
    // let token = '4E6EF539AAF119D82AC4C2BC84FBA21F';
    const linkParam = this.route.name === 'tvwall' ? '&no_login_type=1' : ''
    const url = `${process.env.VUE_APP_WS_URL}?token=${getCookies('token')}&type=1${linkParam}`
    this.ws = new WebSocket(url)
    // 连接成功的事件
    this.ws.onopen = () => {
      // console.log('连接服务端成功了', new Date().toLocaleTimeString())
      this.connected = true
      // 重置重新连接的次数
      this.connectRetryCount = 0
      // 重置手动关闭连接标识
      this.handClosed = false
    }
    // 1.连接服务端失败
    // 2.当连接成功之后, 服务器关闭的情况
    this.ws.onclose = () => {
      // console.log('连接服务端失败', new Date().toLocaleTimeString())
      this.connected = false
      if (this.handClosed) return
      this.connectRetryCount++
      setTimeout(() => {
        this.connect()
      }, 8000 * this.connectRetryCount)
    }
    // 得到服务端发送过来的数据
    this.ws.onmessage = (msg) => {
      if (msg.data.indexOf('msg') !== -1) {
        const res = JSON.parse(msg.data)
        const code = String(res.code)
        switch (code) {
          case '1': // 接收动态
            store.commit('editwebSocketType', store.state.WebSocket.type + 1)
            break
          case '2': // 新的消息
          case '3': // 新的系统消息
            if (this.route.name === 'notification') {
              // 通知页面更新左侧未读数量
              eventBus.$emit('updateNoReadNum', res)
            }
            store.commit('addMessageCountToOne')
            break
          case '4': // 日志
            store.commit('work/setReceiveNewJournal', 1)
            break
          case '5': // 公告
            store.commit('announcement/setunReadAnt', store.state.announcement.unReadAnt + 1)
            break
          case '8': // 新增了待审核和评价和OKR
          case '9': // OKR被领导审核通过、驳回、评价完成
            if (code === '8') {
              getOkrEvaluateCount().then(({ code, data }) => {
                if (code === 0) {
                  store.commit('okr/setEvaluateCount', data)
                }
              })
            }
            if (this.route.name === 'notification') {
              // 通知页面更新左侧未读数量
              eventBus.$emit('updateNoReadNum', res)
            }
            store.commit('addMessageCountToOne')
            break
          case '6': // 更新状态 上下班打卡
            // console.log(clockUpdate)
            // console.log(str)
            // console.log(msg.data)
            // store.commit('work/setInitClockState')
            break
          case '10': // 获取抽奖动态 有新的动态要上墙
            if (this.route.name === 'dynamicWall') {
              store.commit('prize/addFeedsData', res.data)
            }
            break
          case '11': // 删除动态
            store.state.WebSocket.type && store.commit('editwebSocketType', store.state.WebSocket.type - 1)
            break
          case '12': // 消息被撤回，小铃铛减一
          case '13': // 系统消息被撤回
            if (this.route.name === 'notification') {
              // 通知页面更新左侧未读数量
              eventBus.$emit('updateNoReadNum', res)
            }
            store.commit('reduceMessageCountToOne')
            break
          case '16': // 公告
            store.state.announcement.unReadAnt && store.commit('announcement/setunReadAnt', store.state.announcement.unReadAnt - 1)
            break
          case '20': // 电视墙
            if (this.route.name === 'tvwall' && Number(this.route.params.id) === res.id) {
              store.commit('tvwall/addTVWallList', res.data)
            }
            break
          case '21': // 电视墙规则撤销
            store.commit('tvwall/setRuleRevoke', { id: res.id, value: true })
            break
          default: return false
        }
      } else if (msg.data.includes('心跳检测')) {
        const sendParam = {
          type: 99,
          token: getCookies('token')
        }
        this.route.name === 'tvwall' && (sendParam.no_login_type = 1)
        setTimeout(() => {
          this.send(sendParam)
        }, 50000)
      }
    }
  }

  // 关闭连接
  close () {
    if (!this.connected) return
    this.handClosed = true
    this.ws.close()
  }

  // 回调函数的注册
  registerCallBack (socketType, callBack) {
    this.callBackMapping[socketType] = callBack
  }

  // 取消某一个回调函数
  unRegisterCallBack (socketType) {
    this.callBackMapping[socketType] = null
  }

  // 发送数据的方法
  send (data) {
    // console.log(data)
    // 判断此时此刻有没有连接成功
    if (this.connected) {
      this.sendRetryCount = 0
      try {
        this.ws.send(JSON.stringify(data))
      } catch (e) {
        this.ws.send(data)
      }
    } else {
      this.sendRetryCount++
      setTimeout(() => {
        this.send(data)
      }, this.sendRetryCount * 30000)
    }
  }
}
