
import axios from 'axios'
import { dialogMsg } from '@/utils/dialogMsg'
// import { ElMessage } from 'element-plus'
import qs from 'qs'
import router from '@/router'
import store from '@/store'
import sha1 from 'js-sha1'
import { PushPending, DeletePending, CancelToken } from './cancelRequest'
import { getCookies } from '@/utils/auth'
import SocketService from '@/utils/websocket.js'

const logout = async () => {
  await store.dispatch('userInfo/clearLoginInfo')
  SocketService.Instance.close()
  router.push('/login')
}

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
const request = axios.create({
  timeout: 180 * 1000
})
request.interceptors.request.use(
  (config) => {
    let baseUrl = ''
    if (config.type === 'chatAI') {
      baseUrl = process.env.VUE_APP_CHAT_URL.slice(0, -1)
    } else if (config.type === 'face') {
      baseUrl = process.env.VUE_APP_FACE_URL
    } else {
      baseUrl = process.env.VUE_APP_URL.slice(0, -1)
    }
    config.url = baseUrl + config.url
    const timestamp = +new Date()
    DeletePending(config)
    config.cancelToken = new CancelToken((c) => {
      PushPending({ u: config.url + '?' + timestamp + '&' + config.method, f: c })
    })

    store.commit('setNetWorkErr', false)
    // console.log(config)
    const token = getCookies('token')
    const key = process.env.VUE_APP_KEY
    // sha1加密 签名
    const sign = sha1(timestamp + token + key).toUpperCase()

    // 如果有token就带上将token添加到config.headers.Authorization中
    if (token) {
      if (config.params) {
        token && (config.params.token = token)
        config.params.sign = sign
        config.params.timestamp = timestamp
      }
      if (config.data) {
        config.data.token = token
        config.data.sign = sign
        config.data.timestamp = timestamp
      }
    }

    config.method !== 'post'
      ? (config.data = qs.stringify({ ...config.data }))
      : (config.params = { ...config.params })
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (res) => {
    // 把已经完成的请求从pending中移除
    DeletePending(res.config)
    return res.data
  },
  (error) => {
    // 处理请求超时问题
    var originalRequest = error.config
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
      originalRequest._retry = true
      // dialogMsg('error', '请求超时')
      // return axios.request(originalRequest)
    }
    if (
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error' ||
      error.message === 'Request failed with status code 500' ||
      (error.message && error.message.includes('timeout'))
    ) {
      store.commit('setNetWorkErr', true)
    }
    // 提示一下
    if (
      error.response &&
      error.response.data &&
      (error.response.status === 422 || [3, 20002, 422].includes(error.response.data.code) || error.response.data.msg === '签名不正确！')
    ) {
      store.commit('userInfo/setIsExpire', true)
      dialogMsg('error', '账号过期请重新登录')
      logout()
    }
    return Promise.reject(error)
  }
)

export default request
