import { feedsTopicFeed, feedsHotlist } from '@/apis/hotTopic'
const state = {
  hotList: [],
  pages: 0,
  noMore: false,
  hotTopics: [],
  hotForums: [],
  loading: true
}
const mutations = {
  getHotList (state, payload) {
    state.hotList = payload
  },
  pushHotList (state, payload) {
    state.hotList.push(...payload)
  },
  setPages (state, payload) {
    state.pages = payload
  },
  setNomore (state, payload) {
    state.noMore = payload
  },
  // 热门话题和帖子
  setHotTopic (state, payload) {
    state.loading = false
    state.hotTopics = payload.hot_topic
    state.hotForums = payload.hot_post
  }
}
const actions = {
  APIgetHotList (state, { id, pages }) {
    feedsTopicFeed({ id, page: pages || 0 })
      .then((res) => {
        if (res.code === 0) {
          if (res.data.from === 1) {
            state.pages = 0
            state.commit('getHotList', res.data.data)
          } else {
            state.commit('pushHotList', res.data.data)
          }
          if (!res.data.next_page_url) {
            return state.commit('setNomore', true)
          }
          state.commit('setPages', res.data.current_page + 1)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return state.state.noMore
  },
  getHotTopic (state) {
    feedsHotlist()
      .then((res) => {
        if (res.code === 0) {
          state.commit('setHotTopic', res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
