<!--
 * @Descripttion: 投票信息
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-24 15:28:30
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-12 16:48:13
-->
<template>
  <template v-if="!!Object.keys(voteData).length">
    <div :class="['vote-tip', disabled ? 'disabled' : '']" @click="getVoteData">
      {{ voteData.is_option_fixed ? '封闭式投票' : '开放式投票' }} 主题: {{ voteData.title }}
      <img class="vote-close" @click.stop="delVoteData" src="@/assets/img/vote/close-icon.svg" alt="close">
    </div>
  </template>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})
const voteData = computed(() => store.state.vote.voteInfos)
const disabled = computed(() => props.disabled)

const getVoteData = () => {
  if (!disabled.value) {
    store.commit('vote/setVoteVisible', true)
  }
}

const delVoteData = () => {
  if (!disabled.value) {
    store.commit('vote/setClearNum')
  }
}

</script>

<style lang="less" scoped>
.vote-tip{
  width: 100%;
  background: rgba(255, 168, 0, 0.1);
  border-radius: 30px;
  font-weight: 400;
  font-size: 14px;
  color: #FFA800;
  padding: 7px 29px;
  margin-top: 16px;
  cursor: pointer;
  position: relative;
  &.disabled{
    background: #F6F9FA;
    cursor: not-allowed;
    // &:hover .vote-close {
    //   display: none;
    // }
  }
  .vote-close{
    display: none;
    position: absolute;
    top: 7px;
    right: 12px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
  &:hover{
    .vote-close{
      display: block;
    }
  }
}
</style>
