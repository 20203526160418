/*
 * @Descripttion: 电视墙
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-07-03 11:46:07
 * @LastEditors: tangjz
 * @LastEditTime: 2023-08-02 17:01:43
 */

const state = {
  tvWallList: [], // 电视墙素材
  feedsData: {}, // 动态
  isRuleRevoke: {} // 是否撤销规则
}

const mutations = {
  addTVWallList (state, payload) {
    // console.log(payload)
    state.tvWallList.push(payload)
  },
  setTVWallList (state, payload) { // 更新当前电视墙数据
    state.tvWallList = payload
  },
  setFeedList (state, payload) { // 更新当前动态
    state.feedsData[payload.index] = payload.feed_info
  },
  addFeedsData (state, payload) { // 增加动态
    if (!payload.feed_info) return
    if ((state.feedsData[payload.index] &&
       state.feedsData[payload.index][state.feedsData[payload.index].length - 1]?.id) !==
       payload.feed_info.id) {
      if (!state.feedsData[payload.index]) {
        state.feedsData[payload.index] = [payload.feed_info]
      } else {
        state.feedsData[payload.index].push(payload.feed_info)
      }
    }
  },
  setRuleRevoke (state, payload) { // 设置撤销规则
    state.isRuleRevoke[payload.id] = payload.value
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
