/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-22 17:23:29
 * @LastEditors: tangjz
 * @LastEditTime: 2023-08-07 12:51:08
 */

/**
 * 格式化时间 2021-12-13 18:55:35 => 2021/12/13 18:55
 * @param {String} date
 * @param {Array}  type
 * @param {String} timeType
 * @returns
 */
const formatAllDate = (date, type, timeType) => {
  let index
  const fillList = {
    16: ':00',
    13: ':00:00',
    10: ' 00:00:00'
  }
  date = date?.length < 19 ? (date + fillList[date.length]) : date
  if (!date) {
    return ''
  } else {
    switch (timeType) {
      case 'day':
        index = -9
        break
      case 'hour':
        index = -6
        break
      case 'min':
        index = -3
        break
      default:
        index = date.length
    }
    return !type ? date.slice(0, index) : date.replaceAll(type[0], type[1]).slice(0, index)
  }
}

/**
 * 匹配字符串含有字母或者汉字各不同情景
 * @param {Number} maxLength       最大长度
 * @param {String} inputString     输入框字符串
 * @returns
 */

const stringVerify = (maxLength, inputString) => {
  // eslint-disable-line
  const reg = /[\u4e00-\u9fa5]/g
  // inputString = inputString.trim()
  const isCharacter = inputString.slice(0, maxLength).match(reg)

  if (isCharacter) { // 字符串含有非字母
    const stringArrs = []

    // 穷举法进行获取匹配场景,符合要求有以下几种情况
    for (let oneSize = 0; oneSize <= maxLength; oneSize++) {
      for (let twoSize = 0; twoSize <= maxLength / 2; twoSize++) {
        if ((oneSize + twoSize * 2 === maxLength) || (oneSize + twoSize * 2 === maxLength - 1)) {
          stringArrs.push(oneSize + twoSize)
        }
      }
    }
    // 匹配数据相等于maxLength
    const stringArrJust = stringArrs.filter((item) => {
      // 因为必须含有汉字才能走到这步，如果没有汉字直接return false
      if (!inputString.slice(0, item).match(reg)) {
        return false
      }
      return (maxLength === item + inputString.slice(0, item).match(reg).length)
    })
    // 匹配数据相等于maxLength - 1
    const stringArrOdd = stringArrs.filter((item) => {
      if (!inputString.slice(0, item).match(reg)) {
        // 刚好最后一个是汉字返回true
        return maxLength - 1 === item
      }
      return (maxLength - 1 === item + inputString.slice(0, item).match(reg).length)
    })
    let stringArr = []
    if (stringArrJust.length) {
      stringArr = stringArrJust
    } else {
      stringArr = stringArrOdd
    }

    inputString = inputString.slice(0, stringArr[0])
  } else { // 整个字符串都是字母
    inputString = inputString.slice(0, maxLength)
  }
  return inputString
}

const exceedWords = (maxLength, value) => {
   // eslint-disable-line
  const reg = /[\u4e00-\u9fa5]/g
  // inputString = inputString.trim()
  const characterList = value.match(reg)
  const isExceed = maxLength < value.length + (characterList?.length || 0)
  console.log(isExceed)
  return isExceed
}

export {
  formatAllDate,
  stringVerify,
  exceedWords
}
