/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-06-04 17:12:46
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-02 16:59:01
 */
const accountLogin = () => import('@/views/login/accountLogin/index.vue')
const tvwall = () => import('@/views/tvwall')

export default [
  {
    path: '/login',
    component: () => import('@/views/login'),
    name: 'login',
    meta: { noLogin: true },
    children: [
      {
        path: '',
        component: accountLogin,
        name: 'accountLogin'
      }
    ]
  },
  {
    path: '/tvwall/:id',
    component: tvwall,
    name: 'tvwall',
    meta: { noLogin: true }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/login'
  }
]
