/*
 * @Descripttion: html文本filter过滤器
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-14 17:46:18
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-13 17:10:34
 */
import xss from 'xss'
export const xssChange = (html) => xss(html, {
  onIgnoreTagAttr (tag, name, value) {
    if (name === 'class') {
      return `${name}="${xss.escapeAttrValue(value)}"`
    }
  }
})

/**
 * 转义html
 * @param {*} html
 * @returns
 */
export function formatHtml (html) {
  if (!html) return ''
  const content = html
    .replaceAll('\\', '')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
  return content
}

/**
 * 格式化时间 2021-12-13 18:55:35 => 2021年12月13日 18:55
 * @param {*} date
 * @returns
 */
export function formatDate (date, type) {
  if (!date) {
    return ''
  } else {
    return type === 'secend' ? date.replace('-', '年').replace('-', '月').replace(' ', '日 ') : date.replace('-', '年').replace('-', '月').replace(' ', '日 ').substr(0, date.length - 2)
  }
}

/**
 * @提取表情包回显
 */
const requireEmoji = require.context('@/assets/emoji')
const files = requireEmoji.keys()
const emojiList = []
export const fileUrl = files.map((url) => require(`@/assets/emoji/${url.slice(2)}`))
fileUrl.forEach((item, index) => {
  emojiList.push({
    title: '[' + item.replace(/(.*\/)*([^.]+).*/ig, '$2') + ']',
    url: item
  })
})

export function analyzeEmoji (cont) {
  // 编译表情替换成图片展示出来
  const pattern1 = /<img.*?(?:>|\/>)/gi
  const pattern2 = /\[([^[\]]*)+\]/g
  // 匹配url正则
  const patternUrl1 = RegExp('(https?|ftp|file|http)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|;]', 'g')
  // let str = formatHtml(cont)
  let str = cont
  const patternImgUrl = /(.png|.svg|.jpg|.gif)$/
  str = str.replace(patternUrl1, i => {
    if (!patternImgUrl.test(i)) {
      const tempArr = i.split('.')
      if (tempArr.length === 2 && /^\d+$/.test(tempArr[0])) return i
      if (i.includes('https') || i.includes('ftp') || i.includes('file') || i.includes('http')) return `<a href="${i.replace(/&nbsp;/g, '')}" target="blank">${i}</a>`
      else return `<a href="https://${i}" target="blank">${i}</a>`
    } else {
      return i
    }
  })
  str = str.replaceAll(pattern1, m => {
    const src = emojiList.filter(item => item.url === m.split('"')[1])[0]?.url
    return src ? "<img class='emoji-img' src='" + src + "'/>" : m
  })
  str = str.replace(pattern2, m => {
    const src = emojiList.filter(item => item.title === m)[0]?.url
    return src ? "<img class='emoji-img' src='" + src + "'/>" : m
  })
  return str
}

export function addTableFatherNode (editorVal) {
  const tableReg = /<table[^>]*>\s*(<tbody[^>]*>)?(\s*<tr[^>]*>[\s\S]*?<\/tr>\s*)*(<\/tbody>)?\s*<\/table>/g
  editorVal = editorVal.replace(tableReg, (i) => {
    return `<div class='table-box'>${i}</div>`
  })
  return editorVal
}
