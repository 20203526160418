<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-06-11 17:45:44
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-10-09 11:40:08
-->
<template>
  <p class="common-icon">
    <svg @click="handleClick" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 8h14v14a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8z" stroke="#999" stroke-width="2"/><rect x="4.5" y="7.5" width="19" height="1" rx=".5" stroke="#999"/><rect x="12.5" y="11.5" width="8" height="1" rx=".5" transform="rotate(90 12.5 11.5)" stroke="#999"/><rect x="16.5" y="11.5" width="8" height="1" rx=".5" transform="rotate(90 16.5 11.5)" stroke="#999"/><rect x="11.5" y="4.5" width="5" height="1" rx=".5" stroke="#999"/></svg>
    <template v-if="props.isTextShow">删除</template>
  </p>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import { myToast, confirm } from '@/utils/dialogMsg.js'
const emit = defineEmits(['handleClick'])

const props = defineProps({
  isTextShow: { // 文案是否显示
    type: Boolean,
    default: true
  },
  apiMethod: { // api接口
    type: Function,
    default: null
  },
  params: { // 请求参数
    type: Object,
    default: () => {}
  },
  deleteText: {
    type: String,
    default: '这条动态'
  }
})

const message = computed(() => props.deleteText)

const handleClick = () => {
  console.log(1111)
  confirm({
    message: `确认删除${message.value}吗？`,
    success: function () {
      if (props.apiMethod) {
        props.apiMethod(props.params).then((res) => {
          if (res.code === 0) {
            myToast({ type: 'success', message: '删除成功' })
            emit('handleClick', props.params.id)
          } else {
            myToast({ type: 'error', message: '删除失败' })
          }
        })
      } else {
        emit('handleClick')
      }
    },
    cancel: function () {
    }
  })
}
</script>

<style scoped lang="less">
@import '../../assets/less/icon.less';
</style>
