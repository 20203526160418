/*
 * @Descripttion: 弹窗相关
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-06 09:11:00
 * @LastEditors: heqi
 * @LastEditTime: 2022-08-01 15:08:38
 */
import { Toast } from 'vant'
import { ElMessageBox } from 'element-plus'

export function dialogMsg (type = 'success', message, teleport) {
  type === 'success' &&
    Toast({
      message,
      icon: 'passed',
      iconSize: '60px',
      teleport
    })
  type === 'error' &&
    Toast({
      message,
      icon: 'close',
      iconSize: '60px',
      teleport
    })
  type === 'warning' &&
    Toast({
      message,
      icon: 'warning-o',
      iconSize: '60px',
      teleport
    })
}

export function myToast (obj = { type: '', message: '', forbidClick: false }) {
  const { type, message, forbidClick } = obj
  type === 'success' &&
    Toast({
      message,
      icon: 'passed',
      iconSize: '60px',
      forbidClick
    })
  type === 'error' &&
    Toast({
      message,
      icon: 'close',
      iconSize: '60px',
      forbidClick
    })
  type === 'warning' &&
    Toast({
      message,
      icon: 'warning-o',
      iconSize: '60px',
      forbidClick
    })
}

export function confirm (obj = { message: '', title: '提示', confirmButtonText: '确认', cancelButtonText: '取消', closeOnClickModal: true, success: function () {}, cancel: function () {} }) {
  ElMessageBox.confirm(obj.message, obj.title, {
    confirmButtonText: obj.confirmButtonText || '确认',
    cancelButtonText: obj.cancelButtonText || '取消',
    customClass: 'myMessageBox',
    center: true,
    roundButton: true,
    closeOnClickModal: obj.closeOnClickModal
  }).then(() => {
    obj.success()
  }).catch(() => {
    obj.cancel()
  })
}
