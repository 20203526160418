<!--
 * @Author: wangjuan
 * @Date: 2022-10-28 14:49:55
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-06 11:06:14
 * @FilePath: \sns_web\src\App.vue
-->

<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { useStore } from 'vuex'
import { getCookies } from '@/utils/auth'
import { getUserInfo } from '@/apis/login.js'
import { getAllUserList } from '@/apis/search.js'
// import { getOkrExit } from '@/apis/okr.js'

import eventBus from '@/utils/eventBus.js'

export default {
  components: {
    ElConfigProvider
  },
  setup () {
    const store = useStore()
    // 在页面加载时读取sessionStorage里的状态信息
    // if (getSessionStorage('store')) {
    //   store.replaceState(Object.assign(store.state, JSON.parse(getSessionStorage('store'))))
    // }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      eventBus.$emit('saveLog')
      // setSessionStorage('store', JSON.stringify(store.state))
      // 检测是否打卡
    })

    // 页面刷新就会请求用户信息
    if (getCookies('token')) {
      getUserInfo({ token: getCookies('token') }).then(({ code, data }) => {
        if (code === 0 && data.id) {
          store.commit('userInfo/setUserInfo', data)
        } else {

        }
      })
      getAllUserList().then(res => {
        store.commit('userInfo/setUserMedal', res.data)
      })
    }

    // 刷新清除公告保存的limit和page
    store.commit('announcement/setAnnouncementData', { page: 1, limit: 10 })

    return {
      locale: zhCn
    }
  }
}
</script>
