<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-10 14:59:54
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-11-14 16:11:28
-->

<template>
  <mavon-editor
      ref="md"
      v-model="text"
      :ishljs="true"
      @imgAdd="imgAdd"
      :subfield="false"
      :toolbars="toolbars"/>
</template>

<script setup>
import { ref } from 'vue'

const text = ref('Hello Editor!')

const toolbars = {
  bold: true, // 粗体
  italic: true, // 斜体
  header: true, // 标题
  underline: true, // 下划线
  strikethrough: true, // 中划线
  mark: true, // 标记
  superscript: true, // 上角标
  subscript: true, // 下角标
  quote: true, // 引用
  ol: true, // 有序列表
  ul: true, // 无序列表
  link: true, // 链接
  imagelink: true, // 图片链接
  code: true, // code
  table: true, // 表格
  fullscreen: false, // 全屏编辑
  readmodel: false, // 沉浸式阅读
  htmlcode: true, // 展示html源码
  help: true, // 帮助
  /* 1.3.5 */
  undo: true, // 上一步
  redo: true, // 下一步
  trash: true, // 清空
  save: false, // 保存（触发events中的save事件）
  /* 1.4.2 */
  navigation: true, // 导航目录
  /* 2.1.8 */
  alignleft: true, // 左对齐
  aligncenter: true, // 居中
  alignright: true, // 右对齐
  /* 2.2.1 */
  subfield: true, // 单双栏模式
  preview: true // 预览
}
const imgAdd = (pos, $file) => {
  // 将图片上传服务器
  const formData = new FormData()
  formData.append('file', $file)
  console.log(123123)
  // axios_request({
  //   url: http.article.fileupload,
  //   method: 'post',
  //   data: formData,
  //   headers: { 'Content-Type': 'multipart/form-data' }
  // }).then((res) => {
  //   // 第二步，将返回的url替换到文本位置
  //   md.value.$img2Url(pos, res.data)
  // })
}
</script>

<style lang="less">
.v-note-wrapper.shadow{
  height: calc(100vh - 300px) ; // 改变编辑器默认高度，不需要的可以不配置
  box-shadow: none !important;
  .v-show-content{
    background: #fff !important;
  }
}
</style>
