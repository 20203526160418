/*
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-12 17:00:52
 * @LastEditors: tangjz
 * @LastEditTime: 2023-05-19 11:36:48
 * @FilePath: \mfadmind:\hq\mfzj\src\store\modules\prize.js
 * @Description: prizeData
 */
const state = {
  prizeData: {}, // 中奖详情
  prizelist: [], // 奖品列表数据
  selNumber: 1, // 每次抽取人数
  nowGift: {}, // 当前奖品数据
  luckyUser: {}, // 已中奖名单 首次获取
  prizeCondition: {}, // 抽奖弹窗开始抽奖判断条件
  mfOverlay: false, // 自定义遮罩层
  feedsData: [], // 抽奖动态
  feedsIds: [], // 抽奖动态id
  currentFeeds: [] // 抽奖动态索引
}

const mutations = {
  setPrizeData (state, payload) { // 设置中奖详情
    // console.log(payload)
    if (state.prizeData[payload.prizeId]?.length) { // 已经抽过就push
      return state.prizeData[payload.prizeId].push(...payload.value)
    }
    state.prizeData[payload.prizeId] = payload.value // 没有数据直接存储
    // 减去奖品列表数据
    state.prizelist.filter(i => i.id === payload.prizeId)[0].all_num -= state.prizeData[payload.prizeId].length
  },
  setPrizelist (state, payload) { // 设置奖品列表数据
    state.prizelist = payload
  },
  setSelNumber (state, payload) { // 设置抽取数量
    state.selNumber = payload
  },
  setNowGift (state, payload) { // 当前抽取奖品数据
    state.nowGift = payload
  },
  setLuckyUser (state, payload) { // 中奖用户
    payload.forEach(element => {
      state.luckyUser[element.id] = element.lucky_users
    })
  },
  setPrizeConditions (state, payload) { // 设置抽奖条件
    state.prizeCondition = payload
  },
  setMfOverlay (state, payload) { // 设置遮罩层
    state.mfOverlay = payload
  },
  setFeedList (state, payload) { // 更新当前动态id
    state.feedsData = payload
    state.feedsIds = payload.map(item => item?.id)
  },
  addFeedsData (state, payload) { // 增加动态id
    if (state.feedsData[state.feedsData.length - 1]?.id !== payload.id) {
      state.feedsIds.push(payload.id)
      state.feedsData.push(payload)
    }
  },
  setCurrentFeeds (state, payload) { // 更新当前滚动动态
    state.currentFeeds = payload
  }
}
const actions = {
  getFeedsList ({ state, commit }) {
    const lastItem = state.currentFeeds.slice(-1)
    const newFeedsData = lastItem.length ? state.feedsData.slice(lastItem[0] + 1) : state.feedsData.slice(0)
    commit('setFeedList', newFeedsData)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
