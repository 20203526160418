<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-08-11 17:37:46
 * @LastEditTime: 2022-08-12 10:36:51
 * @LastEditors: heqi
 * @FilePath: \sns_h5d:\hq\mfzj\src\components\Dot.vue
-->
<template>
  <span class="dot">{{count}}</span>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
export default {
  name: 'Dot',
  props: {
    count: {
      type: Number
    }
  },
  setup (props) {
    const state = reactive({
      count: computed(() => props.count)
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.dot {
  display: inline-block !important;
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
  border-radius: 9px !important;
  text-align: center !important;
  background: #FF0000 !important;
  font-size: 12px !important;
  color: #FFFFFF !important;
}
</style>
