/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-04-18 13:29:37
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-05-14 11:51:41
 */
import { RouterView } from 'vue-router'

const prizeSetting = () => import('@/views/layout/prize/prizeSetting')
const prize = () => import('@/views/layout/prize/index.vue')
const prizePoster = () => import('@/views/layout/prize/prizePoster/index.vue')

export default [
  {
    path: '/prize',
    component: RouterView,
    redirect: '/blogs/dynamics',
    children: [
      {
        path: 'screen',
        component: prize,
        name: 'prizeScreen'
      },
      {
        path: 'poster',
        component: prizePoster,
        name: 'prizePoster'
      },
      {
        path: 'setting',
        component: prizeSetting,
        name: 'prizeSetting'
      }
    ]
  }
]
