/*
 * @Descripttion: 取消请求
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-23 19:34:39
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2021-12-23 19:46:31
 */

import axios from 'axios'
let cancelTokenArr = []
export const CancelToken = axios.CancelToken

// 记录要取消的请求
export const PushPending = params => {
  cancelTokenArr.push(params)
}

// 取消请求
export const DeletePending = (config = {}) => {
  if (Object.keys(config).length > 0) {
    CancelPengding(config)
  } else {
    // 取消所有请求
    cancelTokenArr.forEach(pending => { pending && pending.f && pending.f() })
    cancelTokenArr = []
  }
}

// 取消请求实际操作
const CancelPengding = config => {
  const pending = cancelTokenArr.find((item) => item.u === config.url + '&' + config.method)
  if (pending && pending.f) {
    pending.f() // 执行取消操作
    cancelTokenArr = cancelTokenArr.filter((item) => item.u !== config.url + '&' + config.method) // 把这条请求移除
  }
}
