/*
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-25 14:26:06
 * @LastEditors: tangjz
 * @LastEditTime: 2023-09-11 14:15:33
 * @FilePath: \mfadmind:\hq\mfzj\src\utils\okr\selQuarter.js
 */
export default function getSelMonth (montlist, routeName, depName, queryDate) {
  (routeName === 'okrHistory' || routeName === 'allTarget' || routeName === 'targetAligned') && !queryDate.year && (queryDate.year = new Date().getFullYear())
  !queryDate.month && (queryDate.month = new Date().getMonth() + 1 + '月')
  // console.log(queryDate)
  const date = {
    Q1: ['1月', '2月', '3月'],
    Q2: ['4月', '5月', '6月'],
    Q3: ['7月', '8月', '9月'],
    Q4: ['10月', '11月', '12月']
  }
  const nowDate = {
    nowMonth: new Date().getMonth() + 1 + '月',
    nowQuarter: ''
  }

  for (const i in date) {
    if (date[i].includes(nowDate.nowMonth)) {
      nowDate.nowQuarter = i
    }
  }

  const beforeMonth = montlist.filter(i => new Date().getMonth() === parseFloat(i.name))[0]

  if (routeName === 'okrHistory') {
    if (!beforeMonth && new Date().getFullYear() > queryDate.year) {
      montlist.forEach(i => i.select === 2 && (i.selected = true))
      return
    }
    // 选择今年以后的年份
    if (new Date().getFullYear() > queryDate.year) return

    if (!beforeMonth) {
      return montlist.map(i => (i.select = 0))
    }
    // 如果是历史okr中 当前周期之后的周期不可选择
    if (beforeMonth.select === 2) {
      const currentIndex = montlist.findIndex(i => parseFloat(i.name) === new Date().getMonth())
      montlist.map((i, index) => index > currentIndex && (i.select = 0))
      return (beforeMonth.selected = true)
    } else {
      // 查询上个月所在季度
      let quarter = ''
      for (const i in date) {
        if (date[i].includes(beforeMonth.name)) {
          quarter = i
        }
      }
      const prevQuater = montlist.filter(i => quarter === i.name)[0]
      if (prevQuater.select === 2) {
        const currentIndex = montlist.findIndex(i => i.name === quarter)
        montlist.map((i, index) => index > currentIndex && (i.select = 0))
        return (prevQuater.selected = true)
      }
      return
    }
  } else if (routeName === 'allTarget' || routeName === 'targetAligned') {
    montlist.forEach(i => (i.select = 1))
    if (queryDate.year === new Date().getFullYear()) {
      return (montlist.filter(i => parseFloat(i.name) === new Date().getMonth() + 1)[0].selected = true)
    }
    return
  }
  if (queryDate.year) {
    return (montlist.filter(i => i.name === queryDate.month)[0].selected = true)
  }

  montlist.forEach(i => {
    i.selected = false
    if (i.name === nowDate.nowQuarter) {
      i.select === 2 ? (montlist.filter(i => i.name === nowDate.nowQuarter)[0].selected = true) : (montlist.filter(i => i.name === nowDate.nowMonth)[0].selected = true)
    }
  })
}
