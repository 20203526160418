<!--
 * @Descripttion: 文件预览&下载
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-25 14:29:29
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-04-22 09:45:16
-->
<template>
  <template  v-for="(i,index) in dataList"
    :key="i">
    <template v-if="i.length > 0">
      <el-image
      :src="i"
      :preview-src-list="getPrevList(index)"
      :initial-index="index"
      fit="cover"
      hide-on-click-modal
      lazy
      @click="cusPreviewImage(index)"
      @close="closePreview"
      :class="{
        'image-small': imageList.length > 1
      }"
    >
      <template #placeholder>
        <div class="image-slot lightning-animation"></div>
      </template>
    </el-image>
    </template>
  </template>

  <span
    class="el-image-viewer__btn el-image-viewer__download"
    v-show="dnFlag"
    @click="dnImage"
  >
    <el-icon><Download /></el-icon>
  </span>
</template>

<script>
import { ref, nextTick, onMounted, reactive, toRefs, computed } from 'vue'
export default {
  name: 'ImagePrev',
  props: {
    imageList: {
      type: Array,
      default: () => []
    },
    imageLocation: { // blogs
      type: String,
      default: ''
    }
  },
  setup (props) {
    onMounted(() => {
      document.addEventListener('keyup', (e) => {
        e = e || window.event
        if (e.keyCode === 27) {
          state.dnFlag = false
        }
      })
    })
    const state = reactive({
      dnFlag: false,
      wrapperElem: null,
      nowImgurl: '',
      dataList: computed(() => {
        const arr = []
        if (props.imageLocation === 'blogs') {
          props.imageList.forEach((url, index) => {
            if (url) {
              url = url.includes('image/format,jpg') ? `${url}/resize,w_650/quality,Q_40` : `${url}?x-oss-process=image/resize,w_650/quality,Q_40`
              index <= 8 && arr.push(url)
            }
          })
        } else {
          props.imageList.forEach((url, index) => {
            if (url) {
              url = url.includes('image/format,jpg') ? `${url}/resize,m_fill,h_320,w_320` : `${url}?x-oss-process=image/resize,m_fill,h_320,w_320`
              index <= 8 && arr.push(url)
            }
          })
        }
        return arr
      }),
      preList: computed(() => {
        return JSON.parse(JSON.stringify(props.imageList))
      })
    })
    const getPrevList = (index) => {
      return state.preList.slice(index).concat(state.preList.slice(0, index))
    }

    const hidElClassNameLists = ref([
      'el-image-viewer__mask',
      'el-image-viewer__btn el-image-viewer__close',
      'el-icon-close'
    ])

    const handleImageScale = (e) => {
      const changeDomList = ['el-icon-arrow-left', 'el-icon-arrow-right', 'el-image-viewer__btn el-image-viewer__next', 'el-image-viewer__btn el-image-viewer__prev']
      if (changeDomList.includes(e.target.className)) {
        const isScale = document.querySelector('.el-icon-c-scale-to-original') // 处于放大状态
        const imgList = document.querySelectorAll('.el-image-viewer__img')
        if (isScale && imgList.length > 1) {
          document.querySelector('.el-image-viewer__img').parentElement.classList.remove('flex-start')
          if (Array.prototype.filter.call(imgList, (item) => item.style.display !== 'none')[0].clientHeight > document.body.offsetHeight) {
            document.querySelector('.el-image-viewer__img').parentElement.classList.add('flex-start')
          }
        }
      }
      if (e.target.className === 'el-icon-c-scale-to-original' && document.querySelector('.el-image-viewer__img').clientHeight > document.body.offsetHeight) {
        document.querySelector('.el-image-viewer__img').parentElement.classList.add('flex-start')
      }
    }

    function cusPreviewImage (index) {
      state.nowImgurl = state.preList[index]
      state.dnFlag = true
      checkElemlents()
      window.addEventListener('click', handleImageScale)
    }

    function closePreview () {
      window.removeEventListener('click', handleImageScale)
    }

    // function switchImage (index) {
    //   if () {
    //     document.querySelector('.el-image-viewer__img').parentElement.classList.remove('flex-start')
    //     if (document.querySelector('.el-image-viewer__img').clientHeight > document.body.offsetHeight) {
    //       document.querySelector('.el-image-viewer__img').parentElement.classList.add('flex-start')
    //     }
    //   }
    // }

    function checkElemlents () {
      nextTick(() => {
        const wrapper = document.getElementsByClassName(
          'el-image-viewer__wrapper'
        )
        if (wrapper.length > 0) {
          state.wrapperElem = wrapper[0]
          cusClickHandler()
        } else {
          checkElemlents()
        }
      })
    }
    function cusClickHandler () {
      state.wrapperElem.addEventListener('click', hideCusBtn)
    }
    function hideCusBtn (e) {
      const className = e.target.className
      // console.log(className)
      if (hidElClassNameLists.value.includes(className)) {
        state.dnFlag = false
      }
    }

    function dnImage () {
      // 获取当前展示的元素
      const list = [...document.querySelectorAll('.el-image-viewer__img')]
      const img = list.find((item) => item.style.display === '')
      downloadImage(img && img.src)
    }

    function downloadImage (imgUrl = state.nowImgurl) {
      const tmpArr = imgUrl.split('/')
      imgUrl.includes('?')
        ? (imgUrl = imgUrl + '&s=' + Math.random().toString())
        : (imgUrl = imgUrl + '?s=' + Math.random().toString())
      const fileName = tmpArr[tmpArr.length - 1]
      window.URL = window.URL || window.webkitURL
      var xhr = new XMLHttpRequest()
      xhr.open('get', imgUrl, true)
      // 至关重要
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (this.status === 200) {
          // 得到一个blob对象
          var blob = this.response
          var fileUrl = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          ;(document.body || document.documentElement).appendChild(a)
          a.href = fileUrl
          if ('download' in a) {
            a.download = fileName
          } else {
            a.setAttribute('download', fileName)
          }
          a.target = '_self'
          a.click()
          a.remove()
        }
      }
      xhr.send()
    }
    return {
      ...toRefs(state),
      getPrevList,
      cusPreviewImage,
      dnImage,
      closePreview
    }
  }
}
</script>

<style>
  .flex-start {
    align-items: flex-start !important;
  }
</style>
<style lang="less" scoped>
  .el-image-viewer__download {
    top: 40px;
    right: 100px;
    width: 44px;
    height: 44px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    z-index: 2001; /* 如果该组件需要传递 z-index 的值，这个值也需要做成动态的 props */
    cursor: pointer;
    position: fixed;
    svg {
      width: 24px;
      height: 24px;
      transform: translate(-5px, -5px);
      color: #e3e4e4;
    }
  }
  .image-slot {
    display: block;
    width: 156px;
    height: 156px;
    opacity: .3;
    background-color: #606266;
    border-radius: 5px;
  }
  :deep(.el-image-viewer__img) {
    max-width: calc(100vw - 60px)
  }
</style>
