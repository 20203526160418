<template>
    <div class="pick-wrapper" ref="pickRef">
    <div class="selectDate" :style="{width: choiceModel === 'simple' ? '123px' : '166px'}" @click="showCalendar = !showCalendar">
      <span class="showSelDate oneline-overflow">
        {{calendatYear.name}}年<template
          v-for="(i, index) in calendatMonth"
          :key="i.id"
          >{{i.name}}{{(index + 1) === calendatMonth.length ? '' : ','}}
        </template>
      </span>
      <el-icon><ArrowDownBold /></el-icon>
    </div>
    <div class="selector" :style="{left: choiceModel === 'simple' ? '-22px' : '-2px'}" v-show="showCalendar">
      <div class="select-year">
        <el-icon @click.stop="changeYear('close')"><ArrowLeftBold /></el-icon>
        <span :class="[
          'cursor-pointer',
          yearSelected ? 'creating-year' : '', (okr.cycleList.select === 0) ? 'noSelect' : (okr.cycleList.select === 1) ? 'creating' : '' , (okr.cycleList.select === 2 ? 'created' : '')
          ]"
          @click="selYear"
          >{{calendatYear.name}}</span>
        <span class="right-btn"><el-icon @click.stop="changeYear('add')" v-if="showRightBtn"><ArrowRightBold /></el-icon></span>
      </div>
      <ul>
        <li v-for="item in calendatDate" :key="item.id" @click="selectDateMonth(item.id, item.select)">
          <span
            :class="[item.selected ? 'selected' : '', (item.select === 0) ? 'noSelect' : (item.select === 1) ? 'creating' : '', (item.select === 2 ? 'created' : '')]"
          >
            {{item.name}}
          </span>
        </li>
      </ul>
      <div class="select-confirm">
        <template v-if="btnStatus">
          <el-button type="text" @click="selState('all')" :style="{ color: currentClick === 'all'? '#7972F0' : '#333'}">全部</el-button>
          <el-button type="text" @click="selState('runing')" :style="{ color: currentClick === 'runing'? '#7972F0' : '#333'}">进行中</el-button>
        </template>
        <el-button @click="confirmMonth">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
import { onMounted, onUnmounted, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { confirm, dialogMsg } from '@/utils/dialogMsg.js'
import { getOkrCycle } from '@/apis/okr.js'
import { useStore } from 'vuex'
import getSelMonth from '@/utils/okr/selQuarter.js'
import eventBus from '@/utils/eventBus.js'
export default {
  name: 'PickDate',
  props: {
    choiceModel: {
      type: String,
      default: 'simple' // 单选(simple) || 多选(more)
    },
    btnStatus: { // 全部 进行中的显示隐藏
      type: Boolean,
      default: () => true
    },
    historySelect: { // 是否为历史目标选择器
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      showCalendar: false, // 控制显示隐藏
      calendatMonth: computed({ // 选中的日期
        get: () => {
          return state.calendatDate.filter(i => i.selected)
        }
      }),
      calendatDate: [], // 周期数据
      calendatYear: { // 年份
        name: new Date().getFullYear()
      },
      deptId: computed(() => route.params.id || route.query.depId), // id为myokr 的id depId为历史depId
      allTargetID: computed(() => route.params.allTargetID), // alltarget 的id
      choiceModel: computed(() => props.choiceModel), // 选择模式 单选/全选
      currentClick: 'all', // 选择全部 或者进行中
      pickRef: null, // ref 控制显示隐藏
      okr: computed(() => store.state.okr), // okr模块 vuex数据
      btnStatus: computed(() => props.btnStatus), // 按钮状态
      historySelect: computed(() => props.historySelect), // 历史内容中的下拉框
      showRightBtn: true, // 是否显示右侧按钮
      depName: computed(() => route.params.depName), // 部门名称
      initSelect: [], // 初始月份
      initYear: {}, // 初始年份
      yearSelected: false, // 年份样式
      queryDate: {
        year: '',
        month: ''
      }
    })

    // 存储okr周期 id数据
    const getOkrCycleList = (year, deptId, firstQues) => {
      return new Promise((resolve) => {
        getOkrCycle({ year, dept_id: deptId }).then(({ code, data }) => {
          if (code === 0) {
            // 存储周期数据
            state.calendatDate = data.children
            state.calendatDate.forEach(i => (i.selected = false))
            // 初始周期
            firstQues && getSelMonth(state.calendatDate, route.name, state.depName, state.queryDate)

            // 年份信息id name style
            state.calendatYear.name = parseFloat(data.name)
            state.calendatYear.id = data.id
            state.calendatYear.select = data.select

            resolve()
          }
        })
      })
    }

    // 确认选择日期 checkType 是否检查周期可选
    const confirmMonth = () => {
      if (state.calendatMonth.length && state.calendatMonth.filter(i => i.select === 0).length) {
        return dialogMsg('error', '周期不可选')
      }
      const emitFn = () => {
        let selID = []
        const selName = []
        // 获取对应id
        const monthList = state.calendatDate.filter(i => i.selected)
        if (monthList.length) {
          monthList.forEach(i => {
            selID.push(i.id)
            selName.push(i.name)
          })
        } else {
          selID = state.calendatYear.id
        }
        // 有选择日期时
        Array.isArray(selID) && selID.length === 1 && (selID = selID[0])

        if (process.env.VUE_APP_COMPANY_NAME === state.depName) selID = state.calendatYear.id
        // console.log(selID)
        const emitData = {
          selID, // 查询的周期id
          isYearCycle: selID === state.calendatYear.id,
          selCycle: selName.join(''),
          selYear: state.calendatYear.name,
          selType: props.choiceModel === 'more' ? (state.currentClick === 'all' ? 1 : 2) : undefined,
          queryType: route.params.selfEvaluate ? 'evalute' : ''
        }
        emit('confirmSelDate', emitData)

        state.initSelect = monthList
        state.initYear = { ...state.calendatYear }
      }
      if (state.choiceModel === 'simple' && route.name === 'okr-add' && (Number(route.query.year) !== Number(state.calendatYear.name) || route.query.month !== state.calendatMonth[0]?.name)) {
        confirm({
          message: '切换日期会退出创建页面，确定退出吗？',
          success: () => {
            state.showCalendar = false
            emitFn()
          },
          cancel: () => {
            resetCalendat()
            emitFn()
          }
        })
      } else {
        state.showCalendar = false
        emitFn()
      }
      document.scrollingElement.scrollTop = 0
    }

    const handleClick = (e) => {
      if (state.pickRef && !state.pickRef.contains(e.target)) {
        resetCalendat()
      }
    }

    const resetCalendat = () => {
      state.calendatDate.map(i => (i.selected = false))
      state.calendatDate.map(i => {
        state.initSelect.map(initItem => i.name === initItem.name && (i.selected = true))
      })

      state.calendatYear.name = state.initYear.name
      state.calendatYear.id = state.initYear.id
      state.calendatYear.select = state.initYear.select
      state.showCalendar = false
    }
    onMounted(() => {
      document.addEventListener('click', handleClick)
      if (route.params.selfEvaluate || route.params.setInitDate) {
        // 带参初始化年月okr
        const { year, month } = JSON.parse(route.params.selDate)
        state.calendatYear.name = parseFloat(year)
        state.queryDate.year = parseFloat(year)
        state.queryDate.month = month
      }
      getOkrCycleList(state.calendatYear.name, state.deptId, true).then(() => {
        state.initSelect = state.calendatDate.filter(i => i.selected)
        state.initYear = { ...state.calendatYear }

        process.env.VUE_APP_COMPANY_NAME === state.depName && state.calendatDate.map(i => (i.selected = false)) && (state.initSelect = [])
        process.env.VUE_APP_COMPANY_NAME === state.depName && (state.yearSelected = true)
        confirmMonth()
      })
    })
    onUnmounted(() => {
      document.removeEventListener('click', handleClick)
    })

    watch(() => state.deptId, (newValue) => {
      state.showCalendar = false
      state.calendatYear.name = new Date().getFullYear()
      newValue && getOkrCycleList(state.calendatYear.name, newValue, true).then(() => {
        confirmMonth()
      })
    })

    // 监听年份 历史okr 不展示未来年份 隐藏右侧按钮
    watch(() => state.calendatYear.name, (newValue) => {
      if (state.historySelect) {
        if (state.calendatYear.name === new Date().getFullYear()) {
          state.showRightBtn = false
        } else {
          state.showRightBtn = true
        }
      }
    }, { immediate: true, deep: true })

    // 选择月
    const selectDateMonth = (id, select) => {
      if (select === 0) return
      // 单选
      if (state.choiceModel === 'simple') {
        state.calendatDate.map(i => (i.selected = false))
        const selItem = state.calendatDate.filter(i => i.id === id)[0]
        selItem.selected = !selItem.selected
      } else {
        // 多选
        const selItem = state.calendatDate.filter(i => i.id === id)[0]
        selItem.selected = !selItem.selected
      }
      state.yearSelected = false
    }

    // 切换年
    const changeYear = (type) => {
      type === 'close' && (state.calendatYear.name -= 1)
      type === 'add' && (state.calendatYear.name += 1)
      state.calendatDate.forEach(i => (i.selected = false))
      getOkrCycleList(state.calendatYear.name, state.deptId).then(() => {
        route.name !== 'myokr' && getSelMonth(state.calendatDate, route.name, state.depName, { year: state.calendatYear.name })
      })
    }

    const selYear = () => {
      if (state.depName !== process.env.VUE_APP_COMPANY_NAME) return false
      state.yearSelected = true
      state.calendatDate.map(i => (i.selected = false))
    }
    // 审核okr，更新季度
    eventBus.$on('uploadCycle', (data) => {
      const currentIds = Array.isArray(data) ? data : [data]
      getOkrCycleList(state.calendatYear.name, state.deptId).then(() => {
        state.calendatDate.forEach(item => ~currentIds.indexOf(item.id) && (item.selected = true))
      })
    })
    const selState = (value) => {
      state.currentClick = value
    }

    return {
      ...toRefs(state),
      confirmMonth,
      selectDateMonth,
      changeYear,
      selYear,
      selState
    }
  }
}
</script>

<style lang="less" scoped>
  .pick-wrapper {
    position: relative;
    .selector{
      z-index: 1;
      position: absolute;
      top: 30px;
      left: -22px; //
      background: #FFFFFF;
      border: 1px solid #F1F1F1;
      border-radius: 6px;
      width: 172px;
      height: 204px;
      padding-top: 8px;
    }
    .select-year {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #FFFFFF;
      padding: 0 14px 9px 14px;
      & span:hover {
        color: @active-text-color;

      }
      .el-icon {
        cursor: pointer;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0 8px;
      border-bottom: 1px solid #F1F1F1;
      li {
        transition: all .6s;
        flex: 25%;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: @default-text-color;
        padding-bottom: 6px;
        &:hover span{
          background: rgba(121, 114, 240, .1);
          border-radius: 4px;
          color: @active-text-color;
          font-weight: normal;
        }
      }
      li span {
        display: inline-block;
        padding: 5px 3px;
      }
    }
  }
  .selectDate {
    height: 30px;
    line-height: 30px;
    background: #F6F9FA;
    border: 1px solid #F1F1F1;
    border-radius: 44px;
    padding-left: 14px;
    padding-right: 44px;
    transform: translateY(-6px);
    cursor: pointer;
    .el-icon {
      position: absolute;
      right: 14px;
      top: 7px;
    }
  }
  .showSelDate {
    display: inline-block;
    max-width: 108px;
    min-width: 72px;
    font-size: 13px;
  }
  // 选中样式
  .selected {
    cursor: pointer;
    color: @active-text-color !important;
    background: rgba(121, 114, 240, .1);
    border-radius: 4px;
    font-weight: normal !important;
  }
  .noSelect {
    color: #999 !important;
    cursor: not-allowed;
  }
  .creating {
    cursor: pointer;
    color: #333
  }
  .creating-year {
    color: @active-text-color !important;
  }
  .created {
    cursor: pointer;
    font-weight: 700;
    color: @active-text-color !important;
  }
  .select-confirm {
    height: 35px;
    // background: #fff;
    padding: 0 16px;
    display: flex;
    margin-top: 1px;
    align-items: center;
    justify-content: flex-end;
    .el-button--text:hover {
      color: @active-text-color;
    }
    .el-button {
      padding: 0;
      font-size: 12px;
      color: #333;
    }
    .el-button:last-child{
      width: 56px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      min-height: 0;
      padding: 0 16px;
      background: @active-text-color;
      border-radius: 30px;
      color: #fff;
      border: 0;
      margin-left: 12px;
    }
    .el-button:nth-child(2) {
      margin-left: 6px;
    }
  }
  .right-btn {
    display: inline-block;
    width: 14px;
    &:hover {
      color: #7972F0;
    }
  }
  </style>
