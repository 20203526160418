/*
 * @Description:
 * @Author: heqi
 * @Date: 2022-08-29 19:14:43
 * @LastEditTime: 2023-08-17 09:54:29
 * @LastEditors: tangjz
 * @FilePath: \sns_h5d:\hq\mfzj\src\mixin\socket.js
 */

import SocketService from '@/utils/websocket.js'
import { getCookies } from '@/utils/auth'
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
export default function () {
  // 连接websocket
  const data = reactive({
    socketServe: SocketService.Instance
  })
  const route = useRoute()
  // 链接WS且保持心跳检测
  function connectSocket () {
    data.socketServe.connect()
    setTimeout(() => {
      sendToSocket(undefined, { type: 99 })
    }, 50000)
  }

  function sendToSocket (userIds, objVal) {
    if (objVal.type === 1) { // 1 发动态
      data.socketServe.send({
        type: objVal.type,
        obj_id: objVal.feedID,
        token: getCookies('token')
      })
      return false
    }

    if (objVal.type === 99) { // 99 动态墙实现服务器不关闭
      const sendParam = {
        type: objVal.type,
        token: getCookies('token')
      }
      route.name === 'tvwall' && (sendParam.no_login_type = 1)
      data.socketServe.send(sendParam)
      return false
    }

    // 8 创建okr 和 提交自评
    if (objVal === 8) {
      const objId = userIds // OKR ID
      const type = objVal
      data.socketServe.send({
        type,
        user_id: objId,
        token: getCookies('token')
      })
      return false
    }

    // 发其他内容(2点赞 3收藏  4@用户 5评论  6日志)
    var newArr = []
    // 接收用户去重处理
    for (var i = 0; i < userIds.length; i++) {
      if (newArr.indexOf(userIds[i]) === -1) {
        newArr.push(userIds[i])
      }
    }
    // 发送数据
    newArr.forEach((item) => {
      data.socketServe.send({
        user_id: item, // 对象的发布者备注：(除动态发布，其他必传)
        type: objVal.type, // 2点赞 3收藏  4@用户 5评论  6日志 8审核与评价
        token: getCookies('token'),
        obj_id: objVal.id || undefined, // 目标id 操作的对象ID备注：点赞、收藏才传
        obj_type: objVal.obj_type || undefined // 操作对象类型备注：点赞、收藏才传 点赞(1-微博 2-论坛 3-评论 4-员工日志),收藏(1-动态 2-论坛 3-资讯)
      })
    })
  }
  return { data, connectSocket, sendToSocket }
}
