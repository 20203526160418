<!--
 * @Descripttion: 文件列表
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-13 16:52:47
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-19 13:53:43
-->
<template>
  <div class="file-list border-6" v-for="file in fileData"  :key="file.name" @click.prevent="downloadFile(file)">
    <a>
      <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 2a2 2 0 0 1 2-2h17l9 9v24a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" fill="#7972F0"/><path d="M18 1l9 9h-9V1z" fill="#fff"/><path d="M10.366 24.902l4.313-7.469a1.232 1.232 0 1 1 2.134 1.232L12.5 26.134a2.464 2.464 0 1 1-4.268-2.464l4.312-7.469a3.696 3.696 0 1 1 6.402 3.696l-3.285 5.69" stroke="#fff" stroke-width="2" stroke-linecap="round"/></svg>
      <div class="file-info">
        <div class="file-name">
          <p class="name oneline-overflow">{{file.name}}</p>
          <p class="extension">{{file.extension}}</p>
        </div>
        <p class="size">{{file.size && getFileSize(file.size) }}</p>
      </div>
    </a>
    <svg v-if="removeVisible" class="remove-icon" @click.stop="remove(file.uid)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity=".5" cx="10" cy="10" r="10" fill="#666"/><rect x="12.829" y="5.757" width="2" height="10" rx="1" transform="rotate(45 12.829 5.757)" fill="#fff"/><rect x="14.243" y="12.828" width="2" height="10" rx="1" transform="rotate(135 14.243 12.828)" fill="#fff"/></svg>
  </div>
</template>

<script>
import { getFileSize } from '@/utils/tools.js'
import { computed, reactive, toRefs } from '@vue/reactivity'
export default {
  name: 'FileList',
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    removeVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['removeFile'],
  setup (props, { emit }) {
    const state = reactive({
      fileData: computed(() => {
        return props.fileList.map(file => { // 处理文件名超长问题
          const obj = JSON.parse(JSON.stringify(file))
          if (obj.name) {
            const fileName = obj.name
            obj.name = fileName.substring(0, fileName.lastIndexOf('.'))
            obj.extension = fileName.substring(fileName.lastIndexOf('.'))
          }
          return obj
        })
      })
    })

    const remove = (uid) => {
      emit('removeFile', uid)
    }
    const downloadFile = (file) => {
      // office文件预览
      const type = file.url.split('.')[file.url.split('.').length - 1]
      const typeArr = ['doc', 'docx', 'xls', 'xlsx', 'pptx', 'ppt', 'zip']
      const newUrl = decodeURIComponent(file.url)
      // 允许下载
      if (file.notAllowedDownload === 'false' || file.notAllowedDownload === undefined) {
        if (typeArr.includes(type)) {
          const link = document.createElement('a')
          link.href = newUrl
          link.download = file.name // 加上下载的文件名
          link.click()
          link.remove()
        }
        type === 'pdf' && window.open('/pdf/web/viewer.html?file=' + newUrl + '&state=1')
      } else {
        // 不允许下载
        typeArr.includes(type) && window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${newUrl}`, '_blank')
        type === 'pdf' && window.open('/pdf/web/viewer.html?file=' + newUrl + '&state=0')
      }
    }
    return {
      ...toRefs(state),
      remove,
      getFileSize,
      downloadFile
    }
  }
}
</script>

<style lang="less" scoped>
  .file-list{
    display: flex;
    margin-right: 10px;
    padding: 17px 15px;
    background: #F6F9FA;;
    line-height: 19px;
    margin-bottom: 10px;
    position: relative;
    width: 326px;
    cursor: pointer;
    a {
      display: flex;
      color: unset;
      z-index: 1200;
    }
    .file-info{
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 17px;
      .file-name{
        display: flex;
      }
      .name{
        max-width: 216px;
      }
    }
    .size{
      color: @second-text-color;
      margin-top: 5px;
      line-height: 16px;
    }
    .remove-icon{
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      &:hover circle{
        fill: @active-text-color;
        opacity: 1;
      }
    }

  }
</style>
