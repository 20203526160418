/*
 * @Descripttion:个人中心相关接口
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-11-29 16:52:52
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-02 16:50:55
 */

import request from '@/utils/request'
import { get, post } from '@/apis/method'

import { getCookies } from '@/utils/auth'
import sha1 from 'js-sha1'
import { getToken, getFileNameUUID } from '@/utils/uploadOss/tools'

/**
 * 上传文件
 * @param {文件} file
 * @param {类型} type  1-图片 2-文件 3-视频
 * @param {模块} module 1-微博 2-论坛 3-评论 4-员工日志 5-公告 6-用户
 * @returns
 */
const uploadFile = (file, type, callback, module) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  formData.append('module', module)
  formData.append('token', getCookies('token'))
  formData.append('timestamp', +new Date())
  formData.append(
    'sign',
    sha1(+new Date() + getCookies('token') + process.env.VUE_APP_KEY).toUpperCase()
  )
  return request({
    url: '/api/upload/upload_file',
    headers: {
      'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`
    },
    method: 'POST',
    data: formData,
    onUploadProgress (progressEvent) {
      // 上传进度条事件
      if (progressEvent.lengthComputable) {
        callback && callback(progressEvent)
      }
    }
  })
}

/* 文件切片上传 */
const uploadVideoFile = async (file, processFn, uploadSuccess, uploadError) => {
  const fileName = getFileNameUUID()
  const partSize = 1024 * 1024 * 3 // 每个分片大小(byte)
  const parallel = 3 // 同时上传的分片数
  const { client, path } = await getToken()
  const filePath = path + fileName + `.${file.name.split('.').slice(-1)[0]}`
  try {
    client.multipartUpload(filePath, file, {
      partSize,
      parallel,
      progress: (progress) => {
        processFn(progress)
      }
    }).then(result => {
      const { res } = result
      if (res.status === 200) {
        const url = res.requestUrls[0].split('?uploadId')[0]
        uploadSuccess(url)
      } else {
        uploadError()
      }
    })
  } catch (err) {
    console.log(err)
  }
}

/**
 * 更新用户信息（头像、个性签名）
 * @param {*} data
 * @returns
 */
const updateUserInfo = (data) => post('/api/user/user_update', data)

/**
 * 获取面板信息
 * @param {*} data
 * @returns
 */
const getPanelInfo = (data) => get('/api/user/user_panel_info', data)

/**
 * 获取我的动态
 * @param {*} data
 * @returns
 */
const getMyFeed = (data) => get('/api/user/user_feed', data)

/**
 * 获取我的贴子
 * @param {*} data
 * @returns
 */
const getMyPost = (data) => get('/api/user/user_post', data)

/**
 * 获取我的收藏
 * @param {*} data
 * @returns
 */
const getMyCollect = (data) => get('/api/user/user_collect', data)

/**
 * 获取当前佩戴
 * @param {*} data
 * @returns
 */
const getMedalInfo = () => get('/api/medal/info')

/**
 * 我的勋章
 * @param {*} data
 * @returns
 */
const getMedalList = () => get('/api/medal/list')

/**
 * 佩戴勋章
 * @param {*} data
 * @returns
 */
const getMedalWear = (data) => post('/api/medal/wear', data)

/**
 * 获取我的投票
 * @param {*} data
 * @returns
 */
const getMyVote = (data) => get('/api/user/user_vote', data)

export {
  uploadFile,
  updateUserInfo,
  getPanelInfo,
  getMyFeed,
  getMyCollect,
  getMyPost,
  uploadVideoFile,
  getMedalInfo,
  getMedalList,
  getMedalWear,
  getMyVote
}
